import { ReactNode } from 'react'
import { Avatar, Box, Button, ButtonProps, Typography, TypographyProps } from '@mui/material'
import Card from 'lz-design/Card'

export interface ItemDetailsProps {
  value?: ReactNode
  label?: ReactNode
  isBolded?: boolean
  icon?: ReactNode
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'purple'
  children?: ReactNode
  valueTypographyProps?: TypographyProps
}

const ItemDetails = ({
  label,
  value,
  isBolded = false,
  icon,
  color,
  children,
  valueTypographyProps
}: ItemDetailsProps): JSX.Element => {
  const cardColorProps = color &&
    ['warning', 'error'].includes(color) && {
      bgcolor: `${color}.50`,
      borderColor: `${color}.light`
    }
  return (
    <Card direction='row' justifyContent='space-between' alignItems='center' width='100%' {...cardColorProps}>
      <Box display='flex' alignItems='center' gap={3}>
        {icon && <Avatar color={color}>{icon}</Avatar>}
        <Box>
          {label && (
            <Typography variant='footnote' textTransform='uppercase' color='secondary' fontWeight={600}>
              {label}
            </Typography>
          )}
          {value && (
            <Typography variant='body2' fontWeight={isBolded ? 600 : 400} {...valueTypographyProps}>
              {value}
            </Typography>
          )}
        </Box>
      </Box>
      {children}
    </Card>
  )
}

const ItemDetailsButton = ({ ...props }: ButtonProps) => {
  return <Button variant='text' size='small' {...props} />
}

ItemDetails.Button = ItemDetailsButton

export default ItemDetails
