// TODO probably an enum from the API will be used
export enum ItemsActionType {
  SHIP = 'ship',
  DOWNLOAD_PDF = 'downloadPdf',
  SCAN = 'scan',
  CANCEL_SCAN = 'cancelScan',
  MOVE_TO_INBOX = 'moveToInbox',
  MOVE_TO_ARCHIVE = 'moveToArchive',
  MOVE_TO_TRASH = 'moveToTrash',
  MARK_NOT_VIEWED = 'markNotViewed',
  MARK_VIEWED = 'markViewed',
  NOT_MY_MAIL = 'notMyMail',
  SHRED = 'shred',
  CANCEL_SHRED = 'cancelShred',
  SHARE_PDF = 'sharePdf',
  SHARE_ITEM_VIA_INTEGRATION = 'shareItemViaIntegration',
  COPY_LINK_TO_PDF = 'copyLinkToPdf',
  CHECK_DEPOSIT = 'checkDeposit'
}
