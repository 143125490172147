import React from 'react'
import { FormControlLabel, FormControlLabelProps } from '@mui/material'

export default function OutlinedFormControlLabel({ sx, ...restProps }: FormControlLabelProps) {
  return (
    <FormControlLabel
      sx={{
        border: '1px solid',
        borderColor: 'grey.100',
        borderRadius: 1,
        margin: 0,
        padding: 4,
        alignItems: 'flex-start',
        backgroundColor: 'white',
        '&:has(> .Mui-checked)': {
          backgroundColor: 'warning.50',
          borderColor: 'primary.main'
        },
        ...sx
      }}
      slotProps={{
        typography: {
          sx: {
            width: '100%'
          }
        }
      }}
      {...restProps}
    />
  )
}
