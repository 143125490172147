import { alpha, ThemeOptions } from '@mui/material/styles'
import { X } from 'theme/icons'

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    purple: true
  }
}

export const MuiChip: Exclude<ThemeOptions['components'], undefined>['MuiChip'] = {
  defaultProps: {
    deleteIcon: <X />
  },
  styleOverrides: {
    root: ({ ownerState, theme: { palette, shape, spacing } }) => ({
      borderRadius: shape.borderRadius * 0.5,
      backgroundColor: palette[ownerState.color || 'secondary']?.light,
      color: palette[ownerState.color || 'secondary'].dark,
      padding: spacing(1, 2),
      height: 'auto'
    }),
    label: {
      fontWeight: 500,
      lineHeight: '16px',
      fontSize: 12,
      textTransform: 'uppercase',
      padding: 0
    },
    icon: ({ theme, ownerState }) => ({
      width: 16,
      height: 'auto',
      marginLeft: 0,
      marginRight: ownerState.label ? theme.spacing(1) : 0
    }),
    deleteIcon: ({ theme, ownerState }) => ({
      marginLeft: theme.spacing(1),
      marginRight: 0,
      fontSize: '16px',
      color: theme.palette.secondary.dark,
      '&:hover': {
        color: alpha(theme.palette[ownerState.color || 'secondary'].dark, 0.8)
      }
    })
  }
}
