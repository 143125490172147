import React, { useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import pluralize from 'pluralize'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { extractQueryVariablesFromStoreFieldName } from 'apps/MailroomAppV2/utils/extractQueryVariablesFromStoreFieldName'
import { FOLDER_LABELS } from 'apps/MailroomAppV2/constants/folderLabels'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import {
  VirtualMailItemFolder,
  GetMailItemsQueryVariables,
  VirtualMailItemPhysicalStatus
} from 'graphql/__generated__/graphql'
import { gql, useMutation } from 'graphql/client'
import LoadingModal from 'lz-design/LoadingModal'

export default function MoveActionDialog({
  itemsSuitableForAction,
  action,
  onError,
  onSuccess,
  onCancel,
  suitabilityAlert
}: RequestedActionProps<MoveActionTypes>) {
  const { currentInbox } = useUserContext()

  const [moveMailItems, { loading }] = useMutation(MOVE_MAIL_ITEMS, {
    variables: {
      input: {
        inboxId: String(currentInbox.id),
        mailItemIds: itemsSuitableForAction.map(item => item.id),
        folder: ACTION_TYPE_FOLDER_MAP[action]
      }
    },
    onError: () =>
      onError(
        `The request to move the ${pluralize('item', itemsSuitableForAction.length)} failed. Please try again later.`
      ),
    onCompleted: () => onSuccess(`${pluralize('Item', itemsSuitableForAction.length)} successfully moved.`),
    update: cache =>
      cache.modify({
        fields: {
          mailItems: (data, { storeFieldName, fieldName, DELETE }) => {
            const variables = extractQueryVariablesFromStoreFieldName<GetMailItemsQueryVariables>(
              fieldName,
              storeFieldName
            )

            return variables.filters?.folder !== VirtualMailItemFolder.VirtualMailItemFolderAll ? DELETE : data
          }
        }
      })
  })

  const immediateMove = [ItemsActionType.MOVE_TO_INBOX, ItemsActionType.MOVE_TO_ARCHIVE].includes(action)

  useEffect(() => {
    if (immediateMove) {
      void moveMailItems()
    }
  }, [immediateMove, action, moveMailItems])

  if (immediateMove) {
    return <LoadingModal />
  }

  const handleMoveToTrash = () => void moveMailItems()

  return (
    <Dialog open>
      <DialogTitle>Move to Trash</DialogTitle>
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        <Typography variant='body1'>
          Are you sure that you want to move {pluralize('item', itemsSuitableForAction.length)} to Trash?
          {/* TODO information that items moved to trash will be deleted (if it's needed) */}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} size='large'>
          Cancel
        </Button>
        <LoadingButton onClick={handleMoveToTrash} loading={loading} variant='contained' color='primary' size='large'>
          Move
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export const MOVE_TO_INBOX_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.MOVE_TO_INBOX,
  adjective: `moved to ${FOLDER_LABELS[VirtualMailItemFolder.VirtualMailItemFolderInbox]}`
}

export const MOVE_TO_ARCHIVE_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.MOVE_TO_ARCHIVE,
  adjective: `moved to ${FOLDER_LABELS[VirtualMailItemFolder.VirtualMailItemFolderArchive]}`
}

export const MOVE_TO_TRASH_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.MOVE_TO_TRASH,
  adjective: `moved to ${FOLDER_LABELS[VirtualMailItemFolder.VirtualMailItemFolderTrash]}`,
  isSuitable: ({ physicalStatus }) =>
    [
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipCompleted,
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredCompleted
    ].includes(physicalStatus),
  suitabilityExplanation:
    'Items cannot be moved to the trash unless they are no longer physically present. This means the item must either have been shipped or shredded before it can be moved to the trash.'
}

const MOVE_MAIL_ITEMS = gql(`
    mutation moveMailItems($input: MoveMailItemsInput!) {
      moveMailItems(input: $input) {
        ...on VirtualMailItem {
          id
          folder
        }
      }
    }
  `)

type MoveActionTypes = ItemsActionType.MOVE_TO_INBOX | ItemsActionType.MOVE_TO_ARCHIVE | ItemsActionType.MOVE_TO_TRASH

const ACTION_TYPE_FOLDER_MAP = {
  [ItemsActionType.MOVE_TO_INBOX]: VirtualMailItemFolder.VirtualMailItemFolderInbox,
  [ItemsActionType.MOVE_TO_ARCHIVE]: VirtualMailItemFolder.VirtualMailItemFolderArchive,
  [ItemsActionType.MOVE_TO_TRASH]: VirtualMailItemFolder.VirtualMailItemFolderTrash
}
