import { useState } from 'react'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { ItemsSelectionActionType, ItemsSelection } from 'apps/MailroomAppV2/hooks/useItemsSelection'
import { ShareItemViaIntegrationActionPayload } from 'apps/MailroomAppV2/components/Actions/SharePdf/ShareItemDialog'
import { MailItemFragment } from 'graphql/__generated__/graphql'
import { useAlert, AlertOptions } from 'lz-design/AlertContext'

interface UseItemsActionProps {
  itemsSelection: ItemsSelection
  actionsUnavailabilityReason?: AlertOptions
}

interface BaseItemsActionRequest<Type extends ItemsActionType> {
  action: Type
  adjective: string
  suitabilityExplanation?: string
  bulk?: boolean
  isSuitable?: (item: MailItemFragment) => boolean
}

interface ItemsActionRequestWithPayload<Type extends ItemsActionType, Payload> extends BaseItemsActionRequest<Type> {
  payload: Payload
}

type ItemsActionRequestPayloadMap = {
  [ItemsActionType.SHARE_ITEM_VIA_INTEGRATION]: ShareItemViaIntegrationActionPayload
}

type ActionConfigMap = {
  [Type in Exclude<ItemsActionType, keyof ItemsActionRequestPayloadMap>]: BaseItemsActionRequest<Type>
} & {
  [Type in keyof ItemsActionRequestPayloadMap]: ItemsActionRequestWithPayload<Type, ItemsActionRequestPayloadMap[Type]>
}

export type ItemsActionRequest<T extends ItemsActionType = ItemsActionType> = ActionConfigMap[T]

type ItemsActionState<Type extends ItemsActionType = ItemsActionType> = ItemsActionRequest<Type> & {
  itemsSelectedForAction: MailItemFragment[]
  itemsSuitableForAction: MailItemFragment[]
  suitabilityAlert?: AlertOptions
}

export type RequestedActionProps<Type extends ItemsActionType = ItemsActionType> = ItemsActionState<Type> & {
  onSuccess: (message: string | false) => void
  onCancel: () => void
  onError: (message: string) => void
}

export type ExecuteItemsActionImmediately<Type extends ItemsActionType> = (
  props: RequestedActionProps<Type>
) => void | Promise<void>

export type RequestAction<Type extends ItemsActionType = ItemsActionType> = (
  request: ItemsActionRequest<Type>,
  execute?: ExecuteItemsActionImmediately<Type>
) => void

interface UseItemsActionResult {
  requestItemAction: RequestAction
  requestBulkAction: RequestAction
  requestedAction?: RequestedActionProps
}

export function useItemsAction({
  actionsUnavailabilityReason,
  itemsSelection: { dispatchSelectionAction, selectedMailItems, currentMailItem }
}: UseItemsActionProps): UseItemsActionResult {
  const [state, setState] = useState<ItemsActionState | undefined>(undefined)
  const { setAlert } = useAlert()

  const reset = () => setState(undefined)
  const createOnSuccessHandler = (bulk?: boolean) => (message: string | false) => {
    message && setAlert({ severity: 'success', message })
    bulk && dispatchSelectionAction({ type: ItemsSelectionActionType.UNSELECT_ALL })
    reset()
  }
  const onError = (message: string) => {
    setAlert({ severity: 'error', message })
    reset()
  }

  const requestAction = async <Type extends ItemsActionType>(
    request: ItemsActionRequest<Type>,
    itemsSelectedForAction: MailItemFragment[],
    execute?: ExecuteItemsActionImmediately<Type>
  ) => {
    if (actionsUnavailabilityReason) {
      return setAlert(actionsUnavailabilityReason)
    }

    if (!itemsSelectedForAction.length) {
      return setAlert({
        severity: 'info',
        message: 'Select an item to perform an action.'
      })
    }

    const { isSuitable } = request

    // TODO will be handled on the API side as available actions
    const itemsSuitableForAction = isSuitable ? itemsSelectedForAction.filter(isSuitable) : itemsSelectedForAction
    const newState = {
      ...request,
      itemsSelectedForAction,
      itemsSuitableForAction
    }
    const suitabilityAlert = createSuitabilityAlert(newState)

    if (suitabilityAlert && !itemsSuitableForAction.length) {
      return setAlert(suitabilityAlert)
    }

    if (execute) {
      suitabilityAlert && setAlert(suitabilityAlert)

      const props = {
        ...newState,
        onCancel: reset,
        onSuccess: createOnSuccessHandler(request.bulk),
        onError
      }
      return void execute(props)
    }

    setState({
      ...newState,
      suitabilityAlert
    })
  }

  const requestItemAction = async <T extends ItemsActionType>(
    request: ItemsActionRequest<T>,
    execute?: ExecuteItemsActionImmediately<T>
  ) => currentMailItem && requestAction(request, [currentMailItem], execute)
  const requestBulkAction = async <T extends ItemsActionType>(
    request: ItemsActionRequest<T>,
    execute?: ExecuteItemsActionImmediately<T>
  ) => {
    const bulk = selectedMailItems.length !== 1 || selectedMailItems[0] !== currentMailItem

    return requestAction({ ...request, bulk }, selectedMailItems, execute)
  }

  return {
    requestItemAction,
    requestBulkAction,
    requestedAction: state && {
      ...state,
      onCancel: reset,
      onSuccess: createOnSuccessHandler(state.bulk),
      onError
    }
  }
}

function createSuitabilityAlert(state: ItemsActionState) {
  if (state.itemsSelectedForAction.length !== state.itemsSuitableForAction.length) {
    return {
      severity: 'warning',
      message: suitabilityAlertMessage(state)
    } as const
  }

  return undefined
}

function suitabilityAlertMessage({
  adjective,
  itemsSuitableForAction,
  itemsSelectedForAction,
  suitabilityExplanation
}: ItemsActionState): string {
  const explanation = suitabilityExplanation || ''

  if (itemsSelectedForAction.length === 1) {
    return `Selected item cannot be ${adjective}. ${explanation}`
  }

  const restOfTheSentence = `of the ${itemsSelectedForAction.length} selected items can be ${adjective}. ${explanation}`
  return !itemsSuitableForAction.length
    ? `None ${restOfTheSentence}`
    : `Only ${itemsSuitableForAction.length} ${restOfTheSentence}`
}
