import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Mailbox(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.81414 3.00007C7.88644 2.99944 7.18706 2.99897 6.54986 3.21875C5.98936 3.41208 5.47885 3.72759 5.05527 4.14246C4.57372 4.6141 4.26137 5.23985 3.84705 6.06989C3.33863 7.08677 2.5156 8.73594 1.96291 9.84368C1.77196 10.2264 1.67648 10.4178 1.69959 10.5735C1.71976 10.7095 1.79504 10.8312 1.90771 10.9099C2.03674 11.0001 2.25083 11.0001 2.67901 11.0001H5.88199C6.94596 11.0001 7.91862 11.6013 8.39445 12.5529C8.53149 12.827 8.81162 13.0001 9.11805 13.0001H14.882C15.1884 13.0001 15.4686 12.827 15.6056 12.5529C16.0814 11.6013 17.0541 11.0001 18.1181 11.0001H21.3211C21.7492 11.0001 21.9633 11.0001 22.0924 10.9099C22.205 10.8312 22.2803 10.7095 22.3005 10.5735C22.3236 10.4178 22.2281 10.2264 22.0372 9.84368C21.4845 8.73594 20.6615 7.08678 20.153 6.0699C19.7387 5.23986 19.4264 4.6141 18.9448 4.14246C18.5212 3.72759 18.0107 3.41208 17.4502 3.21875C16.813 2.99897 16.1136 2.99944 15.1859 3.00007H8.81414Z'
          fill='currentColor'
        />
        <path
          d='M23.0001 14.5998C23.0001 14.04 23.0001 13.7601 22.8912 13.5462C22.7953 13.358 22.6423 13.205 22.4541 13.1091C22.2402 13.0001 21.9603 13.0001 21.4004 13.0001H18.1181C17.8116 13.0001 17.5315 13.1733 17.3944 13.4473C16.9186 14.399 15.946 15.0001 14.882 15.0001H9.11805C8.05408 15.0001 7.08142 14.399 6.60559 13.4473C6.46855 13.1733 6.18842 13.0001 5.88199 13.0001H2.59962C2.03978 13.0001 1.75985 13.0001 1.54593 13.1091C1.3578 13.205 1.20476 13.3581 1.10891 13.5462C0.999925 13.7601 0.999951 14.04 1 14.5997C1.00002 14.8136 1.00004 15.0275 1.00004 15.2414C1.00003 16.0464 1.00002 16.7108 1.04423 17.252C1.09016 17.8141 1.18872 18.3308 1.43601 18.8161C1.81951 19.5687 2.43143 20.1807 3.18408 20.5642C3.66941 20.8115 4.18612 20.91 4.74821 20.9559C5.2894 21.0002 5.95376 21.0001 6.75872 21.0001H17.2414C18.0463 21.0001 18.7107 21.0002 19.2519 20.9559C19.814 20.91 20.3307 20.8115 20.816 20.5642C21.5687 20.1807 22.1806 19.5687 22.5641 18.8161C22.8114 18.3308 22.9099 17.8141 22.9558 17.252C23.0001 16.7108 23.0001 16.0464 23 15.2415C23 15.0276 23.0001 14.8137 23.0001 14.5998Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
