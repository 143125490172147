import { ThemeOptions } from '@mui/material'

export const MuiLink: Exclude<ThemeOptions['components'], undefined>['MuiLink'] = {
  defaultProps: {
    underline: 'none'
  },
  styleOverrides: {
    root: {
      fontWeight: 600
    }
  }
}
