import { Button } from '@mui/material'
import pluralize from 'pluralize'
import EmptyState from 'lz-design/EmptyState'
import { Check } from 'theme/icons'

interface SelectedItemsEmptyStateProps {
  selectedItemsCount: number
  onClearSelectedItems: () => void
}

const SelectedItemsEmptyState = ({ selectedItemsCount, onClearSelectedItems }: SelectedItemsEmptyStateProps) => {
  return (
    <EmptyState
      mt={{ lg: 14 }}
      title={`${pluralize('item', selectedItemsCount, true)} selected`}
      icon={Check}
      display={{ xs: 'none', lg: 'grid' }}
    >
      <Button onClick={onClearSelectedItems}>Clear selection</Button>
    </EmptyState>
  )
}

export default SelectedItemsEmptyState
