import { ButtonProps } from '@mui/material'
import ItemDetails from 'apps/MailroomAppV2/components/MailItemDetails/ItemDetails'
import {
  PHYSICAL_STATUS_CONFIG,
  DIGITAL_STATUS_CONFIG,
  CHECK_STATUS_CONFIG,
  digitalScanPropOverrides,
  StatusProps
} from 'apps/MailroomAppV2/utils/statusConfig'
import {
  VirtualMailItemCheckStatus,
  VirtualMailItemDigitalStatus,
  VirtualMailItemPhysicalStatus
} from 'graphql/__generated__/graphql'
import * as Icon from 'theme/icons'

interface StatusItemDetailsProps {
  status: VirtualMailItemPhysicalStatus | VirtualMailItemCheckStatus | VirtualMailItemDigitalStatus
  label?: string
  itemActions: ItemActions
  configOverrides?: Partial<StatusProps>
}

type ItemAction = () => void

export interface ItemActions {
  scan: ItemAction
  cancelScan: ItemAction
  download: ItemAction
  cancelShred: ItemAction
  details: ItemAction
  cancelShip: ItemAction
  deposit: ItemAction
  cancelDeposit: ItemAction
}

interface StatusActionConfig extends Omit<ButtonProps, 'onClick'> {
  actionName: keyof ItemActions
}

type PhysicalStatusActionMapping = Partial<
  Record<VirtualMailItemPhysicalStatus | VirtualMailItemCheckStatus | VirtualMailItemDigitalStatus, StatusActionConfig>
>

const STATUS_ACTION_MAPPING: PhysicalStatusActionMapping = {
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan]: {
    actionName: 'scan',
    children: 'Scan'
  },
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanRequested]: {
    actionName: 'cancelScan',
    children: 'Cancel',
    color: 'error'
  },
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanCompleted]: {
    actionName: 'download',
    children: 'Download'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredRequested]: {
    actionName: 'cancelShred',
    children: 'Cancel',
    color: 'error'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredCompleted]: {
    actionName: 'details',
    children: 'Details'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage]: {
    actionName: 'details',
    children: 'Details'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee]: {
    actionName: 'details',
    children: 'Details'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipRequested]: {
    actionName: 'cancelShip',
    children: 'Cancel',
    color: 'error'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipCompleted]: {
    actionName: 'details',
    children: 'Details'
  },
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusCheckDetected]: {
    actionName: 'deposit',
    children: 'Deposit',
    color: 'error'
  },
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositRequested]: {
    actionName: 'cancelDeposit',
    children: 'Cancel',
    color: 'error'
  },
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositCompleted]: {
    actionName: 'details',
    children: 'Details'
  }
}

type StatusesConfigOverrides<S extends string> = Partial<Record<S, Partial<StatusProps>>>

const STATUS_CONFIG_OVERRIDES: StatusesConfigOverrides<
  VirtualMailItemPhysicalStatus | VirtualMailItemDigitalStatus | VirtualMailItemCheckStatus
> = {
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusCheckDetected]: {
    label: 'Check Detected'
  }
}

export default function StatusItemDetails({ status, label, itemActions, configOverrides }: StatusItemDetailsProps) {
  const statusConfig = {
    ...PHYSICAL_STATUS_CONFIG,
    ...CHECK_STATUS_CONFIG,
    ...DIGITAL_STATUS_CONFIG
  }[status]
  if (!statusConfig) return null

  const statusAction = STATUS_ACTION_MAPPING[status]

  const { label: value, ...rest } = {
    ...statusConfig,
    ...STATUS_CONFIG_OVERRIDES[status],
    ...configOverrides
  }

  const hideAction = !!configOverrides

  return (
    <ItemDetails value={value} {...rest} label={label}>
      {!hideAction &&
        (statusAction ? (
          <ItemDetails.Button
            onClick={itemActions[statusAction.actionName]}
            color={statusAction.color}
            children={statusAction.children}
          />
        ) : (
          <Icon.Lock />
        ))}
    </ItemDetails>
  )
}

interface DigitalStatusItemDetailsProps {
  status: VirtualMailItemDigitalStatus
  physicalStatus: VirtualMailItemPhysicalStatus
  itemActions: ItemActions
}

export function DigitalStatusItemDetails({ status, physicalStatus, itemActions }: DigitalStatusItemDetailsProps) {
  const configOverrides = digitalScanPropOverrides(status, physicalStatus)
  return <StatusItemDetails status={status} itemActions={itemActions} configOverrides={configOverrides || undefined} />
}
