import pluralize from 'pluralize'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  DialogActions,
  Button,
  DialogContentText
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { extractQueryVariablesFromStoreFieldName } from 'apps/MailroomAppV2/utils/extractQueryVariablesFromStoreFieldName'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import { useMailroomFilters } from 'apps/MailroomAppV2/hooks/useMailroomFilters'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { useMutation, gql } from 'graphql/client'
import {
  GetMailItemsQueryVariables,
  VirtualMailItemFolder,
  VirtualMailItemPhysicalStatus,
  VirtualMailItemDigitalStatus,
  VirtualMailItemCheckStatus,
  MailItemFragment,
  VirtualMailItemPostShredAction
} from 'graphql/__generated__/graphql'
import { HookedRadioGroup } from 'lz-design/forms/inputs'
import OutlinedFormControlLabel from 'lz-design/OutlinedFormControlLabel'

export default function ShredActionDialog({
  onCancel,
  suitabilityAlert,
  onSuccess,
  onError,
  itemsSuitableForAction
}: RequestedActionProps) {
  const { currentInbox } = useUserContext()
  const {
    filters: { folder }
  } = useMailroomFilters()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm<ShredItemsFormValues>({
    defaultValues: {
      postShredAction: VirtualMailItemPostShredAction.VirtualMailItemPostShredActionUnspecified
    }
  })
  const [requestMailItemsShred] = useMutation(REQUEST_ITEMS_SHRED, {
    onCompleted: () => onSuccess(`${pluralize('item', itemsSuitableForAction.length, true)} shred requested.`),
    onError: () => onError('Shred request failed. Please try again later.'),
    update: cache =>
      cache.modify({
        fields: {
          mailItems: (data, { fieldName, storeFieldName, DELETE }) => {
            const variables = extractQueryVariablesFromStoreFieldName<GetMailItemsQueryVariables>(
              fieldName,
              storeFieldName
            )

            return variables.filters?.physicalStatusIn?.length ? DELETE : data
          }
        }
      })
  })

  const onSubmit = async ({ postShredAction }: ShredItemsFormValues) =>
    requestMailItemsShred({
      variables: {
        input: {
          inboxId: String(currentInbox.id),
          mailItemIds: itemsSuitableForAction.map(({ id }) => id),
          postShredAction
        }
      }
    })
  const notScannedItemsMessage = getShredNotScannedItemsMessage(itemsSuitableForAction)

  const itemPluralized = pluralize('item', itemsSuitableForAction.length)

  return (
    <Dialog open onClose={onCancel} component='form' onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Shred options</DialogTitle>
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        {notScannedItemsMessage && <DialogContentText mb={6}>{notScannedItemsMessage}</DialogContentText>}
        <Typography fontWeight={600}>Select an action after shred:</Typography>
        <HookedRadioGroup name='postShredAction' sx={{ gap: 3, marginTop: 4 }} control={control}>
          <OutlinedFormControlLabel
            value={VirtualMailItemPostShredAction.VirtualMailItemPostShredActionUnspecified}
            control={<Radio />}
            label={`Keep ${itemPluralized} in the current folder`}
          />
          {folder !== VirtualMailItemFolder.VirtualMailItemFolderArchive && (
            <OutlinedFormControlLabel
              value={VirtualMailItemPostShredAction.VirtualMailItemPostShredActionMoveToArchive}
              control={<Radio />}
              label={`Move ${itemPluralized} to Archive`}
            />
          )}
          <OutlinedFormControlLabel
            value={VirtualMailItemPostShredAction.VirtualMailItemPostShredActionMoveToTrash}
            control={<Radio />}
            label={`Move ${itemPluralized} to Trash`}
          />
        </HookedRadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} size='large'>
          Cancel
        </Button>
        <LoadingButton loading={isSubmitting} type='submit' variant='contained' size='large'>
          Shred
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export const SHRED_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.SHRED,
  adjective: 'shredded',
  isSuitable: ({ physicalStatus, digitalStatus, checkStatus }) =>
    [
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage,
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee
    ].includes(physicalStatus) &&
    ![
      VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanRequested,
      VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanInProgress
    ].includes(digitalStatus) &&
    checkStatus !== VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositRequested
}

interface ShredItemsFormValues {
  postShredAction: VirtualMailItemPostShredAction
}

function getShredNotScannedItemsMessage(itemsSuitableForAction: MailItemFragment[]) {
  const notScannedItems = itemsSuitableForAction.filter(
    ({ digitalStatus }) => digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan
  )
  if (!notScannedItems.length) {
    return undefined
  }

  const isPlural = notScannedItems.length > 1
  const allSuitableItemsNotScanned = notScannedItems.length === itemsSuitableForAction.length

  const messageRest = `${pluralize('item', itemsSuitableForAction.length)} ${pluralize(
    'have',
    notScannedItems.length
  )} not been scanned. Are you sure you want to shred ${isPlural ? 'them' : 'it'}?`

  if (allSuitableItemsNotScanned) {
    return `${isPlural ? 'Selected' : 'The selected'} ${messageRest}`
  }

  return `${isPlural ? 'Some' : 'One'} of the selected ${messageRest}`
}

const REQUEST_ITEMS_SHRED = gql(`
  mutation shredMailItems($input: RequestMailItemsShredInput!) {
    requestMailItemsShred(input: $input) {
      ... on VirtualMailItem {
        id,
        physicalStatus
      }
    }
  }
`)
