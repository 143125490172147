import { Alert, AlertProps } from '@mui/material'
import { AlertOptions } from 'lz-design/AlertContext'

interface SuitabilityAlertProps extends AlertProps {
  options?: AlertOptions
}

export default function SuitabilityAlert({ options, sx, ...restProps }: SuitabilityAlertProps) {
  if (!options) {
    return null
  }

  return (
    <Alert severity={options.severity} {...restProps} sx={{ mb: 6, ...sx }}>
      {options.message}
    </Alert>
  )
}
