import { Stack, StackProps } from '@mui/material'

const Card = ({ children, ...props }: StackProps): JSX.Element => {
  return (
    <Stack bgcolor='grey.25' p={4} border='1px solid' borderColor='grey.50' borderRadius={1} {...props}>
      {children}
    </Stack>
  )
}

export default Card
