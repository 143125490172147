import pluralize from 'pluralize'
import isEmail from 'validator/lib/isEmail'

export function validateEmailList(value: string[]) {
  if (!value || value.length === 0) {
    return 'Please select at least one email address'
  }

  const invalidEmails = value.filter(email => !isEmail(email))
  const emailPronoun = invalidEmails.length === 1 ? 'it' : 'them'

  if (invalidEmails.length > 0) {
    return `${invalidEmails.join(', ')} ${pluralize('is', invalidEmails.length)} not valid email ${pluralize(
      'address',
      invalidEmails.length
    )}. Please correct ${emailPronoun} or remove ${emailPronoun} to proceed.`
  }

  return true
}
