import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { gql, useMutation } from 'graphql/client'
import { MailItemFragment, VirtualMailItemCheckStatus, VirtualMailItemFlag } from 'graphql/__generated__/graphql'
import { useAlert } from 'lz-design/AlertContext'

interface ToggleCheckDetectedStatusDialogProps {
  mailItem: MailItemFragment
  isCheckDetected?: boolean
  onDialogClose: () => void
}

const ToggleCheckDetectedStatusDialog = ({
  mailItem,
  isCheckDetected,
  onDialogClose
}: ToggleCheckDetectedStatusDialogProps) => {
  const { currentInbox } = useUserContext()
  const { setAlert } = useAlert()
  const [setCheckDetected] = useMutation(SET_CHECK_DETECTED, {
    variables: { input: { mailItemId: mailItem.id, isDetected: !isCheckDetected, inboxId: String(currentInbox.id) } },
    optimisticResponse: {
      setMailItemCheckDetected: {
        ...mailItem,
        __typename: 'VirtualMailItem',
        checkStatus: isCheckDetected
          ? VirtualMailItemCheckStatus.VirtualMailItemCheckStatusNoCheck
          : VirtualMailItemCheckStatus.VirtualMailItemCheckStatusCheckDetected,
        flags: isCheckDetected
          ? mailItem.flags?.filter(flag => flag !== VirtualMailItemFlag.VirtualMailItemFlagCheckDetected)
          : [...(mailItem.flags || []), VirtualMailItemFlag.VirtualMailItemFlagCheckDetected]
      }
    },
    onError: () => {
      setAlert({
        severity: 'error',
        message: `The request to ${
          isCheckDetected ? 'remove' : 'add'
        } the "Check detected" status failed. Please try again later.`
      })
    },
    onCompleted: () => {
      setAlert({
        severity: 'success',
        message: `The "Check detected" status was synced and ${isCheckDetected ? 'removed' : 'added'}.`
      })
    }
  })

  return (
    <Dialog open>
      <DialogTitle>{isCheckDetected ? 'Remove' : 'Add'} check detected status?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isCheckDetected
            ? 'You are about to remove the check detected status of this item. If a deposit request for it is pending, please cancel it first.'
            : 'You are about to apply the check detected status of this item. Continuing with this selection could result in automation rules creating a deposit for this item.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onDialogClose} size='large'>
          No
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            onDialogClose()
            setCheckDetected()
          }}
          size='large'
        >
          {isCheckDetected ? 'Yes, remove it' : 'Yes, add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ToggleCheckDetectedStatusDialog

const SET_CHECK_DETECTED = gql(`
  mutation setMailItemCheckDetected($input: SetMailItemCheckDetectedInput!) {
    setMailItemCheckDetected(input: $input) {
      id
      checkStatus
      flags
    }
  }
`)
