import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Scan(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.7587 2L8 2C8.55229 2 9 2.44772 9 3C9 3.55229 8.55229 4 8 4H7.8C6.94342 4 6.36113 4.00078 5.91104 4.03755C5.47262 4.07337 5.24842 4.1383 5.09202 4.21799C4.7157 4.40973 4.40973 4.7157 4.21799 5.09202C4.1383 5.24842 4.07337 5.47262 4.03755 5.91104C4.00078 6.36113 4 6.94342 4 7.8V8C4 8.55229 3.55229 9 3 9C2.44772 9 2 8.55229 2 8L2 7.7587C1.99999 6.95373 1.99998 6.28937 2.04419 5.74817C2.09012 5.18608 2.18868 4.66937 2.43597 4.18404C2.81947 3.43139 3.43139 2.81947 4.18404 2.43597C4.66937 2.18868 5.18608 2.09012 5.74817 2.04419C6.28937 1.99998 6.95373 1.99999 7.7587 2Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3 15C3.55229 15 4 15.4477 4 16V16.2C4 17.0566 4.00078 17.6389 4.03755 18.089C4.07337 18.5274 4.1383 18.7516 4.21799 18.908C4.40973 19.2843 4.7157 19.5903 5.09202 19.782C5.24842 19.8617 5.47262 19.9266 5.91104 19.9625C6.36113 19.9992 6.94342 20 7.8 20H8C8.55229 20 9 20.4477 9 21C9 21.5523 8.55229 22 8 22H7.7587C6.95373 22 6.28937 22 5.74817 21.9558C5.18608 21.9099 4.66937 21.8113 4.18404 21.564C3.43139 21.1805 2.81947 20.5686 2.43597 19.816C2.18868 19.3306 2.09012 18.8139 2.04419 18.2518C1.99998 17.7106 1.99999 17.0463 2 16.2413C2 16.2276 2 16.2138 2 16.2V16C2 15.4477 2.44772 15 3 15Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.089 4.03755C17.6389 4.00078 17.0566 4 16.2 4H16C15.4477 4 15 3.55229 15 3C15 2.44772 15.4477 2 16 2H16.2C16.2138 2 16.2276 2 16.2413 2C17.0463 1.99999 17.7106 1.99998 18.2518 2.04419C18.8139 2.09012 19.3306 2.18868 19.816 2.43597C20.5686 2.81947 21.1805 3.43139 21.564 4.18404C21.8113 4.66937 21.9099 5.18608 21.9558 5.74817C22 6.28937 22 6.95373 22 7.7587V8C22 8.55229 21.5523 9 21 9C20.4477 9 20 8.55229 20 8V7.8C20 6.94342 19.9992 6.36113 19.9625 5.91104C19.9266 5.47262 19.8617 5.24842 19.782 5.09202C19.5903 4.7157 19.2843 4.40973 18.908 4.21799C18.7516 4.1383 18.5274 4.07337 18.089 4.03755Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21 15C21.5523 15 22 15.4477 22 16V16.2413C22 17.0463 22 17.7106 21.9558 18.2518C21.9099 18.8139 21.8113 19.3306 21.564 19.816C21.1805 20.5686 20.5686 21.1805 19.816 21.564C19.3306 21.8113 18.8139 21.9099 18.2518 21.9558C17.7106 22 17.0463 22 16.2413 22H16C15.4477 22 15 21.5523 15 21C15 20.4477 15.4477 20 16 20H16.2C17.0566 20 17.6389 19.9992 18.089 19.9625C18.5274 19.9266 18.7516 19.8617 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C19.8617 18.7516 19.9266 18.5274 19.9625 18.089C19.9992 17.6389 20 17.0566 20 16.2V16C20 15.4477 20.4477 15 21 15Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2 12C2 11.4477 2.44772 11 3 11H3.01C3.56229 11 4.01 11.4477 4.01 12C4.01 12.5523 3.56229 13 3.01 13H3C2.44772 13 2 12.5523 2 12Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.5 12C6.5 11.4477 6.94772 11 7.5 11H7.51C8.06229 11 8.51 11.4477 8.51 12C8.51 12.5523 8.06229 13 7.51 13H7.5C6.94772 13 6.5 12.5523 6.5 12Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.5 12C15.5 11.4477 15.9477 11 16.5 11H16.51C17.0623 11 17.51 11.4477 17.51 12C17.51 12.5523 17.0623 13 16.51 13H16.5C15.9477 13 15.5 12.5523 15.5 12Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11 12C11 11.4477 11.4477 11 12 11H12.01C12.5623 11 13.01 11.4477 13.01 12C13.01 12.5523 12.5623 13 12.01 13H12C11.4477 13 11 12.5523 11 12Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20 12C20 11.4477 20.4477 11 21 11H21.01C21.5623 11 22.01 11.4477 22.01 12C22.01 12.5523 21.5623 13 21.01 13H21C20.4477 13 20 12.5523 20 12Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
