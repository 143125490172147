import { ReactNode } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AlertProvider } from './AlertContext'
import { lzTheme } from 'theme/LzTheme'

export default function DesignProvider({ children }: { children?: ReactNode }) {
  return (
    <ThemeProvider theme={lzTheme}>
      <CssBaseline />
      <AlertProvider>{children}</AlertProvider>
    </ThemeProvider>
  )
}
