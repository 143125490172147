import { Box, DialogContent, IconButton, Stack } from '@mui/material'
import DialogWithCloseButton from 'lz-design/DialogWithCloseButton'
import { X } from 'theme/icons'

interface MailItemPreviewDialogProps {
  imgUrl: string
  onDialogClose: () => void
}

const MailItemPreviewDialog = ({ onDialogClose, imgUrl }: MailItemPreviewDialogProps) => {
  return (
    <>
      <Stack
        alignItems='flex-end'
        display={{ xs: 'flex', md: 'none' }}
        position='fixed'
        height='100vh'
        bgcolor='white'
        zIndex='2'
        top='0'
        left='0'
      >
        <Box p={6}>
          <IconButton
            onClick={onDialogClose}
            size='small'
            sx={theme => ({
              color: theme.palette.grey[800],
              padding: 0
            })}
          >
            <X />
          </IconButton>
        </Box>
        <Box bgcolor='grey.50' display='flex' flexGrow={1} justifyContent='center' alignItems='center' paddingX={4}>
          <Box component='img' src={imgUrl} alt='Envelope preview' maxWidth='100%' />
        </Box>
      </Stack>
      <DialogWithCloseButton
        open
        onClose={onDialogClose}
        PaperProps={{
          sx: {
            maxWidth: '80vw'
          }
        }}
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        })}
      >
        <DialogContent>
          <Box
            bgcolor='grey.50'
            p={{ xs: 2, md: 5 }}
            m={{ md: 9 }}
            mt={{ md: 0 }}
            borderRadius={1.5}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Box component='img' src={imgUrl} alt='Envelope preview' maxWidth='100%' maxHeight='calc(80vh - 120px)' />
          </Box>
        </DialogContent>
      </DialogWithCloseButton>
    </>
  )
}

export default MailItemPreviewDialog
