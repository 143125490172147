import { ThemeOptions } from '@mui/material'

export const MuiInputLabel: Exclude<ThemeOptions['components'], undefined>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      position: 'static',
      transform: 'none',
      marginBottom: theme.spacing(1),
      ...theme.typography.footnote,
      '&, &.Mui-focused, &.Mui-error': {
        color: theme.palette.text.primary
      }
    })
  }
}

export const MuiOutlinedInput: Exclude<ThemeOptions['components'], undefined>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: `1px solid ${theme.palette?.grey[100]}`,
      backgroundColor: 'white',
      padding: 0,
      '&.Mui-error': {
        backgroundColor: theme.palette.error[50],
        borderColor: theme.palette.error.main
      },
      '&.Mui-focused': {
        borderColor: theme.palette.text.primary
      }
    }),
    notchedOutline: {
      display: 'none'
    },
    input: ({ theme, ownerState }) => ({
      padding: ownerState.size === 'small' ? theme.spacing(1.5, 2) : theme.spacing(3, 4)
    })
  }
}
