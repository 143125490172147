import { ThemeOptions } from '@mui/material/styles'
import { alpha } from '@mui/material'
import { CheckedCheckbox, Checkbox } from 'theme/icons'

export const MuiCheckbox: Exclude<ThemeOptions['components'], undefined>['MuiCheckbox'] = {
  defaultProps: {
    icon: <Checkbox />,
    checkedIcon: <CheckedCheckbox />
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      color: theme.palette.grey[300],
      padding: theme.spacing(1.5),
      margin: theme.spacing(-1.5),
      borderRadius: 13,
      '&:hover, &:has(input:hover)': {
        backgroundColor: alpha(theme.palette[ownerState.color || 'primary'].main, 0.05)
      },
      '& .MuiSvgIcon-root': {
        backgroundColor: 'white',
        borderRadius: 8
      },
      '& .MuiTouchRipple-root .MuiTouchRipple-child': {
        borderRadius: 13
      }
    })
  }
}
