// @ts-ignore
if (typeof Promise.withResolvers === 'undefined') {
  // @ts-ignore
  window.Promise.withResolvers = function () {
    let resolve, reject
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    return { promise, resolve, reject }
  }
}

export {}
