import { createTheme, ThemeOptions } from '@mui/material/styles'
import fonts from './Fonts'
import reset from './reset'

declare module '@mui/material/styles' {
  interface Palette extends Record<string, PaletteColor> {
    astronaut: PaletteColor
    chetwodeBlue: PaletteColor
    eastBay: PaletteColor
    mariner: PaletteColor
    greenHaze: PaletteColor
    cinnabar: PaletteColor
    mandalay: PaletteColor
    blackSqueeze: PaletteColor
    ebonyClay: PaletteColor
    neonCarrot: PaletteColor
    lzNegroni: {
      brand: PaletteLZNegroniColor
      neutral: PaletteLZNegroniColor
      neutral2: PaletteLZNegroniColor
      accent1: PaletteLZNegroniColor
      accent2: PaletteLZNegroniColor
      accent3: PaletteLZNegroniColor
      success: PaletteLZNegroniColor
      error: PaletteLZNegroniColor
      scrim: string
    }
  }

  interface PaletteOptions {
    astronaut?: PaletteColor
    chetwodeBlue?: PaletteColor
    eastBay?: PaletteColor
    mariner?: PaletteColor
    greenHaze?: PaletteColor
    cinnabar?: PaletteColor
    mandalay?: PaletteColor
    blackSqueeze?: PaletteColor
    ebonyClay?: PaletteColor
    neonCarrot?: PaletteColor
    lzNegroni?: {
      brand: PaletteLZNegroniColor
      neutral: PaletteLZNegroniColor
      neutral2: PaletteLZNegroniColor
      accent1: PaletteLZNegroniColor
      accent2: PaletteLZNegroniColor
      accent3: PaletteLZNegroniColor
      success: PaletteLZNegroniColor
      error: PaletteLZNegroniColor
      scrim: string
    }
  }

  interface PaletteColor extends Record<string, string> {
    dark: string
    main: string
    light: string
    'light-2': string
    'light-3': string
    'light-4': string
    'light-5': string
  }

  interface PaletteLZNegroniColor extends Record<string, string | undefined> {
    0?: string
    25?: string
    50: string
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
  }
}

export const theme: ThemeOptions = {
  palette: {
    text: {
      primary: '#252424'
    },
    background: {
      default: 'white'
    },
    astronaut: {
      dark: '#0c0c0c',
      main: '#252424',
      light: '#252424',
      'light-2': '#6e6d6b',
      'light-3': '#727272',
      'light-4': '#dedede',
      'light-5': '#e9eaf0',
      contrastText: '#e9eaf0'
    },
    chetwodeBlue: {
      dark: '#f5f2ee',
      main: '#c9c9c9',
      light: '#0c0c0c',
      'light-2': '#b3b3b3',
      'light-3': '#cbd7f0',
      'light-4': '#f4f4f4',
      'light-5': '#dedede',
      contrastText: '#dedede'
    },
    eastBay: {
      dark: '#30386b',
      main: '#3c4685',
      light: '#727272',
      'light-2': '#9d9d9d',
      'light-3': '#c9c9c9',
      'light-4': '#dedede',
      'light-5': '#f4f4f4',
      contrastText: '#f4f4f4'
    },
    mariner: {
      dark: '#a83009',
      main: '#f45a27',
      light: '#fbc5b3',
      'light-2': '#f89473',
      'light-3': '#fdded4',
      'light-4': '#fdded4',
      'light-5': '#fef5f2',
      contrastText: '#fef5f2'
    },
    greenHaze: {
      dark: '#0b6a3b',
      main: '#11aa5e',
      light: '#11aa5e',
      'light-2': '#40ec97',
      'light-3': '#6cf0af',
      'light-4': '#98f5c7',
      'light-5': '#edfdf5',
      contrastText: '#c4f9df'
    },
    cinnabar: {
      dark: '#951232',
      main: '#e52554',
      light: '#e52554',
      'light-2': '#ee7190',
      'light-3': '#f292a9',
      'light-4': '#f6b3c3',
      'light-5': '#fef4f7',
      contrastText: '#fef4f7'
    },
    mandalay: {
      dark: '#4a3300',
      main: '#ffb70e',
      light: '#ffd470',
      'light-2': '#ffe3a2',
      'light-3': '#fff2d2',
      'light-4': '#fff9eb',
      'light-5': '#fffcf5',
      contrastText: '#fffcf5'
    },
    blackSqueeze: {
      dark: '#d8dee1',
      main: '#f0f7fa',
      light: '#fbfaf8',
      'light-2': '#f6fafc',
      'light-3': '#e9e6e2',
      'light-4': '#fcfdfe',
      'light-5': '#fefeff',
      contrastText: '#2c3040'
    },
    ebonyClay: {
      dark: '#22424b',
      main: '#2c3040',
      light: '#565966',
      'light-2': '#80838c',
      'light-3': '#abacb3',
      'light-4': '#d5d6d9',
      'light-5': '#eaeaec',
      contrastText: '#eaeaec'
    },
    neonCarrot: {
      dark: '#cc7a2d',
      main: '#ff9838',
      light: '#ffad60',
      'light-2': '#ffc188',
      'light-3': '#ffd6af',
      'light-4': '#ffead7',
      'light-5': '#fff5eb',
      contrastText: '#fff5eb'
    },
    error: {
      dark: '#b32e3a',
      main: '#e03a49',
      light: '#e6616d',
      contrastText: '#fcebed'
    },
    lzNegroni: {
      brand: {
        25: '#fef5f2',
        50: '#fdded4',
        100: '#fbc5b3',
        200: '#faac93',
        300: '#f89473',
        400: '#f67b52',
        500: '#f45a27',
        600: '#ec440c',
        700: '#a83009',
        800: '#651d05',
        900: '#220a02'
      },
      neutral: {
        0: '#fff',
        50: '#f4f4f4',
        100: '#dedede',
        200: '#c9c9c9',
        300: '#b3b3b3',
        400: '#9d9d9d',
        500: '#888',
        600: '#727272',
        700: '#474747',
        800: '#262626',
        900: '#0e0e0e'
      },
      neutral2: {
        25: '#fbfaf8',
        50: '#f5f2ee',
        100: '#e9e6e2',
        200: '#dcdad6',
        300: '#c4c2be',
        400: '#aba9a7',
        500: '#878583',
        600: '#6e6d6b',
        700: '#4a4947',
        800: '#252424',
        900: '#0c0c0c'
      },
      accent1: {
        50: '#fffcf5',
        100: '#fff9eb',
        200: '#fff2d2',
        300: '#ffe3a2',
        400: '#ffd470',
        500: '#ffb70e',
        600: '#dc9a00',
        700: '#ab7800',
        800: '#7b5600',
        900: '#4a3300'
      },
      accent2: {
        50: '#cad4d7',
        100: '#7f979e',
        200: '#6a868e',
        300: '#55757e',
        400: '#2a525e',
        500: '#22424b',
        600: '#1d3942',
        700: '#193138',
        800: '#15292f',
        900: '#0d191c'
      },
      accent3: {
        25: '#edf8fd',
        50: '#daf1fa',
        100: '#bbe5f7',
        200: '#7dcdef',
        300: '#5cc1eb',
        400: '#33b1e6',
        500: '#009de0',
        600: '#007eb3',
        700: '#005172',
        800: '#00415b',
        900: '#002a3a'
      },
      success: {
        25: '#edfdf5',
        50: '#c4f9df',
        100: '#98f5c7',
        200: '#6cf0af',
        300: '#40ec97',
        400: '#17e57e',
        500: '#11aa5e',
        600: '#0f9552',
        700: '#0b6a3b',
        800: '#064023',
        900: '#02150c'
      },
      error: {
        25: '#fef4f7',
        50: '#fad3dd',
        100: '#f6b3c3',
        200: '#f292a9',
        300: '#ee7190',
        400: '#ea5176',
        500: '#e52554',
        600: '#d01946',
        700: '#951232',
        800: '#590b1e',
        900: '#1e040a'
      },
      scrim: '#0009'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontFamily: `inherit`
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${fonts}
        ${reset}

        body {
          line-height: 24px;
          font-size: 14px;
          font-family: Work Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
        }

        strong, b {
          font-weight: 700;
        }

        small {
          color: #6e6d6b
        }`
    }
  }
}

export default createTheme(theme)
