import { ThemeOptions } from '@mui/material/styles'

export const MuiButton: Exclude<ThemeOptions['components'], undefined>['MuiButton'] = {
  styleOverrides: {
    root: {
      textTransform: 'inherit',
      fontWeight: 600,
      '&, &:hover, &:active': {
        boxShadow: 'none'
      },
      fontSize: 16,
      lineHeight: '24px',
      variants: [
        {
          props: ({ color, variant }) => color === 'secondary' && variant && ['text', 'outlined'].includes(variant),
          style: ({ theme }) => ({
            color: theme.palette.text.primary,
            borderColor: theme.palette.grey[100],
            '&:hover': {
              backgroundColor: theme.palette.grey[25]
            }
          })
        }
      ]
    },
    outlined: () => ({
      backgroundColor: 'white'
    }),
    sizeLarge: ({ theme }) => ({
      padding: theme.spacing(3, 5)
    }),
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(1, 3)
    }),
    sizeSmall: {
      fontSize: 14,
      lineHeight: '20px'
    },
    startIcon: ({ ownerState, theme }) => ({
      marginLeft: 0,
      marginRight: theme.spacing(ownerState.size === 'small' ? 1.5 : 2),
      '& > *:nth-of-type(1)': {
        fontSize: 'inherit'
      }
    }),
    endIcon: ({ ownerState, theme }) => ({
      marginLeft: theme.spacing(ownerState.size === 'small' ? 1.5 : 2),
      marginRight: 0,
      '& > *:nth-of-type(1)': {
        fontSize: 'inherit'
      }
    })
  }
}
