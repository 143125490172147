import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { gql, useMutation } from 'graphql/client'
import { MailItemFragment } from 'graphql/__generated__/graphql'
import { useAlert } from 'lz-design/AlertContext'
import { CircleX } from 'theme/icons'

interface UpdateSenderNameDialogProps {
  mailItem: MailItemFragment
  onDialogClose: () => void
}

const UpdateSenderNameDialog = ({ mailItem, onDialogClose }: UpdateSenderNameDialogProps) => {
  const { currentInbox } = useUserContext()
  const { setAlert } = useAlert()
  const [senderName, setSenderName] = useState('')

  const [updateMailItem] = useMutation(UPDATE_MAIL_ITEM, {
    variables: { input: { mailItemId: mailItem.id, senderName, inboxId: String(currentInbox.id) } },
    optimisticResponse: {
      updateMailItem: {
        ...mailItem,
        __typename: 'VirtualMailItem',
        sender: senderName
      }
    },
    onError: () => {
      setAlert({
        severity: 'error',
        message: "The request to edit the sender's name failed. Please try again later."
      })
      setSenderName('')
    },
    onCompleted: () => {
      setAlert({
        severity: 'success',
        message: "Sender's name changed."
      })
    }
  })
  return (
    <Dialog open>
      <DialogTitle>Edit sender name</DialogTitle>
      <DialogContent>
        <TextField
          label='Sender name'
          fullWidth
          helperText={`${senderName?.length}/100`}
          value={senderName}
          onChange={event => setSenderName(event.target.value)}
          slotProps={{
            formHelperText: { sx: { textAlign: 'right' } },
            htmlInput: { maxLength: 100 },
            input: {
              endAdornment: (
                <IconButton size='small' sx={{ p: 2 }} onClick={() => setSenderName('')}>
                  <CircleX fontSize='small' />
                </IconButton>
              )
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onDialogClose} fullWidth size='large'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            onDialogClose()
            updateMailItem()
          }}
          fullWidth
          size='large'
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const UPDATE_MAIL_ITEM = gql(`
    mutation updateMailItem($input: UpdateMailItemInput!) {
      updateMailItem(input: $input) {
        id
        sender
      }
    }
  `)

export default UpdateSenderNameDialog
