import { Children, isValidElement, ReactNode, ReactElement } from 'react'
import { Dialog, DialogProps, IconButton, DialogTitle, DialogTitleProps } from '@mui/material'
import * as Icon from 'theme/icons'

interface DialogWithCloseButtonProps extends DialogProps {
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void
}

const DialogWithCloseButton = ({ children, onClose, ...props }: DialogWithCloseButtonProps) => {
  const { titleElement, restChildren } = segregateDialogChildren(children)
  const { children: titleChildren, ...restTitleProps } = titleElement?.props || {}

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle {...restTitleProps}>
        {titleChildren}
        {onClose && (
          <IconButton
            onClick={event => onClose(event, 'closeButtonClick')}
            size='small'
            sx={{
              color: 'grey.800',
              padding: 0
            }}
          >
            <Icon.X />
          </IconButton>
        )}
      </DialogTitle>
      {restChildren}
    </Dialog>
  )
}

interface DialogChildrenSegregated {
  titleElement?: ReactElement<DialogTitleProps>
  restChildren: ReactNode[]
}

function segregateDialogChildren(children: ReactNode): DialogChildrenSegregated {
  return Children.toArray(children).reduce<DialogChildrenSegregated>(
    ({ titleElement, restChildren }, current) => {
      if (isDialogTitleElement(current) && !titleElement) {
        return {
          titleElement: current,
          restChildren
        }
      }

      return {
        titleElement,
        restChildren: [...restChildren, current]
      }
    },
    { restChildren: [] }
  )
}

function isDialogTitleElement(element: ReactNode): element is ReactElement<DialogTitleProps> {
  return isValidElement(element) && element.type === DialogTitle
}

export default DialogWithCloseButton
