import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function MailOpenOutlined(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13.744 2.63395L21.272 7.52716C21.538 7.70006 21.671 7.78651 21.7674 7.90183C21.8527 8.00391 21.9167 8.12198 21.9558 8.24914C22 8.39278 22 8.55141 22 8.86867V16.2004C22 17.8805 22 18.7206 21.673 19.3623C21.3854 19.9268 20.9265 20.3858 20.362 20.6734C19.7202 21.0004 18.8802 21.0004 17.2 21.0004H6.8C5.11984 21.0004 4.27976 21.0004 3.63803 20.6734C3.07354 20.3858 2.6146 19.9268 2.32698 19.3623C2 18.7206 2 17.8805 2 16.2004V8.86867C2 8.55141 2 8.39278 2.04417 8.24914C2.08327 8.12198 2.14735 8.00391 2.23265 7.90183C2.32901 7.78651 2.46201 7.70006 2.72802 7.52716L10.256 2.63395M13.744 2.63395C13.1127 2.22364 12.7971 2.01848 12.457 1.93866C12.1564 1.8681 11.8436 1.8681 11.543 1.93866C11.2029 2.01848 10.8873 2.22364 10.256 2.63395M13.744 2.63395L20.9681 7.32961C21.312 7.55317 21.484 7.66494 21.5435 7.80669C21.5956 7.93056 21.5956 8.07018 21.5435 8.19405C21.484 8.3358 21.312 8.44757 20.9681 8.67113L13.744 13.3668C13.1127 13.7771 12.7971 13.9823 12.457 14.0621C12.1564 14.1326 11.8436 14.1326 11.543 14.0621C11.2029 13.9823 10.8873 13.7771 10.256 13.3668L3.03193 8.67112C2.68801 8.44757 2.51604 8.3358 2.45649 8.19405C2.40444 8.07018 2.40444 7.93056 2.45649 7.80669C2.51604 7.66494 2.68801 7.55317 3.03193 7.32962L10.256 2.63395'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  )
}
