import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function MinusCheckbox(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='24' height='24' rx='8' fill='currentColor' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.6665 12.0002C6.6665 11.632 6.96498 11.3335 7.33317 11.3335H16.6665C17.0347 11.3335 17.3332 11.632 17.3332 12.0002C17.3332 12.3684 17.0347 12.6668 16.6665 12.6668H7.33317C6.96498 12.6668 6.6665 12.3684 6.6665 12.0002Z'
          fill='white'
        />
      </svg>
    </SvgIcon>
  )
}
