import { CircularProgress, Stack, Typography } from '@mui/material'
import { DATE_TIME_FORMAT, formatDate } from 'utils/format_date'
import useUserContext from 'hooks/useUserContext'
import HistoryItem from 'apps/MailroomAppV2/components/MailItemDetails/HistoryItem'
import { gql, useQuery } from 'graphql/client'
import { MailItemFragment } from 'graphql/__generated__/graphql'
import EmptyState from 'lz-design/EmptyState'
import { SadFace } from 'theme/icons'

interface HistoryTabProps {
  mailItem: MailItemFragment
}

const GET_MAIL_ITEM_HISTORY = gql(`
  query getMailItemHistory($inboxId: ID!, $mailItemId: ID!) {
    mailItemHistory(inboxId: $inboxId, mailItemId: $mailItemId) {
     id
     createdAt
     description
    }
  }
`)

const HistoryTab = ({ mailItem }: HistoryTabProps) => {
  const { currentInbox } = useUserContext()

  const { data, loading, error } = useQuery(GET_MAIL_ITEM_HISTORY, {
    variables: { inboxId: String(currentInbox.id), mailItemId: mailItem.id }
  })

  if (loading || error) {
    return (
      <Stack minHeight='100%' justifyContent='center' alignItems='center'>
        {loading && <CircularProgress color='primary' />}
        {error && (
          <EmptyState
            title='Oops, something went wrong'
            subtitle="An error occurred while fetching item's history. Please try again later."
            icon={SadFace}
          />
        )}
      </Stack>
    )
  }

  return (
    <Stack gap={3} minHeight='100%'>
      <Typography fontWeight={600} variant='body1'>
        Item history
      </Typography>
      {data?.mailItemHistory.map(({ createdAt, description, id }) => (
        <HistoryItem date={formatDate(createdAt, DATE_TIME_FORMAT)} description={description} key={id} />
      ))}
    </Stack>
  )
}
export default HistoryTab
