import { getEnvConfig } from './envconfig'
const currentConfig = getEnvConfig()

export const myLzUrl = currentConfig.myLzUrl
export const appBaseUrl = currentConfig.appUrl
export const apiBaseURL = currentConfig.apiBaseURL
export const graphqlBaseURL = currentConfig.graphqlBaseURL
export const googleTagManagerId = currentConfig.googleTagManagerId
export const launchDarklyClientSideId = currentConfig.launchDarklyClientSideId
export const angularAppJsUrl =
  process.env.REACT_APP_ANGULAR_APP_JS_URL ?? 'https://local.app.earthclassmail.com:4040/js/all.js'
export const angularAppCssUrl =
  process.env.REACT_APP_ANGULAR_APP_CSS_URL ?? 'https://local.app.earthclassmail.com:4040/css/app.css'
export const amplitudeApiKey = currentConfig.amplitudeApiKey
export const hotjarId = currentConfig.hotjarId
export const hotjarSnippetVersion = currentConfig.hotjarSnippetVersion
export const appCookieDomain = currentConfig.appCookieDomain
export const awsSecureCookie = currentConfig.awsSecureCookie
export const awsProjectRegion = currentConfig.awsProjectRegion
export const awsCognitoRegion = currentConfig.awsCognitoRegion
export const awsUserPoolsId = currentConfig.awsUserPoolsId
export const awsUserPoolsWebClientId = currentConfig.awsUserPoolsWebClientId
export const awsUserPoolsMarketingClientId = currentConfig.awsUserPoolsMarketingClientId
export const awsOAuthDomain = currentConfig.awsOAuthDomain
export const awsOAuthSignInRedirect = currentConfig.awsOAuthSignInRedirect
export const awsOAuthSignOutRedirect = currentConfig.awsOAuthSignOutRedirect
export const marketingRedirectUrl = currentConfig.marketingRedirectUrl
export const pusherAppKey = currentConfig.pusherAppKey
export const pusherCluster = currentConfig.pusherCluster
export const googleMapsApiKey = currentConfig.googleMapsApiKey
export const stripePublicKey = currentConfig.stripePublicKey
