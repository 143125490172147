import {
  Checkbox,
  CheckboxProps,
  FormControlLabelProps,
  FormHelperText,
  InputLabel,
  RadioGroup,
  RadioGroupProps,
  Select,
  SelectProps,
  Stack,
  TextField,
  TextFieldProps
} from '@mui/material'
import { Control, FieldValues, useController, UseControllerProps, useFormState } from 'react-hook-form'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import OutlinedFormControlLabel from 'lz-design/OutlinedFormControlLabel'
import AutocompleteWithChips, { AutocompleteWithChipsProps } from 'lz-design/AutocompleteWithChips'

export function HookedTextField<FormFields extends FieldValues>({
  control,
  rules,
  name,
  helperText,
  ...rest
}: TextFieldProps & UseControllerProps<FormFields>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid, error },
    formState: { isSubmitting }
  } = useController({
    name,
    control,
    rules
  })

  return (
    <TextField
      disabled={isSubmitting}
      {...rest}
      {...field}
      error={invalid}
      helperText={error?.message || helperText}
      inputRef={ref}
    />
  )
}

export function HookedNumberField<FormFields extends FieldValues>({
  control,
  rules,
  name,
  helperText,
  ...rest
}: NumberFormatProps & Omit<TextFieldProps, 'type' | 'ref'> & UseControllerProps<FormFields>) {
  const {
    field: { ref, ...field },
    fieldState: { invalid, error },
    formState: { isSubmitting }
  } = useController({
    name,
    control,
    rules
  })

  return (
    <NumberFormat
      {...rest}
      {...field}
      customInput={TextField}
      disabled={isSubmitting}
      error={invalid}
      helperText={error?.message || helperText}
      getInputRef={ref}
    />
  )
}

export function HookedSelectField<FormFields extends FieldValues>({
  control,
  rules,
  name,
  helperText,
  ...rest
}: SelectProps & UseControllerProps<FormFields> & { helperText?: string }) {
  const {
    field: { ref, ...field },
    fieldState: { invalid, error },
    formState: { isSubmitting }
  } = useController({
    name,
    control,
    rules
  })

  return (
    <Stack overflow='hidden'>
      {rest.label && <InputLabel disabled={isSubmitting}>{rest.label}</InputLabel>}
      <Select disabled={isSubmitting} {...rest} {...field} error={invalid} inputRef={ref} />
      {(helperText || error?.message) && (
        <FormHelperText disabled={isSubmitting} error={!!error?.message}>
          {error?.message || helperText}
        </FormHelperText>
      )}
    </Stack>
  )
}

export function HookedCheckbox<FormFields extends FieldValues>({
  control,
  rules,
  name,
  onChange,
  ...rest
}: CheckboxProps & UseControllerProps<FormFields>) {
  const {
    field: { ref, value, ...field },
    formState: { isSubmitting }
  } = useController({
    name,
    control,
    rules
  })

  return (
    <Checkbox
      disabled={isSubmitting}
      checked={!!value}
      {...field}
      {...rest}
      onChange={(...rest) => {
        field.onChange(...rest)
        onChange?.(...rest)
      }}
      inputRef={ref}
    />
  )
}

export function HookedRadioGroup<FormFields extends FieldValues>({
  control,
  name,
  rules,
  sx,
  ...rest
}: RadioGroupProps & UseControllerProps<FormFields>) {
  const {
    field: { ref, ...field },
    fieldState: { error, invalid }
  } = useController({
    name,
    control,
    rules
  })

  return (
    <Stack>
      <RadioGroup
        {...rest}
        {...field}
        ref={ref}
        sx={{ ...sx, '& .MuiFormControlLabel-root': { borderColor: invalid ? 'error.main' : '' } }}
      />
      {error?.message && <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>}
    </Stack>
  )
}

export function HookedOutlinedFormControlLabel({
  controller,
  ...rest
}: FormControlLabelProps & { controller?: Control }) {
  const { isSubmitting } = useFormState({
    control: controller
  })
  return <OutlinedFormControlLabel disabled={isSubmitting} {...rest} />
}

interface HookedAutocompleteWithChipsProps
  extends Pick<AutocompleteWithChipsProps, 'options' | 'label' | 'showInputAsNoOptionText'> {}

export function HookedAutocompleteWithChips<FormFields extends FieldValues>({
  name,
  control,
  rules,
  ...rest
}: HookedAutocompleteWithChipsProps & UseControllerProps<FormFields>) {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  })
  return <AutocompleteWithChips {...rest} value={value} name={name} onChange={onChange} error={error?.message} />
}
