import { useQuery, gql } from 'graphql/client'
import { AccountFlagType } from 'graphql/__generated__/graphql'

export function useNewMailroomAvailability(inboxId?: number) {
  const { loading, data } = useQuery(GET_ACCOUNT_FLAGS, {
    variables: {
      inboxId: inboxId || 0
    },
    skip: !inboxId
  })

  const flags = data?.getInbox.account.flags || []
  const enabled = flags.find(flag => flag.key === AccountFlagType.AccountFlagNewMailroomEnabled)?.value || false
  const switchable =
    flags.find(flag => flag.key === AccountFlagType.AccountFlagMailroomVersionsSwitchAllowed)?.value || false

  return {
    enabled,
    switchable,
    loading
  }
}

const GET_ACCOUNT_FLAGS = gql(`
  query getAccountFlags($inboxId: Int!) {
    getInbox(inboxId: $inboxId) {
      id
      account {
        id
        flags {
          accountId
          key
          value
        }
      }
    }
  }
`)
