import { gql } from 'graphql/client'

export const MAIL_ITEMS_SHARE_DATA = gql(`
  query mailItemsShareData($mailItemIds: [ID!]!) {
    mailItemsShareData(mailItemIds: $mailItemIds) {
      ...MailItemShareData
    }
  }
`)

export const TOGGLE_SHARE_LINK_ACCESS = gql(`
  mutation toggleShareLinkAccess($input: ToggleShareLinkAccessInput!) {
    toggleShareLinkAccess(input: $input) {
      ...MailItemShareData
    }
  }
`)

gql(`
    fragment MailItemShareData on VirtualMailItemShareData {
      mailItemId
      generalLinkData {
        linkKey
        isEnabled
      }
      emailShares {
          email
          linkData {
            linkKey
            isEnabled
          }
        }
    }
  `)
