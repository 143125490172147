import { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { PDFDocumentProxy } from 'pdfjs-dist'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Box, Button, CircularProgress, Link, Paper, Stack, Typography } from '@mui/material'
import { debounce, isEqual } from 'lodash'
import { Download, Print, RotateRight, ZoomIn, ZoomOut } from 'theme/icons'

// Why legacy? See this:
// https://github.com/mozilla/pdf.js/wiki/Frequently-Asked-Questions#which-browsersenvironments-are-supported
// https://github.com/wojtekmaj/react-pdf/issues/1811
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/legacy/build/pdf.worker.min.mjs', import.meta.url).toString()

// e.g For a 100px height container, 0.2 means that a page is considered shown if it's visible 20px from the bottom of the container
const VISIBILITY_THRESHOLD = 0.2
const MAX_PREVIEW_PAGES = 15
const ZOOM_MULTIPLIER = 1.1
const MIN_ZOOM_SCALE = 1
const MAX_ZOOM_SCALE = 2
const DEFAULT_PAGE_GAP = 5
const PAGE_CONTAINER_PADDING = 6

interface PdfViewerProps {
  url?: string
  pageGap?: number
  maxPreviewPages?: number
  maxPageWidth?: number
  showDownloadButton?: boolean
}

/*
  This is a wrapper for the react-pdf library. 
  You can find the documentation here: https://github.com/wojtekmaj/react-pdf
*/
export default function PDFViewer({
  url,
  pageGap = DEFAULT_PAGE_GAP,
  maxPreviewPages = MAX_PREVIEW_PAGES,
  maxPageWidth = Infinity,
  showDownloadButton = true
}: PdfViewerProps): JSX.Element | null {
  const frameRef = useRef<HTMLIFrameElement | null>(null)
  const file = useRef<string>()
  const [numOfVisiblePages, setNumOfVisiblePages] = useState(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [loadingPages, setLoadingPages] = useState(true)

  const [angle, rotate] = useReducer((currentAngle: number) => (currentAngle + 90) % 360, 0)
  const [scale, setScale] = useState(1)
  const [width, setWidth] = useState<number>()

  const scrollableContainer = useRef<HTMLDivElement>(null)
  const loadedPages = useRef<Element[]>([])

  const pagesToLoad = Math.min(totalPages, maxPreviewPages)
  const loading = !totalPages || loadingPages

  const handlePrint = () => {
    frameRef?.current?.contentWindow?.print()
  }

  const preparePdfForPrinting = async (pdf: PDFDocumentProxy) => {
    const data = await pdf.getData()
    const blob = new Blob([data], { type: 'application/pdf' })
    file.current = URL.createObjectURL(blob)
  }

  const setLoadedPages = useCallback(
    (value: Element) => {
      loadedPages.current.push(value)
      if (loadedPages.current.length === pagesToLoad) {
        setLoadingPages(false)
      }
    },
    [pagesToLoad]
  )

  useEffect(() => {
    loadedPages.current = []
    setLoadingPages(true)
  }, [angle, scale, width])

  useEffect(() => {
    if (loading) {
      return
    }

    const countShownPages = debounce((): void => {
      const container = scrollableContainer.current?.getBoundingClientRect()
      if (container) {
        const visibilityEdge = container.bottom - container.height * VISIBILITY_THRESHOLD
        const visiblePages = loadedPages.current.filter((elem: Element) => {
          const page = elem.getBoundingClientRect()
          return page.y <= visibilityEdge
        })
        if (visiblePages.length) {
          setNumOfVisiblePages(visiblePages.length)
        }
      }
    }, 200)

    countShownPages()
    const container = scrollableContainer.current
    container?.addEventListener('scroll', countShownPages)
    return () => {
      container?.removeEventListener('scroll', countShownPages)
    }
  }, [loading])

  useEffect(() => {
    const container = scrollableContainer.current
    if (width || !container) {
      return
    }

    const resize = debounce(() => {
      if (container) {
        const containerWidth = container.getBoundingClientRect().width
        const scrollbarWidth = container.offsetWidth - container.clientWidth
        setWidth(Math.min(containerWidth - scrollbarWidth, maxPageWidth) - PAGE_CONTAINER_PADDING * 8)
      }
    }, 100)

    const observer = new ResizeObserver(resize)
    observer.observe(container)

    return () => {
      observer.disconnect()

      if (file.current) {
        URL.revokeObjectURL(file.current)
      }
    }
  }, [])

  const onPDFLoadSuccess = useCallback(async (pdf: PDFDocumentProxy): Promise<void> => {
    setTotalPages(pdf.numPages)
    await preparePdfForPrinting(pdf)
  }, [])

  function handleZoomOut(): void {
    if (!loading) {
      setScale(scale / ZOOM_MULTIPLIER)
    }
  }

  function handleZoomIn(): void {
    if (!loading) {
      setScale(scale * ZOOM_MULTIPLIER)
    }
  }

  function handleRotate(): void {
    if (!loading) {
      rotate()
    }
  }

  return (
    <Stack height='100%' position='relative'>
      <Stack direction='row' justifyContent='space-between' flexWrap='wrap'>
        <Stack direction='row' gap={2} mb={4} alignItems='flex-start' display={{ xs: 'none', md: 'flex' }}>
          <Button startIcon={<RotateRight />} color='secondary' variant='text' onClick={handleRotate}>
            Rotate
          </Button>
          <Button
            startIcon={<ZoomOut />}
            variant='text'
            color='secondary'
            disabled={scale <= MIN_ZOOM_SCALE}
            onClick={handleZoomOut}
          >
            Zoom Out
          </Button>
          <Button
            startIcon={<ZoomIn />}
            variant='text'
            color='secondary'
            disabled={scale >= MAX_ZOOM_SCALE}
            onClick={handleZoomIn}
          >
            Zoom In
          </Button>
        </Stack>
        <Stack direction='row' gap={2} mb={6} alignItems='flex-start'>
          {url && showDownloadButton && (
            <Button startIcon={<Download />} variant='outlined' href={url} download>
              Download PDF
            </Button>
          )}
          <Button startIcon={<Print />} variant='outlined' onClick={handlePrint}>
            Print
          </Button>
        </Stack>
      </Stack>
      {file.current && <iframe ref={frameRef} src={file.current} style={{ display: 'none' }} title='pfd to download' />}
      <Box
        padding={PAGE_CONTAINER_PADDING}
        bgcolor='grey.50'
        borderRadius={1}
        ref={scrollableContainer}
        overflow='auto'
        flexGrow='1'
        display='flex'
        width='100%'
        margin='auto'
      >
        <Box margin='auto'>
          {!!width && (
            <Document file={url} onLoadSuccess={onPDFLoadSuccess} loading={<CircularProgress color='primary' />}>
              <Stack gap={pageGap}>
                {Array.from({ length: pagesToLoad }, (el, index) => (
                  <PDFPage
                    key={index}
                    pageNumber={index + 1}
                    scale={scale}
                    angle={angle}
                    width={width}
                    onRenderSuccess={setLoadedPages}
                  />
                ))}
                {loadedPages.current.length === maxPreviewPages && totalPages > maxPreviewPages && (
                  <Box textAlign='center' mb={20}>
                    This is a preview of this PDF. Please{' '}
                    <Link href={url} download>
                      download
                    </Link>{' '}
                    to view the complete document.
                  </Box>
                )}
              </Stack>
            </Document>
          )}
        </Box>
      </Box>
      {!!numOfVisiblePages && !!totalPages && (
        <Paper
          sx={{ position: 'absolute', bottom: 48, left: 0, right: 0, width: 'fit-content', margin: 'auto', p: 2 }}
          elevation={5}
        >
          <Typography variant='body2' color='grey.700'>
            Page {numOfVisiblePages} of {totalPages}
          </Typography>
        </Paper>
      )}
    </Stack>
  )
}

interface PDFSettings {
  scale: number
  width: number
  angle: number
}

interface PDFPageProps extends PDFSettings {
  pageNumber: number
  onRenderSuccess: (page: Element) => void | undefined
}

const PDFPage = memo(({ pageNumber, scale, onRenderSuccess, width, angle }: PDFPageProps): JSX.Element => {
  const [prevSettings, setPrevSettings] = useState<PDFSettings>()
  const ref = useRef(null)
  const loadedParts = useRef(0)

  const hasSettingsChanged = !isEqual(prevSettings, { scale, width, angle })

  if (hasSettingsChanged) {
    loadedParts.current = 0
  }

  const onSuccess = useCallback((): void => {
    if (!ref.current) {
      return
    }
    loadedParts.current++
    if (loadedParts.current === 2) {
      onRenderSuccess(ref.current)
      setPrevSettings({ scale, width, angle })
    }
  }, [angle, onRenderSuccess, width, scale])

  const showPreviousPage = prevSettings && hasSettingsChanged
  const prevKey = `${prevSettings?.scale}-${prevSettings?.angle}-${prevSettings?.width}`
  const key = `${scale}-${angle}-${width}`

  const paperStyles = { overflow: 'hidden', margin: 'auto' }

  return (
    <Box display='flex' ref={ref}>
      {showPreviousPage && ( // Render the previous page (with the previous key) to avoid flickering when changing settings
        <Box key={prevKey} sx={paperStyles}>
          <Page
            width={prevSettings.width}
            pageNumber={pageNumber}
            scale={prevSettings.scale}
            loading=''
            rotate={prevSettings.angle}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Box>
      )}
      <Box key={key} sx={{ ...paperStyles, display: showPreviousPage ? 'none' : undefined }}>
        <Page
          width={width}
          pageNumber={pageNumber}
          scale={scale}
          loading=''
          onRenderSuccess={onSuccess}
          onRenderTextLayerSuccess={onSuccess}
          renderAnnotationLayer={false}
          rotate={angle}
        />
      </Box>
    </Box>
  )
})
