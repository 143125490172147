import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Divider,
  List,
  Menu,
  MenuItem,
  Stack,
  Typography,
  ListSubheader,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { useNewMailroomAvailability } from 'hooks/useNewMailroomAvailability'
import {
  FolderFilterParam,
  useMailroomFilters,
  GeneralStatusFilterParam,
  StatusParam,
  DigitalStatusFilterParam,
  PhysicalStatusFilterParam,
  CheckStatusFilterParam
} from 'apps/MailroomAppV2/hooks/useMailroomFilters'
import SwitchToOldMailroomDialog from 'apps/MailroomAppV2/components/Switchers/SwitchToOldMailroomDialog'
import * as Icon from 'theme/icons'
import ListNavButton from 'lz-design/NavButton'
import { usePageContext } from 'lz-design/PageContext'
import { gql, useQuery } from 'graphql/client'
import SwitchHorizontal from 'theme/icons/SwitchHorizontal'

const GET_RECIPIENTS = gql(`
  query getRecipients($inboxId: ID!) {
    recipients(inboxId: $inboxId) {
      id
      name
    }
  }
`)

export default function LeftMenu(): JSX.Element {
  const { isMobileMenuOpened, toggleMobileMenu } = usePageContext()
  const { currentInbox } = useUserContext()
  const selectedInboxId = currentInbox.id
  const unreadMailsCount = currentInbox.piece_counts.mailroom_unread_piece_count
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {
    filters: { recipientId },
    changeRecipient,
    statuses,
    clearAllStatuses
  } = useMailroomFilters()
  const [initialStatuses] = useState(statuses)
  const { search } = useLocation()
  const { switchable } = useNewMailroomAvailability(currentInbox.id)
  const [isSwitchToLegacyDialogOpen, setIsSwitchToLegacyDialogOpen] = useState(false)
  const { data } = useQuery(GET_RECIPIENTS, { variables: { inboxId: String(selectedInboxId) } })
  const recipients = data?.recipients

  const isRecipientsMenuOpen = Boolean(anchorEl)
  const openRecipientsMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const closeRecipientsMenu = (): void => {
    setAnchorEl(null)
  }

  const closeMenu = (): void => {
    if (isMobileMenuOpened) {
      toggleMobileMenu()
    }
  }

  const selectRecipient = (id?: string) => {
    changeRecipient(id)
    closeRecipientsMenu()
  }

  const statusFiltersDefaultExpanded = Object.values(initialStatuses).flat().length > 0
  const selectedStatusesLength = Object.values(statuses).flat().length

  return (
    <>
      <Typography variant='subtitle2' fontWeight={600} pr={6} pl={8} pb={4}>
        MailRoom
      </Typography>
      <Stack gap={5} overflow='auto' px={6}>
        <Button variant='text' size='large' color='secondary' sx={{ px: 2 }} onClick={openRecipientsMenu}>
          <Icon.User sx={{ mr: 2, fontSize: 20 }} />
          <Typography variant='body2' fontWeight={600}>
            {recipientId ? recipients?.find(({ id }) => id === recipientId)?.name : 'All recipients'}
          </Typography>
          <Icon.ChevronDown sx={{ ml: 'auto', fontSize: 16 }} />
        </Button>
        <Menu anchorEl={anchorEl} open={isRecipientsMenuOpen} onClose={closeRecipientsMenu} elevation={8}>
          <MenuItem selected={!recipientId} onClick={() => selectRecipient()}>
            All recipients
          </MenuItem>
          <ListSubheader>Recipients:</ListSubheader>
          {recipients?.map(({ id, name }) => (
            <MenuItem key={id} selected={id === recipientId} onClick={() => selectRecipient(id)}>
              {name}
            </MenuItem>
          ))}
        </Menu>
        <Divider flexItem sx={{ borderColor: 'grey.100' }} />
        <List>
          <ListNavButton
            onClick={closeMenu}
            icon={Icon.MailboxOutlined}
            activeIcon={Icon.Mailbox}
            route={`/inboxes/${selectedInboxId}/mailroom${search}`}
            end
          >
            Inbox
            {!!unreadMailsCount && (
              <Badge badgeContent={unreadMailsCount} color='primary' standalone sx={{ ml: 'auto' }} />
            )}
          </ListNavButton>
          <ListNavButton
            onClick={closeMenu}
            icon={Icon.MailOpenOutlined}
            activeIcon={Icon.MailOpen}
            route={`/inboxes/${selectedInboxId}/mailroom/${FolderFilterParam.All}${search}`}
          >
            All Mail
          </ListNavButton>
          <ListNavButton
            onClick={closeMenu}
            icon={Icon.PackageOutlined}
            activeIcon={Icon.Package}
            route={`/inboxes/${selectedInboxId}/mailroom/${FolderFilterParam.Archive}${search}`}
          >
            Archive
          </ListNavButton>
          <ListNavButton
            onClick={closeMenu}
            icon={Icon.TrashOutlined}
            activeIcon={Icon.Trash}
            route={`/inboxes/${selectedInboxId}/mailroom/${FolderFilterParam.Trash}${search}`}
          >
            Trash
          </ListNavButton>
        </List>
        <Divider flexItem sx={{ borderColor: 'grey.100', mx: -6 }} />
        <Box sx={{ mb: 6 }}>
          <Accordion defaultExpanded={statusFiltersDefaultExpanded}>
            <AccordionSummary expandIcon={<Icon.ChevronDown fontSize='small' />}>
              <Typography variant='body2' fontWeight={600}>
                Filter by Status
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack gap={5} pl={3} pt={2}>
                <Box display='flex' justifyContent='space-between' alignItems='center' ml={-3}>
                  <Typography variant='body2'>{selectedStatusesLength} selected</Typography>
                  <Button variant='text' size='small' onClick={clearAllStatuses}>
                    Clear all
                  </Button>
                </Box>
                <StatusFilterList options={Object.values(GeneralStatusFilterParam)} selected={statuses.general} />
                <StatusFilterAccordion
                  title='Scan status'
                  options={Object.values(DigitalStatusFilterParam)}
                  selected={statuses.digital}
                  defaultExpanded={initialStatuses.digital.length > 0}
                />
                <StatusFilterAccordion
                  title='Paper status'
                  options={Object.values(PhysicalStatusFilterParam)}
                  selected={statuses.physical}
                  defaultExpanded={initialStatuses.physical.length > 0}
                />
                <StatusFilterAccordion
                  title='Check status'
                  options={Object.values(CheckStatusFilterParam)}
                  selected={statuses.check}
                  defaultExpanded={initialStatuses.check.length > 0}
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Stack>
      {switchable && (
        <>
          <Button
            size='small'
            color='secondary'
            variant='outlined'
            onClick={() => setIsSwitchToLegacyDialogOpen(true)}
            startIcon={<SwitchHorizontal />}
            sx={{ m: 5, mt: 'auto', fontSize: 12 }}
          >
            Switch to legacy MailRoom
          </Button>
          <SwitchToOldMailroomDialog
            onClose={() => setIsSwitchToLegacyDialogOpen(false)}
            open={isSwitchToLegacyDialogOpen}
          />
        </>
      )}
    </>
  )
}

interface StatusFilterAccordionProps extends StatusFilterListProps {
  title: string
  defaultExpanded: boolean
}

function StatusFilterAccordion({ title, options, selected, defaultExpanded }: StatusFilterAccordionProps) {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<Icon.ChevronDown fontSize='small' />} sx={{ p: 0 }}>
        <Typography variant='footnote' textTransform='uppercase' fontWeight={600}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, mt: 4 }}>
        <StatusFilterList options={options} selected={selected} />
      </AccordionDetails>
    </Accordion>
  )
}

interface StatusFilterListProps {
  options: StatusParam[]
  selected: StatusParam[]
}

function StatusFilterList({ options, selected }: StatusFilterListProps) {
  const { switchStatus } = useMailroomFilters()

  return (
    <Stack gap={4}>
      {options.map(status => (
        <FormControlLabel
          key={status}
          checked={selected.includes(status)}
          onChange={(e, checked) => switchStatus(status, checked)}
          control={<Checkbox sx={{ '.MuiSvgIcon-root': { width: 20, height: 20 } }} />}
          label={<Typography variant='body2'>{STATUS_LABELS[status]}</Typography>}
        />
      ))}
    </Stack>
  )
}

const STATUS_LABELS: Record<StatusParam, string> = {
  [GeneralStatusFilterParam.NotViewed]: 'Not viewed',
  [GeneralStatusFilterParam.InShippingCart]: 'In shipping cart',
  [DigitalStatusFilterParam.NoScan]: 'No scan',
  [DigitalStatusFilterParam.Scanning]: 'Scanning',
  [DigitalStatusFilterParam.Scanned]: 'Scanned',
  [PhysicalStatusFilterParam.InStorage]: 'In storage',
  [PhysicalStatusFilterParam.StorageFee]: 'Storage fee',
  [PhysicalStatusFilterParam.Shredding]: 'Shredding',
  [PhysicalStatusFilterParam.Shredded]: 'Shredded',
  [PhysicalStatusFilterParam.Shipping]: 'Shipping',
  [PhysicalStatusFilterParam.Shipped]: 'Shipped',
  [CheckStatusFilterParam.CheckDetected]: 'Check detected',
  [CheckStatusFilterParam.Depositing]: 'Depositing',
  [CheckStatusFilterParam.Deposited]: 'Deposited'
}
