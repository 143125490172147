import { ReactElement, useState, MouseEvent } from 'react'
import { NavLink } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import { Badge, Box, Button, Divider, Link, Menu, Stack, Typography } from '@mui/material'
import { myLzUrl } from 'config/config'
import useUserContext from 'hooks/useUserContext'
import Card from 'lz-design/Card'
import { ChevronDown, ChevronUp } from 'theme/icons'
import { gql, useQuery } from 'graphql/client'
import CopyToClipboardButton from 'lz-design/CopyToClipboardButton'

export default function AccountDropdown(): ReactElement {
  const { currentInbox } = useUserContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { data } = useQuery(GET_INBOXES)

  const orderedInboxes = [...(data?.getInboxes ?? [])].sort(a => (a.id === currentInbox.id ? -1 : 1))

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const EndIcon = open ? ChevronUp : ChevronDown

  return (
    <>
      <Button
        variant='text'
        size='large'
        color='secondary'
        sx={{ backgroundColor: open ? 'grey.50' : '', px: 2 }}
        onClick={handleClick}
        startIcon={<Avatar sx={{ width: 24, height: 24, mr: { xs: -3, md: 0 }, backgroundColor: 'grey.500' }} />}
        endIcon={<EndIcon />}
      >
        <Typography variant='body1' display={{ xs: 'none', md: 'block' }}>
          {currentInbox.account.name}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={8}
        sx={{ mt: 2 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        slotProps={{
          paper: {
            sx: {
              width: {
                xs: '100%',
                sm: 400
              }
            }
          }
        }}
      >
        <Stack p={4} gap={6} alignItems='center'>
          <Avatar sx={{ width: 40, height: 40, backgroundColor: 'grey.500' }} />
          <Typography variant='subtitle2' fontWeight={600}>
            {currentInbox.account.name}
          </Typography>
          <Stack gap={6} width='100%'>
            <Card direction='row' justifyContent='space-between' alignItems='center'>
              <Box>
                <Typography variant='footnote' textTransform='uppercase' color='secondary' fontWeight={600}>
                  Private mailbox number
                </Typography>
                <Typography fontWeight={600}>PMB {`#${currentInbox.account?.ecm_numbers[0]}`}</Typography>
              </Box>
              <CopyToClipboardButton textToCopy={currentInbox.account?.ecm_numbers[0]} alertMessage='PMB copied.' />
            </Card>
            {orderedInboxes.length > 1 && (
              <Stack gap={2}>
                <Typography variant='body2' fontWeight={600}>
                  Switch account
                </Typography>
                {orderedInboxes.map(({ id, account, pieceCounts }) => (
                  <Link key={id} href={`/inboxes/${id}/mailroom`}>
                    <Card
                      gap={2}
                      direction='row'
                      alignItems='center'
                      sx={{
                        ...(id === currentInbox.id && {
                          backgroundColor: 'primary.50',
                          borderColor: 'primary.main'
                        })
                      }}
                    >
                      <Stack>
                        <Typography color='textPrimary' variant='body2' fontWeight={600}>
                          {account.name}
                        </Typography>
                        <Typography color='secondary' variant='body2'>
                          #{account.ecmNumber}
                        </Typography>
                      </Stack>
                      {!!pieceCounts.unreadPieceCount && (
                        <Badge
                          badgeContent={pieceCounts.unreadPieceCount}
                          color='primary'
                          standalone
                          sx={{ ml: 'auto' }}
                        />
                      )}
                    </Card>
                  </Link>
                ))}
              </Stack>
            )}
            <Button href={myLzUrl} variant='outlined' size='large'>
              Go to MyLZ
            </Button>
            <Divider sx={{ mx: -4, borderColor: 'grey.50' }} />
          </Stack>
          <Button component={NavLink} to='/logout' variant='contained' size='large' fullWidth>
            Sign out
          </Button>
        </Stack>
      </Menu>
    </>
  )
}

// TODO: move this to the app setup once we get to this stage as we can reuse data from inboxes in more places
const GET_INBOXES = gql(`
  query getInboxes {
    getInboxes {
      id
      account {
        id
        ecmNumber
        name
      }
      pieceCounts {
        unreadPieceCount
      }
    }
  }
`)
