import { Route, Routes, useParams, Navigate, useLocation } from 'react-router-dom'
import { useNewMailroomAvailability } from 'hooks/useNewMailroomAvailability'
import useUserContext from 'hooks/useUserContext'
import MailItemDetailView from 'apps/MailroomAppV2/components/MailItemDetailView'
import SwitchToNewMailroom from 'apps/MailroomAppV2/components/Switchers/SwitchToNewMailroom'
import LeftSideNavigation from 'lz-design/LeftSideNavigation'
import Page, { PageBody } from 'lz-design/Page'
import LeftMenu from './components/LeftMenu'
import Inboxes from './components/Inboxes'

const MailroomApp = () => {
  const { currentInbox } = useUserContext()
  const { enabled } = useNewMailroomAvailability(currentInbox.id)
  const location = useLocation()
  const isItemDetailsView = location.pathname.includes('mailroom/items/')

  if (!enabled) {
    return (
      <Page>
        <Routes>
          <Route path='/switch-to-new' element={<SwitchToNewMailroom />} />
          <Route path='/items/:id' element={<SpecificItemRedirect />} />
          <Route path='*' element={<Navigate to={`/inboxes/${currentInbox.id}/pieces`} replace />} />
        </Routes>
      </Page>
    )
  }

  return (
    <Page>
      <LeftSideNavigation>{!isItemDetailsView && <LeftMenu />}</LeftSideNavigation>
      <PageBody>
        <Routes>
          <Route path='/switch-to-new' element={<Navigate to={`/inboxes/${currentInbox.id}/mailroom`} replace />} />
          <Route path='/items/:id' element={<MailItemDetailView />} />
          <Route path='/:folder?' element={<Inboxes />} />
        </Routes>
      </PageBody>
    </Page>
  )
}

function SpecificItemRedirect() {
  const { currentInbox } = useUserContext()
  const { id } = useParams<{ id: string }>()

  return <Navigate to={`/inboxes/${currentInbox.id}/pieces/${id}`} replace />
}

export default MailroomApp
