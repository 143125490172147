import React from 'react'
import { Box, Stack } from '@mui/material'
import TopBar, { TOP_BAR_HEIGHT } from './TopBar'
import { PageContextProvider } from './PageContext'

export default function Page({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <PageContextProvider>
      <Stack height='100dvh'>
        <TopBar />
        <Stack direction='row' minHeight={`calc(100dvh - ${TOP_BAR_HEIGHT}px)`}>
          {children}
        </Stack>
      </Stack>
    </PageContextProvider>
  )
}

export function PageBody({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <Box bgcolor='grey.50' flexGrow={1} px={{ xs: 4, md: 6 }} py={{ xs: 4, md: 5 }} display='grid' overflow='auto'>
      {children}
    </Box>
  )
}
