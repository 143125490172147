import { format } from 'date-fns'

export const BASIC_DATE_FORMAT = 'MMM d, y'
export const BASIC_DATE_FORMAT_WITHOUT_YEAR = 'MMM d'
export const DATE_TIME_FORMAT = "MMM d, y 'at' h:mm a"

export const formatDate = (date?: string, dateFormat?: string): string => {
  if (!date) return ''
  return format(new Date(date), dateFormat ?? BASIC_DATE_FORMAT)
}
