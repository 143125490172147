import { createContext, useContext, useReducer } from 'react'

interface PageContextInterface {
  isMobileMenuOpened: boolean
  toggleMobileMenu: () => void
}

const PageContext = createContext<PageContextInterface>({
  isMobileMenuOpened: false,
  toggleMobileMenu: () => {}
})

export function PageContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [isMobileMenuOpened, toggleMobileMenu] = useReducer(currentState => !currentState, false)

  return <PageContext.Provider value={{ isMobileMenuOpened, toggleMobileMenu }}>{children}</PageContext.Provider>
}

export function usePageContext(): PageContextInterface {
  return useContext(PageContext)
}
