import { Button, Divider, Drawer, List, Stack, Theme, useMediaQuery } from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { usePageContext } from './PageContext'
import ListNavButton from './NavButton'
import * as Icon from 'theme/icons'

export default function LeftSideNavigation({ children }: { children?: React.ReactNode }): React.JSX.Element {
  const { currentInbox } = useUserContext()
  const selectedInboxId = currentInbox.id
  const hasUnreadMail = currentInbox.piece_counts.mailroom_unread_piece_count > 0

  return (
    <NavigationContainer subMenu={children}>
      <List sx={{ alignItems: 'center', gap: 4, p: 0, width: '100%' }}>
        <ListNavButton icon={Icon.Home} route={`/inboxes/${selectedInboxId}/dashboard`} />
        <ListNavButton
          badged={hasUnreadMail}
          icon={Icon.Mail}
          route={`/inboxes/${selectedInboxId}/mailroom`}
        ></ListNavButton>
        <ListNavButton icon={Icon.Truck} route={`/inboxes/${selectedInboxId}/shipments`} />
        <ListNavButton icon={Icon.Bank} route={`/inboxes/${selectedInboxId}/deposits`} />
        <Divider flexItem sx={{ borderColor: 'grey.100' }} />
        <ListNavButton icon={Icon.Settings} route={`/settings/${selectedInboxId}/profile`} />
      </List>
      <List sx={{ alignItems: 'center', gap: 4, p: 0, width: '100%' }}>
        <ListNavButton icon={Icon.InfoOutlined} route='/info' />
        <Divider flexItem sx={{ borderColor: 'grey.100' }} />
        <ListNavButton icon={Icon.LogOutOutlined} route='/logout' />
      </List>
    </NavigationContainer>
  )
}

function NavigationContainer({
  children,
  subMenu
}: {
  children: React.ReactNode
  subMenu?: React.ReactNode
}): React.JSX.Element {
  const { toggleMobileMenu } = usePageContext()

  return (
    <ResponsiveDrawer>
      <Stack direction='row' position='relative' bgcolor='white' component='nav' height='100%'>
        <Button
          sx={{
            display: { md: 'none' },
            position: 'absolute',
            right: 16,
            top: 16,
            minWidth: 'auto',
            p: 1.5
          }}
          variant='outlined'
          color='secondary'
          size='small'
          onClick={toggleMobileMenu}
        >
          <Icon.Close sx={{ fontSize: 16 }} />
        </Button>
        <Stack
          width={64}
          alignItems='center'
          pt={{ xs: 9, md: 5 }}
          pb={4}
          justifyContent='space-between'
          overflow='auto'
        >
          {children}
        </Stack>
        {subMenu && (
          <Stack
            pt={{ xs: 9, md: 6.5 }}
            width={240}
            sx={{
              backgroundColor: 'grey.25'
            }}
          >
            {subMenu}
          </Stack>
        )}
      </Stack>
    </ResponsiveDrawer>
  )
}

function ResponsiveDrawer({ children }: { children: React.ReactNode }): React.JSX.Element {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { isMobileMenuOpened, toggleMobileMenu } = usePageContext()

  return isMobile ? (
    <Drawer open={isMobileMenuOpened} onClose={toggleMobileMenu} sx={{ '& .MuiPaper-root': { boxShadow: 'none' } }}>
      {children}
    </Drawer>
  ) : (
    <>{children}</>
  )
}
