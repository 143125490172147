import { ThemeOptions } from '@mui/material/styles'

export const MuiAutocomplete: Exclude<ThemeOptions['components'], undefined>['MuiAutocomplete'] = {
  styleOverrides: {
    inputRoot: ({ theme }) => ({
      padding: theme.spacing(1, 2),
      minHeight: '50px'
    }),
    paper: ({ theme }) => ({
      boxShadow: 'none',
      border: '1px solid',
      borderColor: theme.palette.grey[100]
    }),
    noOptions: ({ theme }) => ({
      padding: theme.spacing(2, 1)
    }),
    option: ({ theme }) => ({
      wordBreak: 'break-word',
      borderRadius: theme.shape.borderRadius
    }),
    listbox: ({ theme }) => ({
      padding: theme.spacing(2, 1),
      '& .MuiAutocomplete-option': {
        padding: theme.spacing(2, 3),
        marginTop: theme.spacing(2),
        '&:first-of-type': {
          marginTop: 0
        }
      }
    })
  }
}
