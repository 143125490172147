import { ReactNode, useState } from 'react'
import { Stack, StackProps, Tab, Tabs } from '@mui/material'
import { RequestAction } from 'apps/MailroomAppV2/hooks/useItemsAction'
import OverviewTab from './OverviewTab'
import ContentTab from './ContentTab'
import HistoryTab from './HistoryTab'
import TabContent from './TabContent'
import { MailItemFragment } from 'graphql/__generated__/graphql'

enum TabName {
  Overview = 'Overview',
  Content = 'Content',
  History = 'History'
}

interface MailItemDetailsProps extends StackProps {
  mailItem: MailItemFragment
  isItemDetailsView?: boolean
  onClearItemsState?: () => void
  requestItemAction: RequestAction
  children: ReactNode
}

const MailItemDetails = ({
  mailItem,
  requestItemAction,
  isItemDetailsView = false,
  children,
  ...stackProps
}: MailItemDetailsProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TabName>(TabName.Overview)

  return (
    <Stack overflow='hidden' mb={{ xs: 7, md: 0 }} {...stackProps}>
      <Stack gap={{ xs: 2.5, lg: 0 }} width='100%'>
        {children}
        <Tabs value={activeTab} onChange={(_event, newValue): void => setActiveTab(newValue)}>
          <Tab label='Overview' data-text={TabName.Overview} value={TabName.Overview} />
          <Tab
            label='Content'
            data-text={TabName.Content}
            value={TabName.Content}
            sx={{ display: { lg: isItemDetailsView ? 'none' : 'inline-flex' } }}
          />
          <Tab label='History' data-text={TabName.History} value={TabName.History} />
        </Tabs>
      </Stack>

      <TabContent value={activeTab} index={TabName.Overview}>
        <OverviewTab mailItem={mailItem} requestItemAction={requestItemAction} isItemDetailsView={isItemDetailsView} />
      </TabContent>
      <TabContent value={activeTab} index={TabName.Content}>
        <ContentTab mailItem={mailItem} requestItemAction={requestItemAction} />
      </TabContent>
      <TabContent value={activeTab} index={TabName.History}>
        <HistoryTab mailItem={mailItem} />
      </TabContent>
    </Stack>
  )
}

export default MailItemDetails
