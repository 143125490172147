import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function InfoOutlined(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
        <g clipPath='url(#clip0_1146_16681)'>
          <path
            d='M10.9997 14.6663V10.9997M10.9997 7.33301H11.0088M20.1663 10.9997C20.1663 16.0623 16.0623 20.1663 10.9997 20.1663C5.93706 20.1663 1.83301 16.0623 1.83301 10.9997C1.83301 5.93706 5.93706 1.83301 10.9997 1.83301C16.0623 1.83301 20.1663 5.93706 20.1663 10.9997Z'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_1146_16681'>
            <rect width='22' height='22' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
