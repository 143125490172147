import { ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/Avatar' {
  interface AvatarOwnProps {
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'purple'
  }
}

export const MuiAvatar: Exclude<ThemeOptions['components'], undefined>['MuiAvatar'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      backgroundColor: ownerState.color ? theme.palette[ownerState.color].dark : undefined,
      color: ownerState.color ? theme.palette[ownerState.color].light : undefined,
      width: 32,
      height: 32,
      fontWeight: 600,
      '&, & > .MuiSvgIcon-root': {
        fontSize: 14
      }
    })
  }
}
