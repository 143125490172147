import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { relayStylePagination } from '@apollo/client/utilities'
import { graphqlBaseURL } from 'config/config'
import { getOrRefreshAccessTokenWithCognitoOrStoredCredentials } from 'utils/credentials_service'

const httpLink = createHttpLink({
  uri: graphqlBaseURL
})

const authLink = setContext(async (_, { headers }) => {
  let token
  try {
    token = await getOrRefreshAccessTokenWithCognitoOrStoredCredentials()
  } catch (e) {
    console.error('Failed to retrieve token for API request', e)
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          mailItems: relayStylePagination(['filters', 'accountId', 'inboxId'])
        }
      },
      ShippingCart: {
        keyFields: ['inboxId', 'userId']
      },
      ShareLinkData: {
        keyFields: ['linkKey']
      },
      VirtualMailItemShareData: {
        keyFields: ['mailItemId']
      },
      AccountFlag: {
        keyFields: ['accountId', 'key']
      },
      VirtualMailItem: {
        fields: {
          sender: {
            read: sender => sender || 'Unknown Sender'
          },
          recipient: {
            read: recipient => recipient || { name: 'Unknown Recipient' }
          }
        }
      }
    }
  })
})

if (process.env.NODE_ENV === 'development') {
  import('@apollo/client-devtools-vscode').then(({ connectApolloClientToVSCodeDevTools }) =>
    connectApolloClientToVSCodeDevTools(client, 'ws://localhost:7095')
  )
}

export default client

export { useQuery, useMutation, useLazyQuery } from '@apollo/client'
export { gql } from 'graphql/__generated__'
