import { useReducer, useState } from 'react'
import { Box, Button, Stack, Switch, Typography } from '@mui/material'
import { formatDate } from 'utils/format_date'
import { RequestAction } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { FOLDER_LABELS } from 'apps/MailroomAppV2/constants/folderLabels'
import { SCAN_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/ScanActionDialog'
import { DOWNLOAD_PDF_ACTION_REQUEST_PARAMS } from 'apps/MailroomAppV2/utils/downloadPdfAction'
import { CANCEL_SCAN_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/CancelScanActionDialog'
import { CANCEL_SHRED_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/CancelShredActionDialog'
import ToggleCheckDetectedStatusDialog from './ToggleCheckDetectedStatusDialog'
import UpdateSenderNameDialog from './UpdateSenderNameDialog'
import StorageDetailsDialog from './StorageDetailsDialog'
import ItemDetails from './ItemDetails'
import StatusItemDetails, { DigitalStatusItemDetails, ItemActions } from './StatusItemDetails'
import MailItemPreviewDialog from 'lz-design/MailItemPreviewDialog'
import {
  MailItemFragment,
  VirtualMailItemCheckStatus,
  VirtualMailItemFlag,
  VirtualMailItemPhysicalStatus
} from 'graphql/__generated__/graphql'
import { useAlert } from 'lz-design/AlertContext'
import CopyToClipboardButton from 'lz-design/CopyToClipboardButton'
import { ChevronDown, ZoomInOutlined, ShoppingCart, Coins } from 'theme/icons'

interface OverviewTabProps {
  mailItem: MailItemFragment
  isItemDetailsView?: boolean
  requestItemAction: RequestAction
}

export enum DialogTypes {
  PreviewImg = 'previewImg',
  CheckDetected = 'checkDetected',
  UpdateSenderName = 'updateSenderName',
  StorageDetails = 'storageDetails',
  OutstandingStorageFee = 'outstandingStorageFee'
}

const OverviewTab = ({ mailItem, requestItemAction, isItemDetailsView = false }: OverviewTabProps) => {
  const [viewMore, toggleViewMore] = useReducer(prev => !prev, isItemDetailsView)
  const [openedDialog, setOpenedDialog] = useState<DialogTypes | undefined>()
  const { setAlert } = useAlert()

  const envelopeImgUrl = mailItem.enclosure.largeUrl || ''
  const isCheckDetected = !!mailItem.flags?.includes(VirtualMailItemFlag.VirtualMailItemFlagCheckDetected)
  const isChangingCheckStatusPossible =
    !isCheckDetected || mailItem.checkStatus === VirtualMailItemCheckStatus.VirtualMailItemCheckStatusCheckDetected
  const hasOutstandingStorageFee = mailItem.flags?.includes(
    VirtualMailItemFlag.VirtualMailItemFlagOutstandingStorageFee
  )

  const itemActions: ItemActions = {
    scan: () => requestItemAction(SCAN_ACTION_REQUEST),
    cancelScan: () => requestItemAction(CANCEL_SCAN_ACTION_REQUEST),
    download: () => requestItemAction(...DOWNLOAD_PDF_ACTION_REQUEST_PARAMS),
    cancelShred: () => requestItemAction(CANCEL_SHRED_ACTION_REQUEST),
    details: () => {
      if (
        [
          VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage,
          VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee
        ].includes(mailItem.physicalStatus)
      ) {
        setOpenedDialog(DialogTypes.StorageDetails)
      }
    },
    cancelShip: () => console.log('Cancel ship', mailItem),
    deposit: () => console.log('Deposit', mailItem),
    cancelDeposit: () => console.log('Cancel deposit', mailItem)
  }

  function onCheckDetected() {
    if (!isChangingCheckStatusPossible) {
      const message = `Action unavailable. The item ${
        mailItem?.checkStatus === VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositRequested
          ? 'has a pending deposit request'
          : 'is currently being deposited'
      }.`
      setAlert({
        severity: 'warning',
        message
      })
    } else {
      setOpenedDialog(DialogTypes.CheckDetected)
    }
  }

  return (
    <>
      {envelopeImgUrl && (
        <Box
          position='relative'
          bgcolor='grey.50'
          borderRadius={1}
          p={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Box component='img' src={envelopeImgUrl} maxHeight='420px' maxWidth='100%' />
          <Button
            sx={{
              position: 'absolute',
              right: '16px',
              bottom: '20px',
              minWidth: '48px',
              height: '48px'
            }}
            variant='outlined'
            color='secondary'
            onClick={() => setOpenedDialog(DialogTypes.PreviewImg)}
          >
            <ZoomInOutlined />
          </Button>
        </Box>
      )}
      <Box display='grid' gridTemplateColumns={{ lg: isItemDetailsView ? 'initial' : '1fr 1fr' }} gap={6} pt={6}>
        <Stack>
          <Typography variant='body1' fontWeight={600}>
            Item status
          </Typography>
          <Stack pt={3} gap={3}>
            <DigitalStatusItemDetails
              status={mailItem.digitalStatus}
              physicalStatus={mailItem.physicalStatus}
              itemActions={itemActions}
            />
            <StatusItemDetails status={mailItem.physicalStatus} label='Paper status' itemActions={itemActions} />
            <StatusItemDetails status={mailItem.checkStatus} itemActions={itemActions} />
            {hasOutstandingStorageFee && (
              <ItemDetails value='Outstanding storage fee is $1.50' color='secondary' icon={<Coins />}>
                <ItemDetails.Button onClick={() => setOpenedDialog(DialogTypes.OutstandingStorageFee)}>
                  Details
                </ItemDetails.Button>
              </ItemDetails>
            )}
            {mailItem.inShippingCart && (
              <ItemDetails value='In shipping cart' color='purple' icon={<ShoppingCart />}>
                <ItemDetails.Button>View</ItemDetails.Button>
              </ItemDetails>
            )}
          </Stack>
        </Stack>
        <Stack>
          <Typography variant='body1' fontWeight={600}>
            Item details
          </Typography>
          <Stack pt={3} gap={3}>
            <ItemDetails label='sender' value={mailItem.sender} isBolded>
              <ItemDetails.Button onClick={() => setOpenedDialog(DialogTypes.UpdateSenderName)}>
                Edit
              </ItemDetails.Button>
            </ItemDetails>
            <ItemDetails label='recipient' value={mailItem.recipient?.name} isBolded />
            <Box display='flex' gap={2}>
              <ItemDetails label='received' value={formatDate(mailItem.receivedAt)} />
              <ItemDetails label='facility' value={mailItem.facility} />
            </Box>
            {viewMore && (
              <Stack gap={3}>
                <ItemDetails label='item id' value={mailItem.barcode}>
                  <CopyToClipboardButton textToCopy={mailItem.barcode} alertMessage='ID item copied.' />
                </ItemDetails>
                <ItemDetails label='location' value={FOLDER_LABELS[mailItem.folder]} />
                <ItemDetails label='check detected'>
                  <Switch
                    checked={isCheckDetected}
                    onClick={event => {
                      event.preventDefault()
                      onCheckDetected()
                    }}
                  />
                </ItemDetails>
                <Box display='flex' gap={2}>
                  <ItemDetails label='weight' value={`${mailItem.physicalDimensions.weightInOunces} oz`} />
                  <ItemDetails
                    label='pages'
                    value={
                      mailItem.physicalDimensions.pageCountActual ||
                      `${mailItem.physicalDimensions.pageCountEstimate} (estimated)`
                    }
                  />
                </Box>
                <Box display='flex' gap={2}>
                  <ItemDetails
                    label='package type'
                    valueTypographyProps={{
                      textTransform: 'capitalize'
                    }}
                    value={[mailItem.mailType, mailItem.mailSubtype || ''].filter(Boolean).join(', ')}
                  />
                  <ItemDetails label='carrier' value={mailItem.senderCarrier} />
                </Box>
              </Stack>
            )}
            {!isItemDetailsView && (
              <Button
                variant='text'
                startIcon={<ChevronDown sx={{ rotate: viewMore ? '180deg' : 'none' }} />}
                fullWidth
                onClick={toggleViewMore}
                sx={{ mt: 1 }}
              >
                View {viewMore ? 'less' : 'more'}
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>

      {openedDialog === DialogTypes.PreviewImg && (
        <MailItemPreviewDialog onDialogClose={() => setOpenedDialog(undefined)} imgUrl={envelopeImgUrl} />
      )}
      {openedDialog === DialogTypes.CheckDetected && (
        <ToggleCheckDetectedStatusDialog
          mailItem={mailItem}
          isCheckDetected={isCheckDetected}
          onDialogClose={() => setOpenedDialog(undefined)}
        />
      )}
      {openedDialog === DialogTypes.UpdateSenderName && (
        <UpdateSenderNameDialog mailItem={mailItem} onDialogClose={() => setOpenedDialog(undefined)} />
      )}
      {openedDialog && [DialogTypes.StorageDetails, DialogTypes.OutstandingStorageFee].includes(openedDialog) && (
        <StorageDetailsDialog
          openedDialogType={openedDialog}
          mailItem={mailItem}
          onDialogClose={() => setOpenedDialog(undefined)}
        />
      )}
    </>
  )
}

export default OverviewTab
