import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: TypographyStyleOptions
    subtitle4: TypographyStyleOptions
    footnote: TypographyStyleOptions
  }

  interface TypographyVariantsOptions {
    subtitle3?: TypographyStyleOptions
    subtitle4?: TypographyStyleOptions
    footnote?: TypographyStyleOptions
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true
    subtitle4: true
    footnote: true
  }
}

export const typography: TypographyOptions = {
  fontFamily: [
    'Work Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    "'Segoe UI'",
    "'Roboto'",
    "'Oxygen'",
    "'Ubuntu'",
    "'Cantarell'",
    "'Fira Sans'",
    "'Droid Sans'",
    "'Helvetica Neue'",
    'sans-serif'
  ].join(','),
  h1: {
    fontSize: 128,
    lineHeight: '140px'
  },
  h2: {
    fontSize: 96,
    lineHeight: '112px'
  },
  h3: {
    fontSize: 80,
    lineHeight: '92px'
  },
  h4: {
    fontSize: 64,
    lineHeight: '72px'
  },
  h5: {
    fontSize: 48,
    lineHeight: '56px'
  },
  h6: {
    fontSize: 40,
    lineHeight: '48px'
  },
  subtitle1: {
    fontSize: 32,
    lineHeight: '40px'
  },
  subtitle2: {
    fontSize: 24,
    lineHeight: '28px'
  },
  subtitle3: {
    fontSize: 20,
    lineHeight: '24px'
  },
  subtitle4: {
    fontSize: 18,
    lineHeight: '24px'
  },
  body1: {
    fontSize: 16,
    lineHeight: '24px'
  },
  body2: {
    fontSize: 14,
    lineHeight: '20px'
  },
  footnote: {
    fontSize: 12,
    lineHeight: '16px'
  },
  fontWeightBold: '600 !important'
}
