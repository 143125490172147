import { ThemeOptions } from '@mui/material/styles'
import { alpha } from '@mui/material'
import { Radio, RadioChecked } from 'theme/icons'

export const MuiRadio: Exclude<ThemeOptions['components'], undefined>['MuiRadio'] = {
  defaultProps: {
    icon: <Radio />,
    checkedIcon: <RadioChecked />
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      padding: theme.spacing(1.5),
      margin: theme.spacing(-1.5),
      color: theme.palette.grey[300],
      '& .MuiSvgIcon-root': {
        backgroundColor: 'white'
      },
      '&:hover, &:has(input:hover)': {
        backgroundColor: alpha(theme.palette[ownerState.color || 'primary'].main, 0.05)
      }
    })
  }
}
