import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Lock(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M6 8a6 6 0 1 1 12 0v1.15c.283.062.554.152.816.286a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564.044.541.044 1.206.044 2.01v1.483c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H8.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C3 17.71 3 17.046 3 16.242v-1.483c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.262-.134.533-.224.816-.286zm10 0v1.002H8V8a4 4 0 1 1 8 0m-3 6.5a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0z'
          clipRule='evenodd'
        />
      </svg>
    </SvgIcon>
  )
}
