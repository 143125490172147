import { ThemeOptions } from '@mui/material/styles'
import { Alert, CheckCircle, Info } from 'theme/icons'

export const MuiAlert: Exclude<ThemeOptions['components'], undefined>['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      success: <CheckCircle />,
      info: <Info />,
      warning: <Alert />,
      error: <Alert />
    }
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      padding: theme.spacing(4),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette[ownerState.severity || 'success'].main,
      backgroundColor: theme.palette[ownerState.severity || 'success'].light
    }),
    icon: ({ theme }) => ({
      padding: 0,
      marginRight: theme.spacing(2)
    }),
    message: ({ theme }) => ({
      fontSize: 16,
      lineHeight: '24px',
      padding: 0,
      color: theme.palette.text.primary
    }),
    action: ({ theme }) => ({
      marginRight: 0,
      padding: 0,
      '& > button': {
        padding: 0
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        fontSize: 24
      }
    })
  }
}
