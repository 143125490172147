import { ThemeOptions } from '@mui/material/styles'

export const MuiSwitch: Exclude<ThemeOptions['components'], undefined>['MuiSwitch'] = {
  styleOverrides: {
    root: {
      height: 48,
      width: 68
    },
    switchBase: ({ theme }) => ({
      padding: theme.spacing(3.5)
    }),
    thumb: ({ theme: { palette } }) => ({
      boxShadow: `0px 1px 8px 0px ${palette.grey[800]}0A`
    }),
    track: ({ theme: { shape, palette } }) => ({
      borderRadius: shape.borderRadius * 1.5,
      background: palette.grey[800]
    })
  }
}
