import { ThemeOptions } from '@mui/material/styles'

export const MuiTooltip: Exclude<ThemeOptions['components'], undefined>['MuiTooltip'] = {
  styleOverrides: {
    arrow: ({ theme }) => ({
      color: theme.palette.secondary.dark
    }),
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.dark,
      lineHeight: '20px',
      fontSize: 14,
      padding: theme.spacing(4)
    })
  },
  defaultProps: {
    arrow: true
  }
}
