import { useNavigate } from 'react-router-dom'
import { DialogTitle, DialogContent, DialogActions, Button, Typography, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import pluralize from 'pluralize'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { formatReceivedDate } from 'apps/MailroomAppV2/utils/formatReceivedDate'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import DialogWithCloseButton from 'lz-design/DialogWithCloseButton'
import { ShoppingCart } from 'theme/icons'
import Card from 'lz-design/Card'
import { gql, useMutation } from 'graphql/client'
import { VirtualMailItemDigitalStatus, VirtualMailItemPhysicalStatus } from 'graphql/__generated__/graphql'

export const SHIP_NOW_ITEMS_PARAM = 'items'

export default function ShipActionDialog({
  itemsSuitableForAction,
  onCancel,
  onSuccess,
  onError,
  suitabilityAlert
}: RequestedActionProps) {
  const navigate = useNavigate()
  const { currentInbox } = useUserContext()

  const mailItemIds = itemsSuitableForAction.map(item => item.id)

  const [addToCart, { loading: addingToCart }] = useMutation(SHIP_ITEMS, {
    variables: {
      input: {
        inboxId: String(currentInbox.id),
        mailItemIds
      }
    },
    update: cache =>
      cache.modify({
        fields: {
          mailItems: (data, { storeFieldName, DELETE }) => (storeFieldName.includes('inShippingCart') ? DELETE : data)
        }
      }),
    onCompleted: () => onSuccess(`${pluralize('item', itemsSuitableForAction.length, true)} added to the cart.`),
    onError: () =>
      onError(
        `${['Item', 'have']
          .map(word => pluralize(word, itemsSuitableForAction.length))
          .join(' ')} not been added to the cart. Please try again later.`
      )
  })

  function handleShipNow() {
    const searchParams = new URLSearchParams(mailItemIds.map(id => [SHIP_NOW_ITEMS_PARAM, id]))
    navigate({
      pathname: `/inboxes/${currentInbox.id}/shipments/new-shipment`,
      search: searchParams.toString()
    })
  }

  return (
    <DialogWithCloseButton open onClose={onCancel}>
      <DialogTitle>Ship</DialogTitle>
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        <Stack gap={3}>
          {itemsSuitableForAction.map(item => (
            <Card direction='row' gap={6} alignItems='center' key={item.id}>
              <div>
                <Typography fontWeight={600}>{item.recipient?.name}</Typography>
                <Typography>{item.sender}</Typography>
              </div>
              <Typography variant='body2' sx={{ marginLeft: 'auto' }}>
                {formatReceivedDate(item.receivedAt)}
              </Typography>
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant='outlined'
          startIcon={<ShoppingCart />}
          size='large'
          onClick={() => void addToCart()}
          loading={addingToCart}
        >
          Add to cart
        </LoadingButton>
        <Button disabled={addingToCart} variant='contained' size='large' onClick={handleShipNow}>
          Ship now
        </Button>
      </DialogActions>
    </DialogWithCloseButton>
  )
}

export const SHIP_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.SHIP,
  adjective: 'shipped',
  isSuitable: ({ digitalStatus, physicalStatus, inShippingCart }) =>
    digitalStatus !== VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusActionsBlockedNoReverification &&
    [
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage,
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee
    ].includes(physicalStatus) &&
    !inShippingCart
}

const SHIP_ITEMS = gql(`
  mutation addItemsToShippingCart($input: AddItemsToShippingCartInput!) {
    addItemsToShippingCart(input: $input) {
      inboxId
      userId
      itemCount
      items {
        id
        inShippingCart
      }
    }
  }
`)
