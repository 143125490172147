import { config as localConfig } from './local'
import { config as qaConfig } from './qa'
import { config as prdConfig } from './prd'
import { config as devConfig } from './dev'

export interface AngularConfig {
  restServices: {
    mockServiceHost: string
    apiServiceHost: string
    apiConnectorServiceHost: string
    configServiceHost: string
    defaultRestPrefix: string
    defaultRestConfigPrefix: string
    defaultConnectorRestPrefix: string
    connectorOmitRESTPrefix: boolean
    defaultRestShipmentsPrefix: string
    defaultRestShippingAddressesPrefix: string
    defaultRestMetaPrefix: string
    apiServiceApiKey: null
  }
  connectorServiceBaseUrl: string
  connectorServiceBillcomEndpoint: string
  appUrl: string
  backendStubDelay: number
  cookieDomain: string
  debug: boolean
  defaultCacheLengthInMinutes: number
  googleTagManagerId: string
  properName: string
  shortName: string
  maintenanceMode: boolean
  sharePortalUrl: string
  useNewShare: boolean
  amplitudeApiKey: string
}

export interface EnvConfig {
  myLzUrl: string
  amplitudeApiKey: string
  apiBaseURL: string
  graphqlBaseURL: string
  appCookieDomain: string
  appUrl: string
  awsCognitoRegion: string
  awsOAuthDomain: string
  awsOAuthSignInRedirect: string
  awsOAuthSignOutRedirect: string
  awsProjectRegion: string
  awsSecureCookie: boolean
  awsUserPoolsId: string
  awsUserPoolsMarketingClientId: string
  awsUserPoolsWebClientId: string
  connectorServiceBaseUrl: string
  connectorServiceBillcomEndpoint: string
  googleMapsApiKey: string
  googleTagManagerId: string
  hotjarId: string
  hotjarSnippetVersion: string
  launchDarklyClientSideId: string
  marketingRedirectUrl: string
  pusherAppKey: string
  pusherCluster: string
  sharePortalUrl: string
  stripePublicKey: string
  datadogAppId: string
  datadogClientToken: string
  env: 'development' | 'qa' | 'production' | 'local'
}

export function getEnvConfig(): EnvConfig {
  if (window.location.hostname.includes('ci.ecm.network')) {
    return devConfig
  }
  if (window.location.hostname.includes('dev.ecm.network')) {
    return devConfig
  }
  if (window.location.hostname.includes('qa.ecm.network')) {
    return qaConfig
  }
  if (window.location.hostname.includes('local')) {
    return localConfig
  }
  return prdConfig
}

export function getAngularConfig(): AngularConfig {
  const config = getEnvConfig()

  return {
    restServices: {
      mockServiceHost: config.apiBaseURL,
      apiServiceHost: config.apiBaseURL,
      apiConnectorServiceHost: config.apiBaseURL,
      configServiceHost: config.apiBaseURL,
      defaultRestPrefix: '/v1',
      defaultRestConfigPrefix: '/v1',
      defaultConnectorRestPrefix: '/v1',
      connectorOmitRESTPrefix: false,
      defaultRestShipmentsPrefix: '/v1',
      defaultRestShippingAddressesPrefix: '/v1',
      defaultRestMetaPrefix: '/v1',
      apiServiceApiKey: null
    },
    connectorServiceBaseUrl: config.connectorServiceBaseUrl,
    connectorServiceBillcomEndpoint: config.connectorServiceBillcomEndpoint,
    appUrl: config.appUrl,
    backendStubDelay: 0,
    cookieDomain: config.appCookieDomain,
    debug: false,
    defaultCacheLengthInMinutes: 5,
    googleTagManagerId: config.googleTagManagerId,
    properName: 'Earth Class Mail',
    shortName: 'earthclassmail',
    maintenanceMode: false,
    sharePortalUrl: config.sharePortalUrl,
    useNewShare: true,
    amplitudeApiKey: config.amplitudeApiKey
  }
}
