import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Scissors(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M3.957 9.44a4 4 0 1 1 4.226-.087l2.996 1.525 9.367-4.769a1 1 0 1 1 .908 1.782L13.384 12l8.07 4.109a1 1 0 1 1-.908 1.782l-9.367-4.769-2.996 1.526a4 4 0 1 1-3.845-.287L8.975 12 4.338 9.64a4 4 0 0 1-.38-.2M4 6a2 2 0 1 1 1.193 1.83l-.196-.1A2 2 0 0 1 4 6m.997 10.27a2 2 0 1 0 .197-.1z'
          clipRule='evenodd'
        />
      </svg>
    </SvgIcon>
  )
}
