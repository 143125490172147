import { ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/Badge' {
  interface BadgeOwnProps {
    standalone?: true
  }
  interface BadgePropsColorOverrides {
    purple: true
  }
}

export const MuiBadge: Exclude<ThemeOptions['components'], undefined>['MuiBadge'] = {
  styleOverrides: {
    badge: ({ ownerState, theme }) => ({
      borderRadius: theme.shape.borderRadius,
      fontWeight: 600,
      position: ownerState.standalone ? 'static' : undefined,
      transform: ownerState.standalone ? 'none' : undefined,
      backgroundColor:
        ownerState.color === 'purple'
          ? theme.palette[ownerState.color || 'secondary']?.dark
          : theme.palette[ownerState.color || 'secondary']?.main // TODO: discuss with Magda if we want to make an exception for the purple color
    })
  }
}
