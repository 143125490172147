import { compact } from 'lodash'
import { RequestAction } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { downloadFiles } from 'apps/MailroomAppV2/utils/downloadFiles'
import { VirtualMailItemDigitalStatus } from 'graphql/__generated__/graphql'

export const DOWNLOAD_PDF_ACTION_REQUEST_PARAMS: Parameters<RequestAction> = [
  {
    action: ItemsActionType.DOWNLOAD_PDF,
    adjective: 'downloaded',
    isSuitable: ({ digitalStatus, scan }) =>
      digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanCompleted && !!scan?.pdfUrl,
    suitabilityExplanation: 'To download a PDF, please request a scan first.'
  },
  async ({ itemsSuitableForAction, onSuccess, onError }) => {
    try {
      await downloadFiles(
        compact(
          itemsSuitableForAction.map(
            ({ scan, barcode }) => scan?.pdfUrl && { url: scan.pdfUrl, filename: `${barcode}.pdf` }
          )
        )
      )
      onSuccess(false)
    } catch (e) {
      onError('Download PDF process has failed. Please try again later.')
    }
  }
]
