import { DialogTitle, DialogContent, Typography, DialogActions, Dialog, Button, Radio } from '@mui/material'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import pluralize from 'pluralize'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import { extractQueryVariablesFromStoreFieldName } from 'apps/MailroomAppV2/utils/extractQueryVariablesFromStoreFieldName'
import OutlinedFormControlLabel from 'lz-design/OutlinedFormControlLabel'
import { gql, useMutation } from 'graphql/client'
import {
  VirtualMailItemPostScanAction,
  VirtualMailItemScanType,
  GetMailItemsQueryVariables,
  VirtualMailItemDigitalStatus,
  VirtualMailItemPhysicalStatus
} from 'graphql/__generated__/graphql'
import { HookedRadioGroup } from 'lz-design/forms/inputs'

export default function ScanActionDialog({
  onCancel,
  suitabilityAlert,
  onSuccess,
  onError,
  itemsSuitableForAction
}: RequestedActionProps) {
  const { currentInbox } = useUserContext()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm<ScanItemsFormValues>({
    defaultValues: {
      postScanAction: VirtualMailItemPostScanAction.VirtualMailItemPostScanActionShred
    }
  })
  const [requestItemsScan] = useMutation(REQUEST_ITEMS_SCAN, {
    onCompleted: () => onSuccess(`${pluralize('item', itemsSuitableForAction.length, true)} scan requested.`),
    onError: () => onError('Scan request failed. Please try again later.'),
    update: cache =>
      cache.modify({
        fields: {
          mailItems: (data, { fieldName, storeFieldName, DELETE }) => {
            const variables = extractQueryVariablesFromStoreFieldName<GetMailItemsQueryVariables>(
              fieldName,
              storeFieldName
            )

            return variables.filters?.digitalStatusIn?.some(status =>
              [
                VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan,
                VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanRequested
              ].includes(status)
            )
              ? DELETE
              : data
          }
        }
      })
  })

  const onSubmit = async (values: ScanItemsFormValues) =>
    requestItemsScan({
      variables: {
        input: {
          inboxId: String(currentInbox.id),
          mailItemIds: itemsSuitableForAction.map(item => item.id),
          postScanAction: values.postScanAction,
          scanType: VirtualMailItemScanType.VirtualMailItemScanTypeColor
        }
      }
    })

  return (
    <Dialog open onClose={onCancel} component='form' onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Scan options</DialogTitle>
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        <Typography fontWeight={600}>Select an action for the item after scanning:</Typography>
        <HookedRadioGroup name='postScanAction' sx={{ gap: 3, marginTop: 4 }} control={control}>
          <OutlinedFormControlLabel
            value={VirtualMailItemPostScanAction.VirtualMailItemPostScanActionShred}
            control={<Radio />}
            label='Shred'
          />
          <OutlinedFormControlLabel
            value={VirtualMailItemPostScanAction.VirtualMailItemPostScanActionStore}
            control={<Radio />}
            label='Re-envelope and store'
          />
        </HookedRadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} size='large'>
          Cancel
        </Button>
        <LoadingButton loading={isSubmitting} type='submit' variant='contained' size='large'>
          Scan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

interface ScanItemsFormValues {
  postScanAction: VirtualMailItemPostScanAction
}

export const SCAN_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.SCAN,
  adjective: 'scanned',
  isSuitable: ({ digitalStatus, physicalStatus }) =>
    digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan &&
    [
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage,
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee
    ].includes(physicalStatus)
}

const REQUEST_ITEMS_SCAN = gql(`
  mutation requestItemsScan($input: RequestMailItemsScanInput!) {
    requestMailItemsScan(input: $input) {
      id,
      digitalStatus
    }
  }
`)
