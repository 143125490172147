import { ReactNode } from 'react'
import { Box, BoxProps } from '@mui/material'

interface TabContentProps extends BoxProps {
  children: ReactNode
  index: string
  value: string
}

const TabContent = ({ children, value, index, ...restProps }: TabContentProps): JSX.Element => {
  return (
    <Box
      hidden={value !== index}
      role='tabpanel'
      flexGrow={1}
      bgcolor='white'
      borderRadius={1}
      p={6}
      {...restProps}
      sx={{
        ...restProps.sx,
        overflowY: 'auto'
      }}
    >
      {value === index && children}
    </Box>
  )
}

export default TabContent
