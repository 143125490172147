import { ThemeOptions } from '@mui/material/styles'

export const MuiFormControlLabel: Exclude<ThemeOptions['components'], undefined>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      margin: 0,
      gap: theme.spacing(3)
    })
  }
}
