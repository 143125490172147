import { useState, MouseEvent } from 'react'
import { Stack, Button, Box, Typography, ButtonProps, MenuItem, Checkbox, Menu, Divider, Theme } from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { RequestAction } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { SHIP_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/ShipActionDialog'
import { SCAN_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/ScanActionDialog'
import { useMailroomFilters } from 'apps/MailroomAppV2/hooks/useMailroomFilters'
import { FOLDER_LABELS } from 'apps/MailroomAppV2/constants/folderLabels'
import {
  MOVE_TO_INBOX_ACTION_REQUEST,
  MOVE_TO_ARCHIVE_ACTION_REQUEST,
  MOVE_TO_TRASH_ACTION_REQUEST
} from 'apps/MailroomAppV2/components/Actions/MoveActionDialog'
import { SHARE_ITEM_VIA_INTEGRATION } from 'apps/MailroomAppV2/components/Actions/SharePdf/ShareItemDialog'
import { MARK_NOT_VIEWED_REQUEST } from 'apps/MailroomAppV2/components/Actions/MarkNotViewedActionModal'
import { DOWNLOAD_PDF_ACTION_REQUEST_PARAMS } from 'apps/MailroomAppV2/utils/downloadPdfAction'
import { MARK_VIEWED_REQUEST } from 'apps/MailroomAppV2/components/Actions/MarkViewedActionModal'
import { NOT_MY_MAIL_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/NotMyMailActionDialog'
import { SHRED_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/ShredActionDialog'
import { COPY_LINK_TO_PDF_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/SharePdf/CopyLinkToPdfDialog'
import { SHARE_PDF_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/SharePdf/SharePdfDialog'
import { CHECK_DEPOSIT_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/CheckDepositDrawer'
import { ItemsSelectionActionType, ItemsSelection } from 'apps/MailroomAppV2/hooks/useItemsSelection'
import { IntegrationProviderFragment, VirtualMailItemFolder } from 'graphql/__generated__/graphql'
import {
  Cash,
  ChevronUp,
  Download,
  MinusCheckbox,
  Scan,
  Scissors,
  Truck,
  EyeHiddenOutlined,
  DotsVertical,
  ThreeLayersOutlined,
  MailboxOutlined,
  TrashOutlined,
  PackageOutlined,
  FolderDown,
  EyeOutlined,
  XCircleLine,
  ShareOutlined,
  Share,
  Link,
  ChevronLeft
} from 'theme/icons'
import ShippingCartButton from 'lz-design/ShippingCartButton'

interface ActionsBarProps {
  mailItemsCount?: number
  availableProviders?: IntegrationProviderFragment[]
  isItemDetailsView?: boolean
  requestBulkAction: RequestAction
  itemsSelection: ItemsSelection
}

const ActionsBar = ({
  mailItemsCount,
  availableProviders,
  requestBulkAction,
  itemsSelection: { dispatchSelectionAction, selectedMailItems, currentMailItem },
  isItemDetailsView = false
}: ActionsBarProps): JSX.Element => {
  const { currentInbox } = useUserContext()
  const selectionMenuControl = useMenuControl()
  const moveToActionsMenuControl = useMenuControl()
  const moreActionsMenuControl = useMenuControl()
  const shareActionsMenuControl = useMenuControl()
  const inboxId = String(currentInbox.id)

  const areAllItemsSelected = selectedMailItems?.length === mailItemsCount

  const {
    filters: { folder }
  } = useMailroomFilters()
  const visibleMoveToActions = MOVE_TO_ACTIONS.filter(({ folder: moveToFolder }) => moveToFolder !== folder)

  return (
    <Box
      display='flex'
      justifyContent={{ xs: 'center', lg: 'space-between' }}
      position={{ xs: 'fixed', md: 'initial' }}
      left='0'
      bottom='0'
      width={{ xs: '100dvw', md: 'unset' }}
      p={{ xs: 4, md: 0 }}
      bgcolor={{ xs: 'grey.50', md: 'unset' }}
      gap={2}
      order={{ xs: 1, lg: 0 }}
      zIndex={{ xs: 2, lg: 'initial' }}
    >
      <Stack
        direction='row'
        gap={2}
        justifyContent={{ xs: 'center', lg: 'flex-start' }}
        flexWrap={{ xs: 'nowrap', md: 'wrap' }}
      >
        {isItemDetailsView && (
          <ResponsiveButton startIcon={<ChevronLeft color='primary' />} href={`/inboxes/${inboxId}/mailroom`}>
            <Typography color='primary' fontWeight={600}>
              Back to Mailroom
            </Typography>
          </ResponsiveButton>
        )}
        {!isItemDetailsView && (
          <ResponsiveButton
            {...selectionMenuControl.buttonProps}
            visibleOnMobile={!currentMailItem}
            mobileDirection='row'
          >
            <Checkbox
              checked={selectedMailItems && selectedMailItems.length > 0}
              onChange={(e, checked) =>
                dispatchSelectionAction({
                  type: checked ? ItemsSelectionActionType.SELECT_ALL : ItemsSelectionActionType.UNSELECT_ALL
                })
              }
              onClick={e => e.stopPropagation()}
              checkedIcon={!areAllItemsSelected ? <MinusCheckbox /> : undefined}
            />
          </ResponsiveButton>
        )}
        <Menu {...selectionMenuControl.menuProps}>
          <Typography fontWeight={600} px={3} pt={3} pb={1}>
            Select:
          </Typography>
          <MenuItem onClick={() => dispatchSelectionAction({ type: ItemsSelectionActionType.SELECT_ALL })}>
            All
          </MenuItem>
          <MenuItem onClick={() => dispatchSelectionAction({ type: ItemsSelectionActionType.UNSELECT_ALL })}>
            None
          </MenuItem>
          <MenuItem onClick={() => dispatchSelectionAction({ type: ItemsSelectionActionType.SELECT_VIEWED })}>
            Read
          </MenuItem>
          <MenuItem onClick={() => dispatchSelectionAction({ type: ItemsSelectionActionType.SELECT_NOT_VIEWED })}>
            Unread
          </MenuItem>
        </Menu>
        <ResponsiveButton startIcon={<Scan />} onClick={() => requestBulkAction(SCAN_ACTION_REQUEST)} visibleOnMobile>
          Scan
        </ResponsiveButton>
        <ResponsiveButton
          startIcon={<Scissors />}
          onClick={() => requestBulkAction(SHRED_ACTION_REQUEST)}
          visibleOnMobile
        >
          Shred
        </ResponsiveButton>
        <ResponsiveButton
          startIcon={<Truck />}
          onClick={() => requestBulkAction(SHIP_ACTION_REQUEST)}
          visibleOnMobile={!!currentMailItem}
        >
          Ship
        </ResponsiveButton>
        <ResponsiveButton startIcon={<Cash />} onClick={() => requestBulkAction(CHECK_DEPOSIT_ACTION_REQUEST)}>
          Deposit
        </ResponsiveButton>
        <ResponsiveButton
          startIcon={<Download />}
          onClick={() => requestBulkAction(...DOWNLOAD_PDF_ACTION_REQUEST_PARAMS)}
        >
          Download PDF
        </ResponsiveButton>
        <ResponsiveButton startIcon={<Share />} {...shareActionsMenuControl.buttonProps}>
          Share
        </ResponsiveButton>
        <Menu {...shareActionsMenuControl.menuProps}>
          <MenuItem onClick={() => requestBulkAction(SHARE_PDF_ACTION_REQUEST)}>
            <ShareOutlined />
            Share PDF
          </MenuItem>
          <MenuItem onClick={() => requestBulkAction(COPY_LINK_TO_PDF_ACTION_REQUEST)}>
            <Link />
            Copy link
          </MenuItem>

          <Typography fontWeight={600} px={3} pt={3} pb={1}>
            Store a copy in:
          </Typography>
          {availableProviders?.map(provider => {
            const { displayName, id, logoUrl } = provider
            return (
              <MenuItem
                onClick={() => {
                  requestBulkAction({
                    ...SHARE_ITEM_VIA_INTEGRATION,
                    payload: { provider }
                  })
                }}
                key={id}
              >
                <img src={logoUrl} alt={displayName} />
                {displayName}
              </MenuItem>
            )
          })}
        </Menu>
        <ResponsiveButton startIcon={<FolderDown />} {...moveToActionsMenuControl.buttonProps}>
          Move to
        </ResponsiveButton>
        <Menu {...moveToActionsMenuControl.menuProps}>
          {visibleMoveToActions.map(({ folder, icon, actionRequest }) => (
            <MenuItem key={folder} onClick={() => requestBulkAction(actionRequest)}>
              {icon}
              {FOLDER_LABELS[folder]}
            </MenuItem>
          ))}
        </Menu>
        <ResponsiveButton startIcon={<DotsVertical />} {...moreActionsMenuControl.buttonProps} visibleOnMobile>
          More
        </ResponsiveButton>
        <Menu {...moreActionsMenuControl.menuProps}>
          {visibleMoveToActions.map(({ folder, icon, actionRequest }) => (
            <MenuItem key={folder} onClick={() => requestBulkAction(actionRequest)} sx={{ display: { lg: 'none' } }}>
              {icon}
              {FOLDER_LABELS[folder]}
            </MenuItem>
          ))}
          <Divider sx={{ mx: -1, display: { lg: 'none' } }} />
          <MenuItem>
            <ThreeLayersOutlined />
            Open & Deliver
          </MenuItem>
          <MenuItem onClick={() => requestBulkAction(MARK_NOT_VIEWED_REQUEST)}>
            <EyeHiddenOutlined />
            Mark as unread
          </MenuItem>
          <MenuItem onClick={() => requestBulkAction(MARK_VIEWED_REQUEST)}>
            <EyeOutlined />
            Mark as read
          </MenuItem>
          <MenuItem onClick={() => requestBulkAction(NOT_MY_MAIL_ACTION_REQUEST)}>
            <XCircleLine />
            Not my mail
          </MenuItem>
        </Menu>
      </Stack>
      <ShippingCartButton />
    </Box>
  )
}

interface ResponsiveButtonProps extends ButtonProps {
  visibleOnMobile?: boolean
  mobileDirection?: 'column' | 'row'
}

function ResponsiveButton({ visibleOnMobile, mobileDirection = 'column', ...props }: ResponsiveButtonProps) {
  return (
    <Button
      variant='outlined'
      color='secondary'
      size='large'
      {...props}
      sx={theme => ({
        [theme.breakpoints.down('lg')]: {
          display: visibleOnMobile ? undefined : 'none',
          maxWidth: '80px',
          flexDirection: mobileDirection,
          '& > .MuiButton-startIcon': {
            fontSize: '20px',
            marginRight: 0,
            marginBottom: 1
          }
        }
      })}
    />
  )
}

function useMenuControl() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isMenuOpen = Boolean(anchorEl)

  const openMenu = (event: MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  return {
    buttonProps: {
      endIcon: <ChevronUp sx={{ rotate: isMenuOpen ? 'none' : '180deg', display: { xs: 'none', lg: 'block' } }} />,
      onClick: openMenu
    },
    menuProps: {
      anchorEl,
      open: isMenuOpen,
      onClose: closeMenu,
      onClick: closeMenu,
      MenuListProps: {
        sx: (theme: Theme) => ({
          minWidth: 236,
          '& > *': {
            gap: theme.spacing(3)
          }
        })
      }
    }
  }
}

const MOVE_TO_ACTIONS = [
  {
    folder: VirtualMailItemFolder.VirtualMailItemFolderInbox,
    icon: <MailboxOutlined />,
    actionRequest: MOVE_TO_INBOX_ACTION_REQUEST
  },
  {
    folder: VirtualMailItemFolder.VirtualMailItemFolderArchive,
    icon: <PackageOutlined />,
    actionRequest: MOVE_TO_ARCHIVE_ACTION_REQUEST
  },
  {
    folder: VirtualMailItemFolder.VirtualMailItemFolderTrash,
    icon: <TrashOutlined />,
    actionRequest: MOVE_TO_TRASH_ACTION_REQUEST
  }
]

export default ActionsBar
