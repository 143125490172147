import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import noResources from 'assets/noResources.svg'
import noResourcesMobile from 'assets/noResourcesMobile.svg'

export default function NotFound() {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Stack
      direction={{ md: 'row' }}
      justifySelf='center'
      alignSelf={{ md: 'center' }}
      maxWidth={{ md: '80%' }}
      px={{ xs: 5, md: 0 }}
      pt={{ xs: 16, md: 0 }}
      gap={{ xs: 16, md: 4 }}
    >
      <Stack gap={6} width={{ md: '50%' }} order={{ xs: 1, md: 0 }}>
        <Typography variant='h5' fontWeight={600}>
          Your curiosity is inspiring
        </Typography>
        <Typography>
          Unfortunately, the page you’re looking for isn’t here. Sorry about that. Please try refreshing the page or
          contact us if the problem persists.
        </Typography>
      </Stack>
      <Box order={{ xs: 0, md: 1 }}>
        <Box component='img' src={isMdUp ? noResources : noResourcesMobile} width='100%' height='100%' />
      </Box>
    </Stack>
  )
}
