import { ReactNode } from 'react'
import pluralize from 'pluralize'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Link
} from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { DialogTypes } from './OverviewTab'
import { MailItemFragment, VirtualMailItemPhysicalStatus } from 'graphql/__generated__/graphql'

interface StorageDetailsDialogProps {
  mailItem: MailItemFragment
  openedDialogType: DialogTypes
  onDialogClose: () => void
}

const StorageDetailsDialog = ({ mailItem, openedDialogType, onDialogClose }: StorageDetailsDialogProps) => {
  const { currentInbox } = useUserContext()
  const storagePeriodEnds = 12 // TODO: fill with real data
  const outstandingStorageFee = '1.50' // TODO: fill with real data
  const isItemParcel = ['Parcel', 'OversizeParcel'].includes(mailItem.mailType)
  const storageDialog: Record<string, { title: string; content: ReactNode[] }> = {
    [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage]: {
      title: 'Storage status',
      content: [
        <Box display='flex' gap={1}>
          The free item storage period is about to end:{' '}
          <Typography color='textPrimary' fontWeight={600}>
            {pluralize('day', storagePeriodEnds, true)}.
          </Typography>
        </Box>,
        'Shred or ship physical mail items to avoid storage fees. You can still keep your digitized mail in our unlimited free cloud storage.',
        <Box display='inline' gap={1}>
          Do you want to receive a notification when a free storage period for your mail is about to end? Go to{' '}
          <Link href={`/settings/${currentInbox.id}/notification-preferences`}>Notifications.</Link>
        </Box>
      ]
    },
    [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee]: {
      title: `The current fee for storing a ${isItemParcel ? 'parcel is $15.00' : 'letter is $1.50'}`,
      content: [
        isItemParcel
          ? 'Free physical parcel (package) storage for 10 days per mail item, then $15.00 per pound per month.'
          : 'Free physical letter storage for 30 days per mail item, then $1.50 per month.',
        'Shred or ship physical mail items to avoid storage fees. You can still keep your digitized mail in our unlimited free cloud storage.'
      ]
    },
    outstandingStorageFee: {
      title: `Outstanding storage fee is $${outstandingStorageFee}`,
      content: [`A fee of $${outstandingStorageFee} will be added to the current invoice amount.`]
    }
  }

  const storageDialogKey =
    openedDialogType === DialogTypes.OutstandingStorageFee ? 'outstandingStorageFee' : mailItem.physicalStatus
  const { title, content } = storageDialog[storageDialogKey]

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content?.map((text, index) => (
          <DialogContentText pt={index !== 0 ? 5 : 0} key={index}>
            {text}
          </DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose} variant='contained' size='large'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StorageDetailsDialog
