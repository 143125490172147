import { useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { NetworkStatus } from '@apollo/client'
import useUserContext from 'hooks/useUserContext'
import MailItemsList from 'apps/MailroomAppV2/components/MailItemsList/MailItemsList'
import ActionsBar from 'apps/MailroomAppV2/components/ActionsBar'
import MailItemDetails from 'apps/MailroomAppV2/components/MailItemDetails/MailItemDetails'
import { useMailroomFilters } from 'apps/MailroomAppV2/hooks/useMailroomFilters'
import { useItemsAction } from 'apps/MailroomAppV2/hooks/useItemsAction'
import MailItem from 'apps/MailroomAppV2/components/MailItemsList/MailItem'
import UnauthorizedBanner from 'apps/MailroomAppV2/components/UnauthorizedBanner'
import useItemsSelection, { ItemsSelectionActionType } from 'apps/MailroomAppV2/hooks/useItemsSelection'
import Actions from 'apps/MailroomAppV2/components/Actions'
import SelectedItemsEmptyState from 'apps/MailroomAppV2/components/MailItemDetails/SelectedItemsEmptyState'
import ItemDetailsEmptyState from 'apps/MailroomAppV2/components/MailItemDetails/ItemDetailsEmptyState'
import { IntegrationJobType } from 'graphql/__generated__/graphql'
import { gql, useQuery } from 'graphql/client'
import { ChevronLeft } from 'theme/icons'

const Inboxes = () => {
  const { currentInbox } = useUserContext()
  const { filters } = useMailroomFilters()
  const inboxId = String(currentInbox.id)
  const {
    data: rawData,
    error,
    fetchMore,
    loading,
    networkStatus
  } = useQuery(GET_MAIL_ITEMS, {
    variables: { inboxId, first: 10, filters },
    notifyOnNetworkStatusChange: true
  })
  const { data: integrationProvidersData } = useQuery(GET_INTEGRATION_PROVIDERS, { variables: { inboxId } })

  const availableProviders = integrationProvidersData?.integrationProviders?.filter(({ supportedJobType }) =>
    [IntegrationJobType.IntegrationJobTypeUploadPiece, IntegrationJobType.IntegrationJobTypeUploadBill].includes(
      supportedJobType
    )
  )
  const data = networkStatus === NetworkStatus.loading ? undefined : rawData
  const mailItems = data?.mailItems.edges.map(edge => edge.node) ?? []
  const itemsSelection = useItemsSelection(mailItems)
  const { currentMailItem, dispatchSelectionAction, selectedMailItems } = itemsSelection
  const inbox = useQuery(GET_INBOX, { variables: { inboxId: currentInbox.id } }).data?.getInbox
  const unverified = inbox?.account.isVerified === false

  useEffect(
    () => dispatchSelectionAction({ type: ItemsSelectionActionType.UNSELECT_ALL }),
    [filters, dispatchSelectionAction]
  )
  const { requestItemAction, requestBulkAction, requestedAction } = useItemsAction({
    itemsSelection,
    actionsUnavailabilityReason: unverified
      ? {
          severity: 'warning',
          message: 'Action unavailable. You must be authorized to use the service.'
        }
      : undefined
  })

  if (error) {
    return (
      <Box justifySelf='center' alignSelf='center'>
        <Typography variant='subtitle3' color='grey.600' fontWeight={600}>
          An error occurred while fetching mail items
        </Typography>
      </Box>
    )
  }

  const onFetchMore = data?.mailItems.pageInfo.hasNextPage
    ? () =>
        void fetchMore({
          variables: {
            after: data.mailItems.pageInfo.endCursor
          }
        })
    : undefined
  const selectedItemsCount = selectedMailItems.length
  const clearSelection = () => dispatchSelectionAction({ type: ItemsSelectionActionType.UNSELECT_ALL })

  return (
    <Stack gap={4.5} pb={{ xs: 16, md: 0 }} overflow='hidden'>
      <Actions requestedAction={requestedAction} />
      {unverified && <UnauthorizedBanner address={inbox.account.addresses[0]} pmb={inbox.account.ecmNumber} />}
      <ActionsBar
        itemsSelection={itemsSelection}
        mailItemsCount={mailItems?.length ?? 0}
        requestBulkAction={requestBulkAction}
        availableProviders={availableProviders}
      />
      <Box display='grid' gridTemplateColumns={{ xs: '1fr', lg: '1fr 2fr' }} gap={6} flexGrow={1} overflow='hidden'>
        <MailItemsList loading={loading} hideWhenNoSpace={!!currentMailItem} onFetchMore={onFetchMore}>
          {mailItems?.map(item => <MailItem itemsSelection={itemsSelection} item={item} key={item.id} />)}
        </MailItemsList>
        {currentMailItem ? (
          <MailItemDetails mailItem={currentMailItem} requestItemAction={requestItemAction}>
            <Button
              variant='text'
              startIcon={<ChevronLeft />}
              onClick={clearSelection}
              sx={{ display: { lg: 'none' }, width: 'fit-content' }}
            >
              Mailroom
            </Button>
          </MailItemDetails>
        ) : selectedItemsCount ? (
          <SelectedItemsEmptyState selectedItemsCount={selectedItemsCount} onClearSelectedItems={clearSelection} />
        ) : (
          <ItemDetailsEmptyState />
        )}
      </Box>
    </Stack>
  )
}

export const GET_MAIL_ITEMS = gql(`
  query getMailItems($inboxId: ID!, $first: Int, $after: ID, $filters: MailItemFilters) {
    mailItems(inboxId: $inboxId, first: $first, after: $after, filters: $filters) {
      edges {
        node {
          ...MailItem
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export const GET_INTEGRATION_PROVIDERS = gql(`
  query integrationProviders($inboxId: ID!) {
    integrationProviders(inboxId: $inboxId) {
     ...IntegrationProvider
    }
  }
`)

gql(`
  fragment IntegrationProvider on IntegrationProvider {
      id
      providerName
      displayName
      authType
      authorizationUrl
      logoUrl
      supportedJobType
      connections {
        id
        connectionName
        userEmail
        createdAt
      }
  }
`)

gql(`
  fragment MailItem on VirtualMailItem {
    id
    barcode
    isViewed
    sender
    receivedAt
    recipient {
      name
    }
    physicalStatus
    digitalStatus
    checkStatus
    enclosure {
      smallUrl
      largeUrl
    }
    scan {
      pdfUrl
    }
    senderCarrier
    folder
    physicalDimensions {
      weightInOunces
      pageCountEstimate
      pageCountActual
    }
    mailType
    mailSubtype
    facility
    inShippingCart
    flags
  }
`)

const GET_INBOX = gql(`
  query getInbox($inboxId: Int!) {
    getInbox(inboxId: $inboxId) {
      ...Inbox
    }
  }
`)

gql(`
  fragment Inbox on Inbox {
    id,
    account {
      id
      isVerified
      ecmNumber
      addresses {
        ...VirtualAddress
      }
    }
  }
`)

gql(`
  fragment VirtualAddress on Address {
    line1
    city
    state
    postalCode
  }
`)

export default Inboxes
