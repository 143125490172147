import { ReactNode } from 'react'
import { Dialog, DialogTitle } from '@mui/material'
import { ItemsActionRequest, RequestedActionProps } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import ShareMultiplePdfDialog from './ShareMultiplePdfDialog'
import ShareSinglePdfDialog from './ShareSinglePdfDialog'
import { VirtualMailItemDigitalStatus } from 'graphql/__generated__/graphql'
import LoadingModal from 'lz-design/LoadingModal'

interface SharePdfDialogWrapperProps {
  onClose: () => void
  loading: boolean
  children: ReactNode
  onSubmit: () => void
}

export function SharePdfDialogWrapper({ loading, children, onClose, onSubmit }: SharePdfDialogWrapperProps) {
  if (loading) return <LoadingModal />

  return (
    <Dialog open onClose={onClose} component='form' onSubmit={onSubmit}>
      <DialogTitle>Share PDF</DialogTitle>
      {children}
    </Dialog>
  )
}

export default function SharePdfDialog({ ...requestedAction }: RequestedActionProps) {
  return requestedAction?.itemsSuitableForAction.length === 1 ? (
    <ShareSinglePdfDialog {...requestedAction} />
  ) : (
    <ShareMultiplePdfDialog {...requestedAction} />
  )
}

export const SHARE_PDF_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.SHARE_PDF,
  adjective: 'shared',
  isSuitable: item => item.digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanCompleted
}
