import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Checkbox(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.5' y='0.5' width='23' height='23' rx='7.5' stroke='currentColor' />
      </svg>
    </SvgIcon>
  )
}
