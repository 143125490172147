import React from 'react'
import { NavLink } from 'react-router-dom'
import { Badge, ListItemButton } from '@mui/material'

interface ListNavButtonProps {
  icon: React.ElementType
  activeIcon?: React.ElementType
  route: string
  children?: React.ReactNode
  end?: boolean
  onClick?: () => void
  badged?: boolean
}

export default function ListNavButton({
  icon,
  activeIcon,
  route,
  children,
  onClick,
  badged = false,
  end = false
}: ListNavButtonProps): React.JSX.Element {
  return (
    <NavLink to={route} style={{ textDecoration: 'none', color: 'inherit' }} end={end}>
      {({ isActive }): React.JSX.Element => {
        const ItemIcon = isActive && activeIcon ? activeIcon : icon
        return (
          <ListItemButton
            onClick={onClick}
            sx={{ fontWeight: isActive ? 600 : undefined, backgroundColor: isActive ? 'grey.50' : undefined }}
          >
            <Badge color='error' variant={badged ? 'dot' : 'standard'}>
              <ItemIcon sx={{ fontSize: 22 }} color={isActive ? 'primary' : 'inherit'} />
            </Badge>
            {children}
          </ListItemButton>
        )
      }}
    </NavLink>
  )
}
