import { ThemeOptions } from '@mui/material/styles'
import { ChevronDown } from 'theme/icons'

export const MuiSelect: Exclude<ThemeOptions['components'], undefined>['MuiSelect'] = {
  defaultProps: {
    IconComponent: ChevronDown
  },
  styleOverrides: {
    root: {
      backgroundColor: 'white',
      variants: [
        {
          props: ({ size }) => size === 'small',
          style: {
            '& svg': {
              fontSize: '16px'
            }
          }
        }
      ]
    },
    select: ({ theme: { spacing } }) => ({
      padding: spacing(3, 5)
    })
  }
}
