import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function User(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
        <path
          d='M16.6663 17.5C16.6663 16.337 16.6663 15.7555 16.5228 15.2824C16.1996 14.217 15.366 13.3834 14.3006 13.0602C13.8275 12.9167 13.246 12.9167 12.083 12.9167H7.91634C6.75337 12.9167 6.17189 12.9167 5.69872 13.0602C4.63339 13.3834 3.79971 14.217 3.47654 15.2824C3.33301 15.7555 3.33301 16.337 3.33301 17.5M13.7497 6.25C13.7497 8.32107 12.0707 10 9.99967 10C7.92861 10 6.24967 8.32107 6.24967 6.25C6.24967 4.17893 7.92861 2.5 9.99967 2.5C12.0707 2.5 13.7497 4.17893 13.7497 6.25Z'
          stroke='currentColor'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  )
}
