import { Outlet } from 'react-router'
import DesignProvider from './DesignProvider'

export default function Layout() {
  return (
    <DesignProvider>
      <Outlet />
    </DesignProvider>
  )
}
