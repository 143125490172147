import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { isEqual, sortBy } from 'lodash'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { PaginatedList, RequestError, RequestStatus } from 'interfaces/common.interface'
import { Shipment, ShipmentStatus, ShipmentsSort } from 'interfaces/shipment.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { Piece } from 'interfaces/piece.interface'

const PER_PAGE = 10
const SORT_BY = 'ship_after'
const SORT_DIRECTION = 'desc'

export interface ShipmentsList extends PaginatedList<Shipment> {}

interface ShipmentsState {
  initialLoading: boolean
  shipmentsPaginatedList: ShipmentsList
  selectedShipment: Shipment | null
  itemsList: Piece[]
  shipmentsPaginatedListStatus: RequestStatus
  itemsListStatus: RequestStatus
  shipmentsPaginatedListError: RequestError | undefined
  itemsListError: RequestError | undefined
  shipmentsStatus: ShipmentStatus
  shipmentsSort: ShipmentsSort
}

interface FetchShipmentsArgs {
  inboxId: InboxId
  page?: number
  operationStatus?: ShipmentStatus
  sortBy?: string
  sortDirection?: string
}

const initialState: ShipmentsState = {
  initialLoading: true,
  shipmentsPaginatedList: {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 0,
    per_page: PER_PAGE,
    to: 0,
    total: 0
  },
  selectedShipment: null,
  itemsList: [],
  shipmentsPaginatedListStatus: RequestStatus.Pending,
  itemsListStatus: RequestStatus.Pending,
  shipmentsPaginatedListError: undefined,
  itemsListError: undefined,
  shipmentsStatus: ShipmentStatus.All,
  shipmentsSort: { sortBy: 'ship_after', sortDirection: 'desc' }
}

export const fetchShipmentsList = createAsyncThunk(
  'shipments/fetchShipmentsList',
  async ({ inboxId, page }: FetchShipmentsArgs, { getState }) => {
    const state = getState() as RootState
    const currentPage = state.shipmentsApp.shipments.shipmentsPaginatedList.current_page
    const sortBy = state.shipmentsApp.shipments.shipmentsSort.sortBy ?? SORT_BY
    const sortDirection = state.shipmentsApp.shipments.shipmentsSort.sortDirection ?? SORT_DIRECTION
    const operationStatus = state.shipmentsApp.shipments.shipmentsStatus ?? ShipmentStatus.All
    const response: { data: ShipmentsList } = await apiClient.get(`/inboxes/${inboxId}/shipments`, {
      params: {
        per_page: PER_PAGE,
        page: page ?? currentPage,
        operation_status: operationStatus,
        sort_by: sortBy,
        sort_direction: sortDirection
      }
    })
    return response.data
  }
)

export const fetchItems = createAsyncThunk('shipments/fetchItems', async (_, { getState }) => {
  const state = getState() as RootState
  const itemsList: Piece[] = []
  const promises: Array<Promise<{ data: Piece }>> = []
  const selectedItemsIds = state.shipmentsApp.shipments.selectedShipment?.items.map(({ piece_id }) => piece_id) ?? [] // eslint-disable-line @typescript-eslint/naming-convention
  selectedItemsIds.forEach(id => {
    promises.push(apiClient.get(`/pieces/${id}`))
  })
  await Promise.all(promises).then(responses => {
    responses.forEach(response => itemsList.push(response.data))
  })
  return itemsList
})

export const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setSelectedShipment(state, action: PayloadAction<Shipment>) {
      state.selectedShipment = action.payload
    },
    clearSelectedShipment(state) {
      state.selectedShipment = null
    },
    resetShipmentsStore: () => initialState,
    setShipmentsStatus(state, action: PayloadAction<ShipmentStatus>) {
      state.shipmentsStatus = action.payload
    },
    setShipmentsSort: (state, action: PayloadAction<ShipmentsSort>) => {
      state.shipmentsSort = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShipmentsList.pending, state => {
        state.shipmentsPaginatedListStatus = RequestStatus.Pending
      })
      .addCase(fetchShipmentsList.fulfilled, (state, action) => {
        state.shipmentsPaginatedListStatus = RequestStatus.Success
        state.shipmentsPaginatedList = action.payload
        state.shipmentsPaginatedListError = undefined
        state.initialLoading = false
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        const itemsIds = sortBy(action.payload.map(({ id }) => id))
        const sortedSelectedItemsIds = sortBy(state.selectedShipment?.items.map(({ piece_id }) => piece_id)) // eslint-disable-line @typescript-eslint/naming-convention
        if (isEqual(itemsIds, sortedSelectedItemsIds)) {
          state.itemsListStatus = RequestStatus.Success
          state.itemsList = action.payload
        }
      })
      .addCase(fetchItems.pending, state => {
        state.itemsListStatus = RequestStatus.Pending
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.itemsListStatus = RequestStatus.Error
        state.itemsList = initialState.itemsList
        state.itemsListError = action.error.message
      })
      .addCase(fetchShipmentsList.rejected, (state, action) => {
        state.shipmentsPaginatedListStatus = RequestStatus.Error
        state.shipmentsPaginatedList = initialState.shipmentsPaginatedList
        state.shipmentsPaginatedListError = action.error.message
      })
  }
})

export const { setSelectedShipment, clearSelectedShipment, resetShipmentsStore, setShipmentsStatus, setShipmentsSort } =
  shipmentsSlice.actions

export const getInitialLoading = (state: RootState): boolean => state.shipmentsApp.shipments.initialLoading
export const getShipmentsList = (state: RootState): Shipment[] =>
  state.shipmentsApp.shipments.shipmentsPaginatedList.data
export const getShipmentsTotal = (state: RootState): number => state.shipmentsApp.shipments.shipmentsPaginatedList.total
export const getShipmentsLastPage = (state: RootState): number =>
  state.shipmentsApp.shipments.shipmentsPaginatedList.last_page
export const getShipmentsCurrentPage = (state: RootState): number =>
  state.shipmentsApp.shipments.shipmentsPaginatedList.current_page
export const getSelectedShipment = (state: RootState): Shipment | null => state.shipmentsApp.shipments.selectedShipment
export const getItemsList = (state: RootState): Piece[] => state.shipmentsApp.shipments.itemsList
export const getShipmentsStatus = (state: RootState): RequestStatus =>
  state.shipmentsApp.shipments.shipmentsPaginatedListStatus
export const getItemsListStatus = (state: RootState): RequestStatus => state.shipmentsApp.shipments.itemsListStatus
export const getHasOneShipmentOnLastPage = (state: RootState): boolean =>
  state.shipmentsApp.shipments.shipmentsPaginatedList.from === state.shipmentsApp.shipments.shipmentsPaginatedList.to
export const getShipmentsFilterStatus = (state: RootState): ShipmentStatus =>
  state.shipmentsApp.shipments.shipmentsStatus
export const getShipmentsFilterSort = (state: RootState): ShipmentsSort => state.shipmentsApp.shipments.shipmentsSort

export default shipmentsSlice.reducer
