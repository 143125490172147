interface FileToDownload {
  url: string
  filename: string
}

export async function downloadFiles(files: FileToDownload[]) {
  for (const { url, filename } of files) {
    const response = await fetch(url)
    const blob = await response.blob()
    const anchor = document.createElement('a')
    anchor.href = URL.createObjectURL(blob)
    anchor.download = filename
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    URL.revokeObjectURL(anchor.href)
  }
}
