import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function LogOutOutlined(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
        <path
          d='M14.6667 15.5833L19.25 11M19.25 11L14.6667 6.41667M19.25 11H8.25M11 15.5833C11 16.4358 11 16.862 10.9063 17.2118C10.652 18.1608 9.91076 18.902 8.96175 19.1563C8.61205 19.25 8.18581 19.25 7.33333 19.25H6.875C5.59366 19.25 4.95299 19.25 4.44762 19.0407C3.77379 18.7616 3.23844 18.2262 2.95933 17.5524C2.75 17.047 2.75 16.4063 2.75 15.125V6.875C2.75 5.59366 2.75 4.95299 2.95933 4.44762C3.23844 3.77379 3.77379 3.23844 4.44762 2.95933C4.95299 2.75 5.59366 2.75 6.875 2.75H7.33333C8.18581 2.75 8.61205 2.75 8.96175 2.8437C9.91076 3.09799 10.652 3.83924 10.9063 4.78825C11 5.13796 11 5.56419 11 6.41667'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  )
}
