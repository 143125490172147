import { ThemeOptions } from '@mui/material/styles'

export const MuiList: Exclude<ThemeOptions['components'], undefined>['MuiList'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1, 0.5),
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: theme.spacing(1)
    })
  }
}

export const MuiListItemButton: Exclude<ThemeOptions['components'], undefined>['MuiListItemButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      gap: theme.spacing(3),
      '&, &.Mui-selected': {
        '&:hover': {
          backgroundColor: theme.palette.grey[100]
        },
        '& > .MuiTouchRipple-root > *': {
          color: theme.palette.grey[500]
        }
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[50],
        fontWeight: 600,
        '& > .MuiSvgIcon-root': {
          color: theme.palette.primary.main
        }
      }
    })
  }
}

export const MuiListSubheader: Exclude<ThemeOptions['components'], undefined>['MuiListSubheader'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
      color: theme.palette.text.primary,
      padding: theme.spacing(2, 3)
    })
  }
}
