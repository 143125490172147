import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import pluralize from 'pluralize'
import { useForm } from 'react-hook-form'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import { HookedTextField } from 'lz-design/forms/inputs'
import { gql, useMutation } from 'graphql/client'
import {
  VirtualMailItemDigitalStatus,
  VirtualMailItemPhysicalStatus,
  VirtualMailItemCheckStatus
} from 'graphql/__generated__/graphql'

export default function NotMyMailActionDialog({
  itemsSuitableForAction,
  suitabilityAlert,
  onCancel,
  onError,
  onSuccess
}: RequestedActionProps) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<NotMyMailFormValues>({
    defaultValues: {
      comment: ''
    }
  })
  const { currentInbox } = useUserContext()
  const [markAsNotMyMails] = useMutation(MARK_NOT_MY_MAILS, {
    onCompleted: () => onSuccess(`${pluralize('item', itemsSuitableForAction.length, true)} marked as "not my mail".`),
    onError: () => onError('Mark as "not my mail" request failed. Please try again later.'),
    update: cache => {
      itemsSuitableForAction.forEach(item => {
        const id = cache.identify({ __typename: 'VirtualMailItem', id: item.id })
        cache.evict({ id })
      })
      cache.evict({ fieldName: 'getCurrentShippingCart' })
      cache.gc()
    }
  })

  const thisItemPluralized = `${pluralize('this', itemsSuitableForAction.length)} ${pluralize(
    'item',
    itemsSuitableForAction.length
  )}`

  const onSubmit = async ({ comment }: NotMyMailFormValues) =>
    markAsNotMyMails({
      variables: {
        input: {
          inboxId: String(currentInbox.id),
          mailItemIds: itemsSuitableForAction.map(item => item.id),
          comment
        }
      }
    })

  return (
    <Dialog open component='form' onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Not my mail</DialogTitle>
      <DialogContent>
        {suitabilityAlert && <SuitabilityAlert options={suitabilityAlert} />}
        <DialogContentText gap={6} mb={4}>
          By proceeding, you confirm {thisItemPluralized} {pluralize('was', itemsSuitableForAction.length)} delivered to
          the wrong account. You will permanently lose access to {thisItemPluralized}.<br />
          <br />
          <Box component='span' fontWeight={600}>
            You will not be able to undo this action.
          </Box>
        </DialogContentText>
        <HookedTextField name='comment' label='Include a comment (optional)' control={control} multiline fullWidth />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} size='large'>
          Cancel
        </Button>
        <LoadingButton type='submit' loading={isSubmitting} variant='contained' color='error' size='large'>
          Proceed
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export const NOT_MY_MAIL_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.NOT_MY_MAIL,
  adjective: 'marked as "not my mail"',
  isSuitable: ({ digitalStatus, physicalStatus, checkStatus }) =>
    digitalStatus !== VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanInProgress &&
    ![
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipInProgress,
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipCompleted,
      VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredCompleted
    ].includes(physicalStatus) &&
    ![
      VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositRequested,
      VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositCompleted
    ].includes(checkStatus),
  suitabilityExplanation:
    'Items that are in the process of scanning, shipping, or depositing, or have already been shipped, shredded, or deposited, cannot be marked as "not my mail".'
}

interface NotMyMailFormValues {
  comment: string
}

const MARK_NOT_MY_MAILS = gql(`
  mutation markNotMyMails($input: MarkNotMyMailsInput!) {
    markNotMyMails(input: $input) {
      ...on VirtualMailItem {
        id
      }
    }
  }
`)
