import React, { Children, UIEvent } from 'react'
import { CircularProgress, Box, Stack } from '@mui/material'
import EmptyState from 'lz-design/EmptyState'
import { Search } from 'theme/icons'

interface MailItemsListProps extends MailItemsListContentProps {
  hideWhenNoSpace: boolean
}

const MailItemsList = ({ hideWhenNoSpace, ...rest }: MailItemsListProps): JSX.Element => (
  <Box
    mt={{ lg: 14 }}
    overflow='hidden'
    display={{ xs: hideWhenNoSpace ? 'none' : 'flex', lg: 'flex' }}
    flexDirection='column'
    alignItems='stretch'
  >
    <MailItemsListContent {...rest} />
  </Box>
)

type MailItemsListContentProps = {
  loading: boolean
  children: React.ReactNode
  onFetchMore?: () => void
}

function MailItemsListContent({ loading, children, onFetchMore }: MailItemsListContentProps) {
  const itemsCount = Children.toArray(children).length

  if (!loading && !itemsCount) {
    return (
      <EmptyState
        height='100%'
        title='No items matched your search'
        subtitle='Try using search such as sender, recipient or ID item.'
        icon={Search}
      />
    )
  }

  const onScroll = ({ currentTarget }: UIEvent<HTMLDivElement>) => {
    if (onFetchMore && !loading) {
      const { scrollTop, scrollHeight } = currentTarget
      const elementHeight = currentTarget.getBoundingClientRect().height

      if (scrollTop > scrollHeight - 1.5 * elementHeight) {
        onFetchMore()
      }
    }
  }

  return (
    <Stack
      borderRadius={1}
      gap='1px'
      onScroll={onScroll}
      sx={{
        overflowY: 'auto',
        minHeight: '100%'
      }}
    >
      {children}
      {loading && (
        <Box p={8} display='flex' justifyContent='center' margin='auto'>
          <CircularProgress color='primary' />
        </Box>
      )}
    </Stack>
  )
}

export default MailItemsList
