import { EnvConfig } from './envconfig'

export const config: EnvConfig = {
  myLzUrl: 'https://www.legalzoom.com/my',
  amplitudeApiKey: '23a9cf43c397e1cafda6cd49a75da7e6',
  apiBaseURL: 'https://api.earthclassmail.com',
  graphqlBaseURL: 'https://graphql.earthclassmail.com/graphql',
  appCookieDomain: 'earthclassmail.com',
  appUrl: 'https://app.earthclassmail.com',
  awsCognitoRegion: 'us-west-2',
  awsOAuthDomain: 'auth.earthclassmail.com',
  awsOAuthSignInRedirect: 'https://app.earthclassmail.com/login',
  awsOAuthSignOutRedirect: 'https://app.earthclassmail.com/logout',
  awsProjectRegion: 'us-west-2',
  awsSecureCookie: true,
  awsUserPoolsId: 'us-west-2_Gw0APOkmX',
  awsUserPoolsMarketingClientId: '3j1e33t135trblj1k1ek0nv1io',
  awsUserPoolsWebClientId: 'fvhdeht5cuo1jue6qq0u78meg',
  connectorServiceBaseUrl: 'https://connect.earthclassmail.com',
  connectorServiceBillcomEndpoint: 'billcom',
  googleMapsApiKey: 'AIzaSyCA3rYW9A91HDYOp9kJzj0QtiNTvGNDfNo',
  googleTagManagerId: 'GTM-P35M6V2',
  hotjarId: '1757755',
  hotjarSnippetVersion: '6',
  launchDarklyClientSideId: '65b8c714aef4ed0fbd907513',
  marketingRedirectUrl: 'https://www.earthclassmail.com/checkout/billing',
  pusherAppKey: '5a62d9255b0f5f58b423',
  pusherCluster: 'mt1',
  sharePortalUrl: 'https://share.earthclassmail.com/',
  stripePublicKey: 'pk_live_UY8RYuUac3UoW7seQ85hMMYj',
  datadogAppId: '3ce82eed-4ac8-4a20-9f45-c5cb7e8c7629',
  datadogClientToken: 'pub9fb5929d4d37cfb6bcbc5b9e0821eb7e',
  env: 'production'
}
