import { createContext, useContext, useState, ReactNode, SyntheticEvent } from 'react'
import { Snackbar, Alert, AlertProps, SnackbarCloseReason } from '@mui/material'

export type AlertOptions = {
  message: string
  severity?: AlertProps['severity']
}

type AlertContextType = {
  setAlert: (options: AlertOptions) => void
}

const AlertContext = createContext<AlertContextType | undefined>(undefined)

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState<AlertOptions | null>(null)

  const handleClose = (_event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason !== 'clickaway') {
      setAlert(null)
    }
  }

  return (
    <AlertContext.Provider value={{ setAlert }}>
      {alert && (
        <Snackbar
          open={!!alert}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Alert
            severity={alert.severity || 'info'}
            onClose={() => setAlert(null)}
            sx={theme => ({
              width: 'calc(100vw - 32px)',
              [theme.breakpoints.up('md')]: {
                width: 'auto',
                minWidth: '480px'
              }
            })}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
      {children}
    </AlertContext.Provider>
  )
}

export const useAlert = () => {
  const context = useContext(AlertContext)
  if (!context) {
    throw new Error('Missing <AlertContext.Provider />')
  }
  return context
}
