import { omit } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { RootState } from 'store/store'
import { appCreateAsyncThunk } from 'store/app_create_async_thunk'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { Applicant, ApplicantResponseBody, ApplicantStatus } from 'interfaces/applicant.interface'
import { AuthorizationDocument } from 'interfaces/authorization_document.interface'
import {
  ApplicantVerification,
  ApplicantVerificationType,
  ApplicantVerificationTypeConsent,
  AutomatedVerificationCheck,
  CheckFlowType,
  CheckType,
  VerificationMediaDocumentType
} from 'shared_components/ApplicantVerification/interfaces/automated_verification.interface'
import { verificationSource } from 'shared_components/ApplicantVerification/utils/verification_source_helper'
import { IdTypes } from 'shared_components/ApplicantVerification/hooks/useAutomatedVerificationContext'

export enum VerificationLocation {
  GetStarted = 'Get Started',
  Settings = 'Settings - Recipients1583',
  ApplicantUpdate = 'Settings - Applicant Update'
}

interface ApplicantVerificationContext {
  inboxId: number
  accountId: number
  recipientId?: string
  location: VerificationLocation
}

export interface ApplicantVerificationState {
  context: ApplicantVerificationContext
  initialized: boolean
  applicant: Applicant
  applicantStatus: RequestStatus
  applicantError: RequestError | undefined
  applicantVerification: ApplicantVerification | null
  applicantVerificationStatus: RequestStatus
  applicantVerificationError: RequestError | undefined
  availableVerificationTypes: AvailableVerificationTypesResponse[]
  availableVerificationTypesStatus: RequestStatus
  availableVerificationTypesError?: RequestError
}

const initialState: ApplicantVerificationState = {
  context: {
    inboxId: 0,
    accountId: 0,
    location: VerificationLocation.GetStarted,
    recipientId: ''
  },
  initialized: false,
  applicant: {
    first_name: '',
    last_name: '',
    has_ssn: true,
    primary_id_document: {
      type: '',
      id_number: '',
      issuing_country: '',
      issuing_state: '',
      expiration_date: null
    },
    secondary_id_document: { type: '' },
    home_address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: 'US'
    },
    phone_number: '',
    status: ApplicantStatus.Unverified,
    flow_version: 'v2'
  },
  applicantStatus: RequestStatus.Pending,
  applicantError: undefined,
  applicantVerification: null,
  applicantVerificationStatus: RequestStatus.Pending,
  applicantVerificationError: undefined,
  availableVerificationTypes: [],
  availableVerificationTypesStatus: RequestStatus.Pending,
  availableVerificationTypesError: undefined
}

export interface ApplicantVerificationApiUrlsForLocation {
  applicant: string
  verificationTypes: string
  verification: string
  check: (checkId: string) => string
}

export type ApplicantVerificationUrlsForLocationCreator = (
  context: ApplicantVerificationContext,
  isApplicantVerified: boolean
) => ApplicantVerificationApiUrlsForLocation

function verifyRecipientFlowApiUrls({
  accountId,
  recipientId = ''
}: ApplicantVerificationContext): ApplicantVerificationApiUrlsForLocation {
  const root = `mail-authorization/accounts/${accountId}`
  const recipientUrl = `${root}/recipients/${recipientId}`
  const verification = `${recipientUrl}/verification`

  return {
    applicant: 'mail-authorization/applicant',
    verificationTypes: `${root}/applicant/verification_types_details`,
    verification,
    check: (checkId: string): string => `${verification}/checks/${checkId}`
  }
}

const URLS_CREATORS_BY_LOCATION: Record<VerificationLocation, ApplicantVerificationUrlsForLocationCreator> = {
  [VerificationLocation.GetStarted]: context => verifyRecipientFlowApiUrls(context),
  [VerificationLocation.Settings]: context => verifyRecipientFlowApiUrls(context),
  [VerificationLocation.ApplicantUpdate]: ({ accountId }) => {
    const applicant = `mail-authorization/accounts/${accountId}/applicant/draft`
    const verification = `${applicant}/verification`

    return {
      applicant,
      verificationTypes: `${applicant}/verification_types_details`,
      verification,
      check: (checkId: string): string => `${verification}/checks/${checkId}`
    }
  }
}

interface CreateVerificationParams {
  type: ApplicantVerificationType
  allowUseBiometricData?: boolean
}

interface AvailableVerificationTypesResponse {
  type: ApplicantVerificationType
  consent?: { type: ApplicantVerificationTypeConsent }
}

export const initializeApplicantVerification = appCreateAsyncThunk(
  'applicantVerification/initApplicantVerification',
  async (context: ApplicantVerificationContext, { dispatch }) =>
    Promise.all([
      dispatch(fetchApplicant()).unwrap(),
      dispatch(fetchAvailableVerificationTypes()).unwrap(),
      dispatch(fetchApplicantVerification()).unwrap()
    ])
)

export const initializeSelfieOnlyApplicantVerification = appCreateAsyncThunk(
  'applicantVerification/initSelfieOnlyApplicantVerification',
  async (context: ApplicantVerificationContext, { dispatch }) =>
    Promise.all([
      dispatch(fetchApplicant()).unwrap(),
      dispatch(
        createApplicantVerification({
          type: ApplicantVerificationType.AutomaticSimplified,
          allowUseBiometricData: true
        })
      ).unwrap()
    ])
)

export const fetchApplicant = appCreateAsyncThunk('applicantVerification/fetchApplicant', async (arg, { getState }) => {
  const api = getApplicantVerificationApiUrls(getState())
  try {
    const response: { data: ApplicantResponseBody } = await apiClient.get(api.applicant)
    return response.data
  } catch (e: any) {
    const error: AxiosError = { ...e }
    if (error.response?.status === 404) return initialState.applicant
    throw e
  }
})

export const updateApplicant = appCreateAsyncThunk(
  'applicantVerification/updateApplicant',
  async (applicant: Applicant, { getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    const response: { data: Applicant } = await apiClient.post(api.applicant, applicant)
    return response.data
  }
)

export const fetchAvailableVerificationTypes = appCreateAsyncThunk(
  'applicantVerification/fetchAvailableVerificationTypes',
  async (arg: void, { getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    const response: {
      data: AvailableVerificationTypesResponse[]
    } = await apiClient.get(api.verificationTypes)
    return response.data
  }
)

export const createApplicantVerification = appCreateAsyncThunk(
  'applicantVerification/createApplicantVerification',
  async ({ type, allowUseBiometricData = false }: CreateVerificationParams, { rejectWithValue, getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    const data = {
      type,
      terms_and_conditions: {
        allow_use_biometric_data: allowUseBiometricData
      },
      source: verificationSource()
    }
    try {
      const response = await apiClient.post(api.verification, data)
      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const fetchApplicantVerification = appCreateAsyncThunk(
  'applicantVerification/fetchApplicantVerification',
  async (arg, { getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    try {
      const response: { data: ApplicantVerification } = await apiClient.get(api.verification)
      return response.data
    } catch (e: any) {
      const error: AxiosError = { ...e }
      if (error.response?.status === 404) return initialState.applicantVerification
      throw e
    }
  }
)

export interface ApplicantVerificationCheckActionParams {
  checkId: string
}

export interface SubmitApplicantVerificationCheckParams extends ApplicantVerificationCheckActionParams {
  data: {
    signature?: string
    media?: Array<{ context: VerificationMediaDocumentType; content: string }>
  }
}

export const submitApplicantVerificationCheck = appCreateAsyncThunk(
  'applicantVerification/submitApplicantVerificationCheck',
  async ({ checkId, data }: SubmitApplicantVerificationCheckParams, { getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    const response = await apiClient.post(api.check(checkId), {
      ...data,
      source: verificationSource()
    })

    return response.data
  }
)

export const resetApplicantVerificationCheck = appCreateAsyncThunk(
  'applicantVerification/resetApplicantVerificationCheck',
  async ({ checkId }: ApplicantVerificationCheckActionParams, { getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    const { data } = await apiClient.post(`${api.check(checkId)}/reset`)

    return data
  }
)

export const applyApplicantVerificationDocumentAddress = appCreateAsyncThunk(
  'applicantVerification/applyApplicantVerificationDocumentAddress',
  async ({ checkId }: ApplicantVerificationCheckActionParams, { getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    return apiClient.post(`${api.check(checkId)}/apply-document-address`)
  }
)

export interface ApplyApplicantVerificationDocumentChangeParams extends ApplicantVerificationCheckActionParams {
  documentType: IdTypes
}

export const applyApplicantVerificationDocumentChange = appCreateAsyncThunk(
  'applicantVerification/applyApplicantVerificationDocumentChange',
  async ({ checkId, documentType }: ApplyApplicantVerificationDocumentChangeParams, { getState }) => {
    const api = getApplicantVerificationApiUrls(getState())
    return apiClient.post(`${api.check(checkId)}/apply-document-type-change`, {
      poa_document_type: documentType
    })
  }
)

export const applicantVerification = createSlice({
  name: 'applicantVerification',
  initialState,
  reducers: {
    clearApplicantVerification(state) {
      state.applicantVerification = initialState.applicantVerification
      state.applicantVerificationStatus = initialState.applicantVerificationStatus
      state.applicantVerificationError = initialState.applicantVerificationError
    }
  },
  extraReducers: builder => {
    builder
      .addCase(initializeApplicantVerification.pending, (state, action) => ({
        ...initialState,
        context: action.meta.arg,
        initialized: false
      }))
      .addCase(initializeApplicantVerification.fulfilled, state => {
        state.initialized = true
      })
      .addCase(initializeSelfieOnlyApplicantVerification.pending, (state, action) => {
        state.context = action.meta.arg
        state.initialized = false
      })
      .addCase(initializeSelfieOnlyApplicantVerification.fulfilled, state => {
        state.initialized = true
      })
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.applicantStatus = RequestStatus.Success
        // Omitting the issuing_entity field since it's not used anymore in the UI
        state.applicant = omit(action.payload, 'primary_id_document.issuing_entity')
        state.applicantError = undefined
      })
      .addCase(updateApplicant.fulfilled, (state, action) => {
        state.applicantStatus = RequestStatus.Success
        // Omitting the issuing_entity field since it's not used anymore in the UI
        state.applicant = omit(action.payload, 'primary_id_document.issuing_entity')
        state.applicantError = undefined
      })
      .addCase(fetchApplicant.pending, state => {
        state.applicantStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicant.rejected, (state, action) => {
        state.applicantStatus = RequestStatus.Error
        state.applicant = initialState.applicant
        state.applicantError = action.error.message
      })
      .addCase(fetchApplicantVerification.fulfilled, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Success
        state.applicantVerification = action.payload
        state.applicantVerificationError = undefined
      })
      .addCase(fetchApplicantVerification.pending, state => {
        state.applicantVerificationStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicantVerification.rejected, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Error
        state.applicantVerification = initialState.applicantVerification
        state.applicantVerificationError = action.error.message
      })
      .addCase(createApplicantVerification.fulfilled, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Success
        state.applicantVerification = action.payload
        state.applicantVerificationError = undefined
      })
      .addCase(createApplicantVerification.pending, state => {
        state.applicantVerificationStatus = RequestStatus.Pending
      })
      .addCase(createApplicantVerification.rejected, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Error
        state.applicantVerification = initialState.applicantVerification
        state.applicantVerificationError = action.error.message
      })
      .addCase(fetchAvailableVerificationTypes.fulfilled, (state, action) => {
        state.availableVerificationTypes = action.payload
        state.availableVerificationTypesStatus = RequestStatus.Success
        state.availableVerificationTypesError = undefined
      })
      .addCase(fetchAvailableVerificationTypes.rejected, (state, action) => {
        state.availableVerificationTypes = []
        state.availableVerificationTypesStatus = RequestStatus.Error
        state.availableVerificationTypesError = action.error.message
      })
      .addCase(fetchAvailableVerificationTypes.pending, state => {
        state.availableVerificationTypesStatus = RequestStatus.Pending
      })
      .addCase(resetApplicantVerificationCheck.fulfilled, (state, action) => {
        state.applicantVerification = action.payload
        state.applicantVerificationError = initialState.applicantVerificationError
      })
  }
})

export const { clearApplicantVerification } = applicantVerification.actions

export const getApplicant = (state: RootState): Applicant => state.applicantVerification.applicant
export const getApplicantVerification = (state: RootState): ApplicantVerification | null =>
  state.applicantVerification.applicantVerification
export const isApplicantReady = (state: RootState): boolean =>
  state.applicantVerification.applicantStatus === RequestStatus.Success
export const getIsApplicantVerified = (state: RootState): boolean =>
  state.applicantVerification.applicant.status === ApplicantStatus.Verified
export const isApplicantVerificationReady = (state: RootState): boolean =>
  state.applicantVerification.applicantVerificationStatus === RequestStatus.Success
export const getApplicantVerificationStatus = (state: RootState): RequestStatus =>
  state.applicantVerification.applicantVerificationStatus
export const getApplicantStatus = (state: RootState): ApplicantStatus | undefined =>
  state.applicantVerification.applicant.status
export const getVerificationType = (state: RootState): ApplicantVerificationType | undefined =>
  state.applicantVerification.applicantVerification?.type
export const getAutomatedVerificationChecksList = (state: RootState): AutomatedVerificationCheck[] =>
  state.applicantVerification.applicantVerification?.checks ?? []
export const getIsSimpleSelfieOnlyCheckPossible = (state: RootState): boolean =>
  state.applicantVerification.applicantVerification?.checks?.find(({ type }) => type === CheckType.IdentityVerification)
    ?.flow_type === CheckFlowType.selfieOnly
export const getSortedAutomatedVerificationChecksList = (state: RootState): AutomatedVerificationCheck[] => {
  const orderedSteps = [CheckType.IdentityVerification, CheckType.ProofOfAddress, CheckType.FormAuthorization]
  const checksList = getAutomatedVerificationChecksList(state)
  return [...checksList].sort((a, b) => orderedSteps.indexOf(a.type) - orderedSteps.indexOf(b.type))
}
export const getAutomatedVerificationCheck =
  (checkType: CheckType) =>
  (state: RootState): AutomatedVerificationCheck | undefined =>
    getAutomatedVerificationChecksList(state).find(({ type }) => type === checkType)
export const getAutomatedVerification1583Forms = (state: RootState): AuthorizationDocument[] | undefined =>
  state.applicantVerification.applicantVerification?.forms
export const getAvailableVerificationTypes = (state: RootState): AvailableVerificationTypesResponse[] =>
  state.applicantVerification.availableVerificationTypes
export const getIsApplicantVerificationInitialized = (state: RootState): boolean =>
  state.applicantVerification.initialized
export const getApplicantVerificationContext = (state: RootState): ApplicantVerificationContext =>
  state.applicantVerification.context
export const getApplicantVerificationApiUrls = (state: RootState): ApplicantVerificationApiUrlsForLocation => {
  const { location } = getApplicantVerificationContext(state)
  const isApplicantVerified = getIsApplicantVerified(state)
  return URLS_CREATORS_BY_LOCATION[location](getApplicantVerificationContext(state), isApplicantVerified)
}

export default applicantVerification.reducer
