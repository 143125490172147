import { Box, Typography } from '@mui/material'
import Card from 'lz-design/Card'

interface HistoryItemProps {
  date: string
  description: string
}

const HistoryItem = ({ date, description }: HistoryItemProps): JSX.Element => {
  return (
    <Card>
      <Box>
        <Typography variant='body1' fontWeight={600}>
          {date}
        </Typography>
        <Typography variant='body2' color='secondary'>
          {description}
        </Typography>
      </Box>
    </Card>
  )
}

export default HistoryItem
