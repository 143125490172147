import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { AccountId } from 'interfaces/account.interface'
import { EligibleRecipients, ForwardRules } from 'apps/SettingsApp/interfaces/forward_rules.interface'

interface ForwardRulesState {
  rules: ForwardRules
  rulesStatus: RequestStatus
  rulesError: RequestError | undefined
  rulesInitialLoading: boolean
  recipients: EligibleRecipients
  recipientsStatus: RequestStatus
  recipientsError: RequestError | undefined
  recipientsInitialLoading: boolean
}

const initialState: ForwardRulesState = {
  rules: {
    forward_to_single_admin: true,
    display_name: null,
    recipient_id: null
  },
  rulesStatus: RequestStatus.Pending,
  rulesError: undefined,
  rulesInitialLoading: true,
  recipients: [],
  recipientsStatus: RequestStatus.Pending,
  recipientsError: undefined,
  recipientsInitialLoading: true
}

export const fetchForwardRules = createAsyncThunk('forwardRules/fetchForwardRules', async (accountId: AccountId) => {
  const response: { data: ForwardRules } = await apiClient.get(`/accounts/${accountId}/unassigned-rules`)
  return response.data
})

export const fetchEligibleRecipients = createAsyncThunk(
  'forwardRules/fetchEligibleRecipients',
  async (accountId: AccountId) => {
    const response: { data: EligibleRecipients } = await apiClient.get(
      `/accounts/${accountId}/unassigned-rules/recipients`
    )
    return response.data
  }
)

const forwardRulesSlice = createSlice({
  name: 'forwardRules',
  initialState,
  reducers: {
    setInitialLoading(state) {
      state.rulesInitialLoading = true
      state.recipientsInitialLoading = true
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchForwardRules.fulfilled, (state, action) => {
        state.rulesStatus = RequestStatus.Success
        state.rules = action.payload
        state.rulesError = undefined
        state.rulesInitialLoading = false
      })
      .addCase(fetchForwardRules.rejected, (state, action) => {
        state.rulesStatus = RequestStatus.Error
        state.rulesError = action.error.message
        state.rules = initialState.rules
      })
      .addCase(fetchEligibleRecipients.fulfilled, (state, action) => {
        state.recipientsStatus = RequestStatus.Success
        state.recipients = action.payload
        state.recipientsError = undefined
        state.recipientsInitialLoading = false
      })
      .addCase(fetchEligibleRecipients.rejected, (state, action) => {
        state.recipientsStatus = RequestStatus.Error
        state.recipientsError = action.error.message
        state.recipients = initialState.recipients
      })
  }
})

export const { setInitialLoading } = forwardRulesSlice.actions

export const getForwardRules = (state: RootState): ForwardRules => state.settingsApp.forwardRules.rules
export const getEligibleRecipients = (state: RootState): EligibleRecipients => state.settingsApp.forwardRules.recipients
export const getInitialLoading = (state: RootState): boolean => {
  const { rulesInitialLoading, recipientsInitialLoading } = state.settingsApp.forwardRules
  return rulesInitialLoading || recipientsInitialLoading
}

export default forwardRulesSlice.reducer
