import { Box, Stack, StackProps, ThemeOptions } from '@mui/material'
import { Alert } from 'theme/icons'

function FormHelperTextWrapper(props: StackProps) {
  const hasError = props.className?.includes('Mui-error')
  const overriddenProps: StackProps = hasError
    ? {
        direction: 'row',
        gap: 1,
        ...props,
        children: (
          <>
            <Box paddingTop={0.5}>
              <Alert sx={{ fontSize: 'inherit' }} />
            </Box>
            <Box>{props.children}</Box>
          </>
        )
      }
    : props

  return <Stack {...overriddenProps} />
}

export const MuiFormHelperText: Exclude<ThemeOptions['components'], undefined>['MuiFormHelperText'] = {
  defaultProps: {
    component: FormHelperTextWrapper
  },
  styleOverrides: {
    root: ({ theme }) => ({
      margin: theme.spacing(1, 0, 0, 0),
      ...theme.typography.footnote,
      color: theme.palette.text.primary
    })
  }
}
