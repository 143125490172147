import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Radio, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import pluralize from 'pluralize'
import { useForm } from 'react-hook-form'
import useUserContext from 'hooks/useUserContext'
import { ItemsActionRequest, RequestedActionProps } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import {
  CreateMailItemIntegrationJobsInput,
  IntegrationProviderFragment,
  VirtualMailItemDigitalStatus
} from 'graphql/__generated__/graphql'
import { gql, useMutation } from 'graphql/client'
import OutlinedFormControlLabel from 'lz-design/OutlinedFormControlLabel'
import { HookedRadioGroup } from 'lz-design/forms/inputs'

const entityType = 'piece'
const entitySource = 'earthclassmail.iris'
const billComName = 'billcom'

export default function ShareItemDialog({
  suitabilityAlert,
  itemsSuitableForAction,
  payload: { provider },
  onCancel,
  onError,
  onSuccess
}: RequestedActionProps<ItemsActionType.SHARE_ITEM_VIA_INTEGRATION>) {
  const { currentInbox } = useUserContext()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm({ defaultValues: { connectionId: '' } })
  const inboxId = String(currentInbox.id)
  const { connections, providerName, displayName, supportedJobType } = provider
  const mailItemIds = itemsSuitableForAction.map(({ id }) => id)
  const fileText = pluralize('file', itemsSuitableForAction.length)
  const [createMailItemIntegrationJobs] = useMutation(CREATE_MAIL_ITEM_INTEGRATION_JOBS, {
    onCompleted: () => onSuccess(`PDF ${fileText} shared.`),
    onError: () => onError(`A PDF ${fileText} share request failed. Please try again later.`)
  })
  const hasConnections = connections.length
  const hasSingleConnection = connections.length === 1
  const isBillcom = providerName.includes(billComName)
  const sentence =
    itemsSuitableForAction.length > 1
      ? `files of these ${isBillcom ? 'bills' : 'items'}`
      : `file of this ${isBillcom ? 'bill' : 'item'}`
  const accountSelectionName = `${isBillcom ? 'organization ' : ''}account`

  const onSubmit = async (values: ShareItemsViaIntegrationFormValues) =>
    createMailItemIntegrationJobs({
      variables: {
        input: {
          inboxId,
          connectionId: values.connectionId,
          mailItemIds,
          entitySource,
          entityType,
          jobType: supportedJobType
        }
      }
    })
  return (
    <Dialog open component='form' onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Share via {displayName}</DialogTitle>
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        <Stack gap={2}>
          {hasConnections ? (
            hasSingleConnection ? (
              <Typography color='grey.600' component='span'>
                You are about to send a PDF {sentence} to your account:{' '}
                <Typography fontWeight={600} color='inherit' component='span'>
                  {connections[0].connectionName}
                </Typography>
              </Typography>
            ) : (
              <Stack gap={4}>
                <Typography fontWeight={600}>
                  Select the {accountSelectionName} to which you want to send a PDF {sentence}:
                </Typography>
                <HookedRadioGroup
                  name='connectionId'
                  control={control}
                  rules={{ required: `Select the ${accountSelectionName}.` }}
                  sx={{ gap: 3 }}
                >
                  {connections.map(({ connectionName, id }) => (
                    <OutlinedFormControlLabel value={id} control={<Radio />} label={connectionName} key={id} />
                  ))}
                </HookedRadioGroup>
              </Stack>
            )
          ) : (
            <Typography color='grey.600'>
              It seems your inbox isn't connected to {displayName}. You can manage this connection in the Integrations
              tab under Settings.
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='primary' onClick={onCancel} size='large'>
          Cancel
        </Button>
        {hasConnections ? (
          <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='primary' size='large'>
            Share
          </LoadingButton>
        ) : (
          <Button variant='contained' color='primary' href={`/settings/${inboxId}/integrations`} size='large'>
            Go to Settings
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

interface ShareItemsViaIntegrationFormValues {
  connectionId: CreateMailItemIntegrationJobsInput['connectionId']
}

export interface ShareItemViaIntegrationActionPayload {
  provider: IntegrationProviderFragment
}

export const SHARE_ITEM_VIA_INTEGRATION: Omit<
  ItemsActionRequest<ItemsActionType.SHARE_ITEM_VIA_INTEGRATION>,
  'payload'
> = {
  action: ItemsActionType.SHARE_ITEM_VIA_INTEGRATION,
  adjective: 'shared',
  isSuitable: ({ digitalStatus }) =>
    digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanCompleted
}

const CREATE_MAIL_ITEM_INTEGRATION_JOBS = gql(`
  mutation createMailItemIntegrationJobs($input: CreateMailItemIntegrationJobsInput!) {
    createMailItemIntegrationJobs(input: $input) {
      ...on CreateMailItemIntegrationJob {
        id
      }
      ...on ErrorResult {
        mailItemId
        errorMessage
      }
    }
  }
`)
