import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Package(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          d='M12.4028 1.18553C12.137 1.13086 11.8628 1.13086 11.597 1.18553C11.2897 1.24873 11.0151 1.40256 10.7967 1.52485L10.7372 1.5581C10.0832 1.92146 9.26128 2.37817 8.40026 2.85668L17.3631 7.83604L19.6922 6.52593C20.0996 6.29674 20.3033 6.18215 20.3708 6.03185C20.4296 5.90082 20.4292 5.75083 20.3697 5.6201C20.3015 5.47014 20.0974 5.35668 19.6892 5.12975C18.0078 4.19514 15.0255 2.5375 13.2626 1.5581L13.203 1.52485C12.9847 1.40256 12.7101 1.24873 12.4028 1.18553Z'
          fill='currentColor'
        />
        <path
          d='M15.3168 8.9871L6.34156 4.00087C5.58183 4.42314 4.87334 4.81695 4.31057 5.12977C3.90233 5.35669 3.69821 5.47016 3.62999 5.62011C3.57053 5.75084 3.57015 5.90083 3.62895 6.03186C3.6964 6.18216 3.90012 6.29675 4.30757 6.52594L11.6076 10.6323C11.7507 10.7127 11.8222 10.753 11.8981 10.7687C11.9652 10.7827 12.0345 10.7827 12.1016 10.7687C12.1775 10.753 12.249 10.7127 12.3921 10.6323L15.3168 8.9871Z'
          fill='currentColor'
        />
        <path
          d='M21.9958 8.89062C21.9954 8.44229 21.9952 8.21812 21.9007 8.08724C21.8182 7.97305 21.6914 7.89894 21.5514 7.88316C21.3909 7.86506 21.1952 7.97518 20.8036 8.19542L13.4077 12.3557C13.2592 12.4392 13.185 12.4809 13.1309 12.54C13.0831 12.5923 13.047 12.6541 13.0249 12.7214C12.9999 12.7974 12.9999 12.8826 12.9999 13.0529V21.2282C12.9999 21.6754 12.9999 21.899 13.0939 22.0298C13.1759 22.1439 13.3022 22.2182 13.4418 22.2345C13.6018 22.2532 13.7966 22.145 14.1864 21.9286C15.8527 21.0033 18.8814 19.3215 20.6626 18.3319L20.7257 18.2971C20.9568 18.1698 21.2475 18.0097 21.4706 17.7675C21.6636 17.5581 21.8096 17.31 21.8989 17.0396C22.0022 16.7269 22.001 16.3951 22.0001 16.1313L21.9999 16.0591C21.9999 14.096 21.9973 10.7568 21.9958 8.89062Z'
          fill='currentColor'
        />
        <path
          d='M10.558 22.2345C10.398 22.2532 10.2031 22.145 9.81339 21.9286C8.14703 21.0033 5.1184 19.3215 3.33721 18.3319L3.27404 18.297C3.04291 18.1697 2.75231 18.0097 2.52915 17.7675C2.3362 17.5581 2.19018 17.31 2.10085 17.0396C1.99753 16.7269 1.99874 16.3951 1.9997 16.1313L1.99988 16.0591C1.99988 14.0961 2.00242 10.7568 2.00398 8.89066C2.00436 8.44232 2.00454 8.21815 2.09908 8.08726C2.18156 7.97308 2.3084 7.89897 2.44837 7.88318C2.60881 7.86509 2.80458 7.9752 3.19611 8.19544L10.5921 12.3557C10.7405 12.4392 10.8148 12.481 10.8688 12.54C10.9166 12.5923 10.9528 12.6541 10.9749 12.7214C10.9999 12.7975 10.9999 12.8826 10.9999 13.053V21.2282C10.9999 21.6754 10.9999 21.899 10.9059 22.0298C10.8238 22.1439 10.6975 22.2182 10.558 22.2345Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
