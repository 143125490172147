import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import useUserContext from 'hooks/useUserContext'
import { extractQueryVariablesFromStoreFieldName } from 'apps/MailroomAppV2/utils/extractQueryVariablesFromStoreFieldName'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { useMutation, gql } from 'graphql/client'
import { GetMailItemsQueryVariables, VirtualMailItemPhysicalStatus } from 'graphql/__generated__/graphql'

export default function CancelShredActionDialog({
  onCancel,
  onError,
  onSuccess,
  itemsSuitableForAction
}: RequestedActionProps) {
  const { currentInbox } = useUserContext()

  const [item] = itemsSuitableForAction

  const [cancelItemShred, { loading }] = useMutation(CANCEL_ITEMS_SHRED, {
    variables: {
      input: {
        inboxId: String(currentInbox.id),
        mailItemIds: [item.id]
      }
    },
    onCompleted: () => onSuccess('Shred request cancelled.'),
    onError: () => onError('Shred cancellation failed. Please try again later.'),
    update: cache =>
      cache.modify({
        fields: {
          mailItems: (data, { fieldName, storeFieldName, DELETE }) => {
            const variables = extractQueryVariablesFromStoreFieldName<GetMailItemsQueryVariables>(
              fieldName,
              storeFieldName
            )

            return variables.filters?.physicalStatusIn?.length ? DELETE : data
          }
        }
      })
  })

  if (itemsSuitableForAction.length !== 1) {
    return null
  }

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>Cancel shred</DialogTitle>
      <DialogContent>
        <DialogContentText>You are about to cancel the shred of this item.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} size='large'>
          No
        </Button>
        <LoadingButton
          loading={loading}
          variant='contained'
          size='large'
          color='error'
          onClick={() => void cancelItemShred()}
        >
          Yes, cancel
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export const CANCEL_SHRED_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.CANCEL_SHRED,
  adjective: 'requested for shred cancellation',
  isSuitable: ({ physicalStatus }) =>
    physicalStatus === VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredRequested
}

const CANCEL_ITEMS_SHRED = gql(`
  mutation cancelItemsShred($input: CancelMailItemsShredInput!) {
    cancelMailItemsShred(input: $input) {
      id,
      physicalStatus
    }
  }
`)
