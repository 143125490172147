import { ReactElement } from 'react'
import * as Icon from 'theme/icons'
import {
  VirtualMailItemCheckStatus,
  VirtualMailItemDigitalStatus,
  VirtualMailItemPhysicalStatus
} from 'graphql/__generated__/graphql'

export interface StatusProps {
  label: string
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'purple'
  icon: ReactElement
  tip: string
}

export type StatusesConfig<S extends string> = Record<S, StatusProps | undefined>

export const PHYSICAL_STATUS_CONFIG: StatusesConfig<VirtualMailItemPhysicalStatus> = {
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusUnspecified]: undefined,
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredRequested]: {
    label: 'Shredding',
    color: 'warning',
    icon: <Icon.Scissors />,
    tip: 'Shred pending'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredInProgress]: {
    label: 'Shredding',
    color: 'warning',
    icon: <Icon.Scissors />,
    tip: 'The item is currently being shredded. The action cannot be canceled.'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredCompleted]: {
    label: 'Shredded',
    color: 'secondary',
    icon: <Icon.Scissors />,
    tip: 'Item shredded'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage]: {
    label: 'In Storage',
    color: 'info',
    icon: <Icon.Archive />,
    tip: 'In storage'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee]: {
    label: 'Storage Fee',
    color: 'error',
    icon: <Icon.Archive />,
    tip: 'Storage fee applies'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipRequested]: {
    label: 'Shipping',
    color: 'warning',
    icon: <Icon.Truck />,
    tip: 'Shipment pending'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipInProgress]: {
    label: 'Shipping',
    color: 'warning',
    icon: <Icon.Truck />,
    tip: 'The item is currently being shipped. The action cannot be canceled.'
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipCompleted]: {
    label: 'Shipped',
    color: 'secondary',
    icon: <Icon.Truck />,
    tip: 'Shipment sent'
  }
}

export const DIGITAL_STATUS_CONFIG: StatusesConfig<VirtualMailItemDigitalStatus> = {
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusUnspecified]: undefined,
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusActionsBlockedNoReverification]: undefined, // TODO
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan]: {
    label: 'No Scan',
    color: 'secondary',
    icon: <Icon.Mail />,
    tip: 'Item unscanned'
  },
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanRequested]: {
    label: 'Scanning',
    color: 'warning',
    icon: <Icon.Scan />,
    tip: 'Scan pending'
  },
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanInProgress]: {
    label: 'Scanning',
    color: 'warning',
    icon: <Icon.Scan />,
    tip: 'The item is currently being scanned. The action cannot be canceled.'
  },
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanCompleted]: {
    label: 'Scanned',
    color: 'secondary',
    icon: <Icon.File />,
    tip: 'Item scanned'
  }
}

export const CHECK_STATUS_CONFIG: StatusesConfig<VirtualMailItemCheckStatus> = {
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusUnspecified]: undefined,
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusNoCheck]: undefined,
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusCheckDetected]: {
    label: 'Check',
    color: 'success',
    icon: <Icon.Cash />,
    tip: 'Check detected'
  },
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositRequested]: {
    label: 'Depositing',
    color: 'warning',
    icon: <Icon.Cash />,
    tip: 'The item is currently being deposited. The action cannot be canceled.'
  },
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositCompleted]: {
    label: 'Deposited',
    color: 'secondary',
    icon: <Icon.Bank />,
    tip: 'Mailed for deposit'
  },
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositError]: {
    label: 'No Payment',
    color: 'secondary',
    icon: <Icon.Cash />,
    tip: 'Mailed for deposit'
  }
}

const SCAN_NOT_POSSIBLE_PHYSICAL_STATUSES = [
  VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipInProgress,
  VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipCompleted,
  VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredInProgress,
  VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredCompleted
]

export function digitalScanPropOverrides(
  digitalStatus: VirtualMailItemDigitalStatus,
  physicalStatus: VirtualMailItemPhysicalStatus
) {
  return (
    digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan &&
    SCAN_NOT_POSSIBLE_PHYSICAL_STATUSES.includes(physicalStatus) && {
      icon: <Icon.Slash />,
      tip: 'The item was shipped or shredded without being scanned'
    }
  )
}
