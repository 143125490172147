import { PaletteOptions } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    purple: Palette['primary']
  }

  interface PaletteOptions {
    purple?: PaletteOptions['primary']
  }

  interface Color {
    25?: string
  }
}

export const palette: PaletteOptions = {
  text: {
    primary: '#252424',
    secondary: '#6E6D6B'
  },
  primary: {
    50: '#FEF5F2',
    light: '#fbc5b3',
    main: '#F45A27',
    dark: '#A83009'
  },
  secondary: {
    light: '#EDEDF0',
    main: '#878583',
    dark: '#4A4B57'
  },
  purple: {
    light: '#F1E8FF',
    main: '#C937ED',
    dark: '#7F4C9E',
    contrastText: '#FFFFFF'
  },
  info: {
    light: '#EBF2FF',
    main: '#009DE0',
    dark: '#2A62BF'
  },
  success: {
    light: '#ECF7ED',
    main: '#11AA5E',
    dark: '#2A742E'
  },
  warning: {
    50: '#FFFCF5',
    light: '#FFEFDE',
    main: '#FFB70E',
    dark: '#A55907'
  },
  error: {
    50: '#FEF4F7',
    light: '#FFE3EC',
    main: '#E52554',
    dark: '#BD3663'
  },
  grey: {
    25: '#FBFAF8',
    50: '#F5F2EE',
    100: '#E9E6E2',
    200: '#DCDAD6',
    300: '#C4C2BE',
    400: '#ABA9A7',
    500: '#878583',
    600: '#6E6D6B',
    700: '#4A4947',
    800: '#252424',
    900: '#0C0C0C',
    A100: '#E9E6E2',
    A200: '#DCDAD6',
    A400: '#ABA9A7',
    A700: '#4A4947'
  }
}
