import { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  MailItemFilters,
  VirtualMailItemFolder,
  VirtualMailItemDigitalStatus,
  VirtualMailItemPhysicalStatus,
  VirtualMailItemCheckStatus
} from 'graphql/__generated__/graphql'

export function useMailroomFilters() {
  const { folder = FolderFilterParam.Inbox } = useParams<{ folder: FolderFilterParam }>()
  const [searchParams, setSearchParams] = useSearchParams()

  const changeRecipient = (recipientId?: string) =>
    setSearchParams(previousParams => {
      const params = new URLSearchParams(previousParams)
      if (recipientId) {
        params.set(RECIPIENT_QUERY_PARAM_NAME, recipientId)
      } else {
        params.delete(RECIPIENT_QUERY_PARAM_NAME)
      }
      return params
    })

  const switchStatus = (status: StatusParam, checked: boolean) =>
    setSearchParams(previousParams => {
      const params = new URLSearchParams(previousParams)

      if (isGeneralStatusFilterParam(status)) {
        const relatedQueryParam =
          status === GeneralStatusFilterParam.NotViewed
            ? NOT_VIEWED_QUERY_PARAM_NAME
            : IN_SHIPPING_CART_QUERY_PARAM_NAME

        if (checked) {
          params.set(relatedQueryParam, 'true')
        } else {
          params.delete(relatedQueryParam)
        }
      } else {
        const relatedQueryParam = isDigitalStatusFilterParam(status)
          ? DIGITAL_STATUS_QUERY_PARAM_NAME
          : isPhysicalStatusFilterParam(status)
            ? PHYSICAL_STATUS_QUERY_PARAM_NAME
            : CHECK_STATUS_QUERY_PARAM_NAME

        const currentStatuses = params.getAll(relatedQueryParam)
        const newStatuses = checked ? [...currentStatuses, status] : currentStatuses.filter(param => param !== status)
        params.delete(relatedQueryParam)
        newStatuses.forEach(newStatus => params.append(relatedQueryParam, newStatus))
      }

      return params
    })

  const clearAllStatuses = () =>
    setSearchParams(previousParams => {
      const params = new URLSearchParams(previousParams)
      params.delete(NOT_VIEWED_QUERY_PARAM_NAME)
      params.delete(IN_SHIPPING_CART_QUERY_PARAM_NAME)
      params.delete(DIGITAL_STATUS_QUERY_PARAM_NAME)
      params.delete(PHYSICAL_STATUS_QUERY_PARAM_NAME)
      params.delete(CHECK_STATUS_QUERY_PARAM_NAME)
      return params
    })

  const statuses = useMemo(() => {
    return {
      general: [
        searchParams.has(NOT_VIEWED_QUERY_PARAM_NAME) ? GeneralStatusFilterParam.NotViewed : [],
        searchParams.has(IN_SHIPPING_CART_QUERY_PARAM_NAME) ? GeneralStatusFilterParam.InShippingCart : []
      ].flat(),
      digital: searchParams.getAll(DIGITAL_STATUS_QUERY_PARAM_NAME).filter(isDigitalStatusFilterParam),
      physical: searchParams.getAll(PHYSICAL_STATUS_QUERY_PARAM_NAME).filter(isPhysicalStatusFilterParam),
      check: searchParams.getAll(CHECK_STATUS_QUERY_PARAM_NAME).filter(isCheckStatusFilterParam)
    }
  }, [searchParams])

  const filters: MailItemFilters = useMemo(() => {
    return {
      folder: FOLDER_FILTER_MAPPING[folder],
      recipientId: searchParams.get(RECIPIENT_QUERY_PARAM_NAME),
      viewed: statuses.general.includes(GeneralStatusFilterParam.NotViewed) ? false : undefined,
      inShippingCart: statuses.general.includes(GeneralStatusFilterParam.InShippingCart) || undefined,
      digitalStatusIn: statuses.digital.map(status => DIGITAL_STATUS_PARAM_MAPPING[status]).flat(),
      physicalStatusIn: statuses.physical.map(status => PHYSICAL_STATUS_PARAM_MAPPING[status]).flat(),
      checkStatusIn: statuses.check.map(status => CHECK_STATUS_PARAM_MAPPING[status])
    }
  }, [folder, searchParams, statuses])

  return {
    filters,
    changeRecipient,
    statuses,
    switchStatus,
    clearAllStatuses
  }
}

export enum FolderFilterParam {
  Inbox = '',
  All = 'all',
  Archive = 'archive',
  Trash = 'trash'
}

export const FOLDER_FILTER_MAPPING = {
  [FolderFilterParam.Inbox]: VirtualMailItemFolder.VirtualMailItemFolderInbox,
  [FolderFilterParam.All]: VirtualMailItemFolder.VirtualMailItemFolderAll,
  [FolderFilterParam.Archive]: VirtualMailItemFolder.VirtualMailItemFolderArchive,
  [FolderFilterParam.Trash]: VirtualMailItemFolder.VirtualMailItemFolderTrash
}

const RECIPIENT_QUERY_PARAM_NAME = 'recipientId'

const NOT_VIEWED_QUERY_PARAM_NAME = 'notViewed'
const IN_SHIPPING_CART_QUERY_PARAM_NAME = 'inShippingCart'
const DIGITAL_STATUS_QUERY_PARAM_NAME = 'digitalStatus'
const PHYSICAL_STATUS_QUERY_PARAM_NAME = 'physicalStatus'
const CHECK_STATUS_QUERY_PARAM_NAME = 'checkStatus'

export enum GeneralStatusFilterParam {
  NotViewed = 'notViewed',
  InShippingCart = 'inShippingCart'
}

function isGeneralStatusFilterParam(param: string): param is GeneralStatusFilterParam {
  return Object.values(GeneralStatusFilterParam).includes(param as GeneralStatusFilterParam)
}

export enum DigitalStatusFilterParam {
  NoScan = 'noScan',
  Scanning = 'scanning',
  Scanned = 'scanned'
}

function isDigitalStatusFilterParam(param: string): param is DigitalStatusFilterParam {
  return Object.values(DigitalStatusFilterParam).includes(param as DigitalStatusFilterParam)
}

const DIGITAL_STATUS_PARAM_MAPPING = {
  [DigitalStatusFilterParam.NoScan]: VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan,
  [DigitalStatusFilterParam.Scanning]: [
    VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanRequested,
    VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanInProgress
  ],
  [DigitalStatusFilterParam.Scanned]: VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanCompleted
}

export enum PhysicalStatusFilterParam {
  InStorage = 'inStorage',
  StorageFee = 'storageFee',
  Shredding = 'shredding',
  Shredded = 'shredded',
  Shipping = 'shipping',
  Shipped = 'shipped'
}

function isPhysicalStatusFilterParam(param: string): param is PhysicalStatusFilterParam {
  return Object.values(PhysicalStatusFilterParam).includes(param as PhysicalStatusFilterParam)
}

const PHYSICAL_STATUS_PARAM_MAPPING = {
  [PhysicalStatusFilterParam.InStorage]: [
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage,
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee
  ],
  [PhysicalStatusFilterParam.StorageFee]: VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee,
  [PhysicalStatusFilterParam.Shredding]: [
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredRequested,
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredInProgress
  ],
  [PhysicalStatusFilterParam.Shredded]: VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredCompleted,
  [PhysicalStatusFilterParam.Shipping]: [
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipRequested,
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipInProgress
  ],
  [PhysicalStatusFilterParam.Shipped]: VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipCompleted
}

export enum CheckStatusFilterParam {
  CheckDetected = 'checkDetected',
  Depositing = 'depositing',
  Deposited = 'deposited'
}

function isCheckStatusFilterParam(param: string): param is CheckStatusFilterParam {
  return Object.values(CheckStatusFilterParam).includes(param as CheckStatusFilterParam)
}

const CHECK_STATUS_PARAM_MAPPING = {
  [CheckStatusFilterParam.CheckDetected]: VirtualMailItemCheckStatus.VirtualMailItemCheckStatusCheckDetected,
  [CheckStatusFilterParam.Depositing]: VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositRequested,
  [CheckStatusFilterParam.Deposited]: VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositCompleted
}

export type StatusParam =
  | GeneralStatusFilterParam
  | DigitalStatusFilterParam
  | PhysicalStatusFilterParam
  | CheckStatusFilterParam
