import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { Button, Stack } from '@mui/material'
import logoVirtualMail from 'assets/logoVirtualMail.svg'
import logoLegalZoom from 'assets/logoLegalZoom.svg'
import { usePageContext } from './PageContext'
import AccountDropdown from './AccountDropdown'
import { BurgerMenu } from 'theme/icons'

export const TOP_BAR_HEIGHT = 60

function TopBar(): React.ReactElement {
  const { toggleMobileMenu } = usePageContext()

  return (
    <AppBar position='static' sx={{ background: 'white', boxShadow: 'none' }}>
      <Stack sx={{ alignItems: 'center', height: TOP_BAR_HEIGHT, px: { xs: 4, md: 6 } }} direction='row'>
        <Box
          component='img'
          sx={{ display: { xs: 'none', md: 'flex' }, mr: 'auto', width: 135 }}
          src={logoVirtualMail}
          alt='Virtual Mail logo'
        />
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Button
            variant='text'
            color='secondary'
            size='small'
            onClick={toggleMobileMenu}
            sx={{
              px: 1,
              minWidth: 0
            }}
          >
            <BurgerMenu />
          </Button>
        </Box>
        <Box
          component='img'
          sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, height: 24 }}
          src={logoLegalZoom}
          alt='LegalZoom logo'
        />
        <AccountDropdown />
      </Stack>
    </AppBar>
  )
}
export default TopBar
