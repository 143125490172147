import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Radio(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <circle cx='12' cy='12' r='11.5' fill='none' stroke='currentColor' />
      </svg>
    </SvgIcon>
  )
}
