import { useState } from 'react'
import { Outlet } from 'react-router'
import { AlertContext } from 'hooks/useAlertContext'
import Alert, { ALERT_INITIAL_STATE } from './atoms/alerts/Alert'

export default function Layout({ children = <Outlet /> }: { children?: React.ReactNode }) {
  const [alertValue, setAlert] = useState(ALERT_INITIAL_STATE)

  return (
    <AlertContext.Provider value={{ alertValue, setAlert }}>
      <Alert setAlert={setAlert} {...alertValue} />
      {children}
    </AlertContext.Provider>
  )
}
