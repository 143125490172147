/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
  '\n  mutation cancelMailItemsScan($input: CancelMailItemsScanInput!) {\n    cancelMailItemsScan(input: $input) {\n      id,\n      digitalStatus\n    }\n  }\n':
    types.CancelMailItemsScanDocument,
  '\n  mutation cancelItemsShred($input: CancelMailItemsShredInput!) {\n    cancelMailItemsShred(input: $input) {\n      id,\n      physicalStatus\n    }\n  }\n':
    types.CancelItemsShredDocument,
  '\n  query checkDepositBankAccounts($inboxId: ID!, $page: Int!, $pageSize: Int!) {\n    checkDepositBankAccounts(inboxId: $inboxId, page: $page, pageSize: $pageSize) {\n      edges {\n        cursor\n        node {\n          ...BankAccount\n        }\n      }\n      hasNextPage\n      totalCount\n    }\n  }\n':
    types.CheckDepositBankAccountsDocument,
  '\n  fragment BankAccount on CheckDepositBankAccount {\n    id\n    displayName\n    bankName\n    accountType\n    isExpedited\n    routingNumber\n    accountNumberMasked\n  }\n':
    types.BankAccountFragmentDoc,
  '\n  mutation markMailItemsNotViewed($input: MarkMailItemsNotViewedInput!) {\n    markMailItemsNotViewed(input: $input) {\n      ...on VirtualMailItem {\n        id\n        isViewed\n      }\n    }\n  }\n':
    types.MarkMailItemsNotViewedDocument,
  '\n  mutation markMailItemsViewed($input: MarkMailItemsViewedInput!) {\n    markMailItemsViewed(input: $input) {\n      ...on VirtualMailItem {\n        id\n        isViewed\n      }\n    }\n  }\n':
    types.MarkMailItemsViewedDocument,
  '\n    mutation moveMailItems($input: MoveMailItemsInput!) {\n      moveMailItems(input: $input) {\n        ...on VirtualMailItem {\n          id\n          folder\n        }\n      }\n    }\n  ':
    types.MoveMailItemsDocument,
  '\n  mutation markNotMyMails($input: MarkNotMyMailsInput!) {\n    markNotMyMails(input: $input) {\n      ...on VirtualMailItem {\n        id\n      }\n    }\n  }\n':
    types.MarkNotMyMailsDocument,
  '\n  mutation requestItemsScan($input: RequestMailItemsScanInput!) {\n    requestMailItemsScan(input: $input) {\n      id,\n      digitalStatus\n    }\n  }\n':
    types.RequestItemsScanDocument,
  '\n  mutation createGeneralShareLink($input: CreateGeneralShareLinkInput!) {\n    createGeneralShareLink(input: $input) {\n      mailItemId\n      generalLinkData {\n        linkKey\n        isEnabled\n      }\n    }\n  }\n':
    types.CreateGeneralShareLinkDocument,
  '\n  mutation createMailItemIntegrationJobs($input: CreateMailItemIntegrationJobsInput!) {\n    createMailItemIntegrationJobs(input: $input) {\n      ...on CreateMailItemIntegrationJob {\n        id\n      }\n      ...on ErrorResult {\n        mailItemId\n        errorMessage\n      }\n    }\n  }\n':
    types.CreateMailItemIntegrationJobsDocument,
  '\n  query mailItemShareEmails($inboxId: ID!) {\n    mailItemShareEmails(inboxId: $inboxId)\n  }\n':
    types.MailItemShareEmailsDocument,
  '\n  mutation shareMailItemsByEmail($input: ShareMailItemsByEmailInput!) {\n    shareMailItemsByEmail(input: $input) {\n      ...on VirtualMailItemShareData {\n        mailItemId\n        emailShares {\n          email\n          linkData {\n            linkKey\n            isEnabled\n          }\n        }\n      }\n    }\n  }\n':
    types.ShareMailItemsByEmailDocument,
  '\n  mutation deleteShareLink($input: DeleteShareLinkInput!) {\n    deleteShareLink(input: $input) {\n      mailItemId\n      emailShares {\n        email\n        linkData {\n          linkKey\n          isEnabled\n        }\n      }\n    }\n  }\n':
    types.DeleteShareLinkDocument,
  '\n  query mailItemsShareData($mailItemIds: [ID!]!) {\n    mailItemsShareData(mailItemIds: $mailItemIds) {\n      ...MailItemShareData\n    }\n  }\n':
    types.MailItemsShareDataDocument,
  '\n  mutation toggleShareLinkAccess($input: ToggleShareLinkAccessInput!) {\n    toggleShareLinkAccess(input: $input) {\n      ...MailItemShareData\n    }\n  }\n':
    types.ToggleShareLinkAccessDocument,
  '\n    fragment MailItemShareData on VirtualMailItemShareData {\n      mailItemId\n      generalLinkData {\n        linkKey\n        isEnabled\n      }\n      emailShares {\n          email\n          linkData {\n            linkKey\n            isEnabled\n          }\n        }\n    }\n  ':
    types.MailItemShareDataFragmentDoc,
  '\n  mutation addItemsToShippingCart($input: AddItemsToShippingCartInput!) {\n    addItemsToShippingCart(input: $input) {\n      inboxId\n      userId\n      itemCount\n      items {\n        id\n        inShippingCart\n      }\n    }\n  }\n':
    types.AddItemsToShippingCartDocument,
  '\n  mutation shredMailItems($input: RequestMailItemsShredInput!) {\n    requestMailItemsShred(input: $input) {\n      ... on VirtualMailItem {\n        id,\n        physicalStatus\n      }\n    }\n  }\n':
    types.ShredMailItemsDocument,
  '\n  query getMailItems($inboxId: ID!, $first: Int, $after: ID, $filters: MailItemFilters) {\n    mailItems(inboxId: $inboxId, first: $first, after: $after, filters: $filters) {\n      edges {\n        node {\n          ...MailItem\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n':
    types.GetMailItemsDocument,
  '\n  query integrationProviders($inboxId: ID!) {\n    integrationProviders(inboxId: $inboxId) {\n     ...IntegrationProvider\n    }\n  }\n':
    types.IntegrationProvidersDocument,
  '\n  fragment IntegrationProvider on IntegrationProvider {\n      id\n      providerName\n      displayName\n      authType\n      authorizationUrl\n      logoUrl\n      supportedJobType\n      connections {\n        id\n        connectionName\n        userEmail\n        createdAt\n      }\n  }\n':
    types.IntegrationProviderFragmentDoc,
  '\n  fragment MailItem on VirtualMailItem {\n    id\n    barcode\n    isViewed\n    sender\n    receivedAt\n    recipient {\n      name\n    }\n    physicalStatus\n    digitalStatus\n    checkStatus\n    enclosure {\n      smallUrl\n      largeUrl\n    }\n    scan {\n      pdfUrl\n    }\n    senderCarrier\n    folder\n    physicalDimensions {\n      weightInOunces\n      pageCountEstimate\n      pageCountActual\n    }\n    mailType\n    mailSubtype\n    facility\n    inShippingCart\n    flags\n  }\n':
    types.MailItemFragmentDoc,
  '\n  query getInbox($inboxId: Int!) {\n    getInbox(inboxId: $inboxId) {\n      ...Inbox\n    }\n  }\n':
    types.GetInboxDocument,
  '\n  fragment Inbox on Inbox {\n    id,\n    account {\n      id\n      isVerified\n      ecmNumber\n      addresses {\n        ...VirtualAddress\n      }\n    }\n  }\n':
    types.InboxFragmentDoc,
  '\n  fragment VirtualAddress on Address {\n    line1\n    city\n    state\n    postalCode\n  }\n':
    types.VirtualAddressFragmentDoc,
  '\n  query getRecipients($inboxId: ID!) {\n    recipients(inboxId: $inboxId) {\n      id\n      name\n    }\n  }\n':
    types.GetRecipientsDocument,
  '\n  query mailItemById($inboxId: ID!, $mailItemId: ID!) {\n    mailItemById(inboxId: $inboxId, mailItemId: $mailItemId) {\n      ...MailItem\n    }\n  }\n':
    types.MailItemByIdDocument,
  '\n  query getMailItemHistory($inboxId: ID!, $mailItemId: ID!) {\n    mailItemHistory(inboxId: $inboxId, mailItemId: $mailItemId) {\n     id\n     createdAt\n     description\n    }\n  }\n':
    types.GetMailItemHistoryDocument,
  '\n  mutation setMailItemCheckDetected($input: SetMailItemCheckDetectedInput!) {\n    setMailItemCheckDetected(input: $input) {\n      id\n      checkStatus\n      flags\n    }\n  }\n':
    types.SetMailItemCheckDetectedDocument,
  '\n    mutation updateMailItem($input: UpdateMailItemInput!) {\n      updateMailItem(input: $input) {\n        id\n        sender\n      }\n    }\n  ':
    types.UpdateMailItemDocument,
  '\n  mutation setNewMailroomEnabled($input: SetNewMailroomEnabledInput!) {\n    setNewMailroomEnabled(input: $input) {\n      accountId,\n      key,\n      value\n    }\n  }\n':
    types.SetNewMailroomEnabledDocument,
  '\n  query getMailItemsByIds($inboxId: ID!, $mailItemIds: [ID!]!) {\n    mailItemsByIds(inboxId: $inboxId, mailItemIds: $mailItemIds) {\n      ...ShippingItem\n    }\n  }\n':
    types.GetMailItemsByIdsDocument,
  '\n  fragment ShippingItem on VirtualMailItem {\n      id\n      receivedAt\n      sender\n      facility\n      enclosure {\n          largeUrl\n        }\n      recipient {\n        name\n      }\n    }\n':
    types.ShippingItemFragmentDoc,
  '\n  mutation authorizeShippingAddress($input: AuthorizeShippingAddressInput!) {\n    authorizeShippingAddress(input: $input) {\n      ...Address\n    }\n  }\n':
    types.AuthorizeShippingAddressDocument,
  '\n  query getCountries {\n    countriesConfiguration {\n      name\n      abbreviation\n    }\n  }':
    types.GetCountriesDocument,
  '\n  mutation createShippingAddress($input: ShippingAddressInput!) {\n    createShippingAddress(input: $input) {\n      shippingAddressId\n      documentsToAuthorize {\n        ...Document\n      }\n    }\n  }\n':
    types.CreateShippingAddressDocument,
  '\n  fragment Document on Document {\n    uri\n    name\n    format\n  }  \n': types.DocumentFragmentDoc,
  '\n  query getShippingAddresses($inboxId: ID!) {\n    getShippingAddresses(inboxId: $inboxId) {\n      ...Address\n    }\n    getMostRecentlyUsedShippingAddress(inboxId: $inboxId) {\n      ...Address\n    }\n  }\n':
    types.GetShippingAddressesDocument,
  '\n  fragment Address on ShippingAddress {\n      id\n      addressee\n      company\n      line1\n      line2\n      city\n      state\n      stateCode\n      country\n      countryCode\n      state\n      stateCode\n      postalCode\n      phone\n      requiresItn\n      requiresHsTariffCodes\n  }\n':
    types.AddressFragmentDoc,
  '\n  query getCartItems($input: GetCurrentShippingCartInput!) {\n    getCurrentShippingCart(input: $input) {\n      inboxId\n      userId\n      items {\n        ...ShippingItem\n      }\n      itemCount\n    }\n  }\n':
    types.GetCartItemsDocument,
  '\n  mutation removeItemsFromCart($input: RemoveItemsFromShippingCartInput!) {\n    removeItemsFromShippingCart(input: $input) {\n      inboxId\n      userId\n      items {\n        id\n      }\n      itemCount\n    }\n  }\n':
    types.RemoveItemsFromCartDocument,
  '\n  mutation requestRates($input: RequestRatesInput!) {\n    requestRates(input: $input) {\n      ...CarrierRates\n    }\n  }\n':
    types.RequestRatesDocument,
  '\n  fragment CarrierRates on RequestRatesResponse {\n    shippingRates {\n            ...CarrierRate\n        }\n    insuranceValueInCents\n    insurancePriceInCents\n  }\n':
    types.CarrierRatesFragmentDoc,
  '\n  fragment CarrierRate on ShippingRate {\n      id\n      carrierType\n      carrierLogoUrl\n      priceInCents\n      carrierService\n      isTrackingAvailable\n  }\n':
    types.CarrierRateFragmentDoc,
  '\n  mutation requestShipment($input: RequestShipmentInput) {\n    requestShipment(input: $input) {\n      inboxId\n      userId\n      items {\n        id\n      }\n      itemCount\n    }\n  }\n  ':
    types.RequestShipmentDocument,
  '\n  query getAccountFlags($inboxId: Int!) {\n    getInbox(inboxId: $inboxId) {\n      id\n      account {\n        id\n        flags {\n          accountId\n          key\n          value\n        }\n      }\n    }\n  }\n':
    types.GetAccountFlagsDocument,
  '\n  query getInboxes {\n    getInboxes {\n      id\n      account {\n        id\n        ecmNumber\n        name\n      }\n      pieceCounts {\n        unreadPieceCount\n      }\n    }\n  }\n':
    types.GetInboxesDocument,
  '\n  query getShippingCartItemsCount($input: GetCurrentShippingCartInput!) {\n    getCurrentShippingCart(input: $input) {\n      inboxId\n      userId\n      itemCount\n    }\n  }':
    types.GetShippingCartItemsCountDocument
}

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation cancelMailItemsScan($input: CancelMailItemsScanInput!) {\n    cancelMailItemsScan(input: $input) {\n      id,\n      digitalStatus\n    }\n  }\n'
): (typeof documents)['\n  mutation cancelMailItemsScan($input: CancelMailItemsScanInput!) {\n    cancelMailItemsScan(input: $input) {\n      id,\n      digitalStatus\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation cancelItemsShred($input: CancelMailItemsShredInput!) {\n    cancelMailItemsShred(input: $input) {\n      id,\n      physicalStatus\n    }\n  }\n'
): (typeof documents)['\n  mutation cancelItemsShred($input: CancelMailItemsShredInput!) {\n    cancelMailItemsShred(input: $input) {\n      id,\n      physicalStatus\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query checkDepositBankAccounts($inboxId: ID!, $page: Int!, $pageSize: Int!) {\n    checkDepositBankAccounts(inboxId: $inboxId, page: $page, pageSize: $pageSize) {\n      edges {\n        cursor\n        node {\n          ...BankAccount\n        }\n      }\n      hasNextPage\n      totalCount\n    }\n  }\n'
): (typeof documents)['\n  query checkDepositBankAccounts($inboxId: ID!, $page: Int!, $pageSize: Int!) {\n    checkDepositBankAccounts(inboxId: $inboxId, page: $page, pageSize: $pageSize) {\n      edges {\n        cursor\n        node {\n          ...BankAccount\n        }\n      }\n      hasNextPage\n      totalCount\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BankAccount on CheckDepositBankAccount {\n    id\n    displayName\n    bankName\n    accountType\n    isExpedited\n    routingNumber\n    accountNumberMasked\n  }\n'
): (typeof documents)['\n  fragment BankAccount on CheckDepositBankAccount {\n    id\n    displayName\n    bankName\n    accountType\n    isExpedited\n    routingNumber\n    accountNumberMasked\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation markMailItemsNotViewed($input: MarkMailItemsNotViewedInput!) {\n    markMailItemsNotViewed(input: $input) {\n      ...on VirtualMailItem {\n        id\n        isViewed\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation markMailItemsNotViewed($input: MarkMailItemsNotViewedInput!) {\n    markMailItemsNotViewed(input: $input) {\n      ...on VirtualMailItem {\n        id\n        isViewed\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation markMailItemsViewed($input: MarkMailItemsViewedInput!) {\n    markMailItemsViewed(input: $input) {\n      ...on VirtualMailItem {\n        id\n        isViewed\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation markMailItemsViewed($input: MarkMailItemsViewedInput!) {\n    markMailItemsViewed(input: $input) {\n      ...on VirtualMailItem {\n        id\n        isViewed\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation moveMailItems($input: MoveMailItemsInput!) {\n      moveMailItems(input: $input) {\n        ...on VirtualMailItem {\n          id\n          folder\n        }\n      }\n    }\n  '
): (typeof documents)['\n    mutation moveMailItems($input: MoveMailItemsInput!) {\n      moveMailItems(input: $input) {\n        ...on VirtualMailItem {\n          id\n          folder\n        }\n      }\n    }\n  ']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation markNotMyMails($input: MarkNotMyMailsInput!) {\n    markNotMyMails(input: $input) {\n      ...on VirtualMailItem {\n        id\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation markNotMyMails($input: MarkNotMyMailsInput!) {\n    markNotMyMails(input: $input) {\n      ...on VirtualMailItem {\n        id\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation requestItemsScan($input: RequestMailItemsScanInput!) {\n    requestMailItemsScan(input: $input) {\n      id,\n      digitalStatus\n    }\n  }\n'
): (typeof documents)['\n  mutation requestItemsScan($input: RequestMailItemsScanInput!) {\n    requestMailItemsScan(input: $input) {\n      id,\n      digitalStatus\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createGeneralShareLink($input: CreateGeneralShareLinkInput!) {\n    createGeneralShareLink(input: $input) {\n      mailItemId\n      generalLinkData {\n        linkKey\n        isEnabled\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation createGeneralShareLink($input: CreateGeneralShareLinkInput!) {\n    createGeneralShareLink(input: $input) {\n      mailItemId\n      generalLinkData {\n        linkKey\n        isEnabled\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createMailItemIntegrationJobs($input: CreateMailItemIntegrationJobsInput!) {\n    createMailItemIntegrationJobs(input: $input) {\n      ...on CreateMailItemIntegrationJob {\n        id\n      }\n      ...on ErrorResult {\n        mailItemId\n        errorMessage\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation createMailItemIntegrationJobs($input: CreateMailItemIntegrationJobsInput!) {\n    createMailItemIntegrationJobs(input: $input) {\n      ...on CreateMailItemIntegrationJob {\n        id\n      }\n      ...on ErrorResult {\n        mailItemId\n        errorMessage\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query mailItemShareEmails($inboxId: ID!) {\n    mailItemShareEmails(inboxId: $inboxId)\n  }\n'
): (typeof documents)['\n  query mailItemShareEmails($inboxId: ID!) {\n    mailItemShareEmails(inboxId: $inboxId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation shareMailItemsByEmail($input: ShareMailItemsByEmailInput!) {\n    shareMailItemsByEmail(input: $input) {\n      ...on VirtualMailItemShareData {\n        mailItemId\n        emailShares {\n          email\n          linkData {\n            linkKey\n            isEnabled\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation shareMailItemsByEmail($input: ShareMailItemsByEmailInput!) {\n    shareMailItemsByEmail(input: $input) {\n      ...on VirtualMailItemShareData {\n        mailItemId\n        emailShares {\n          email\n          linkData {\n            linkKey\n            isEnabled\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteShareLink($input: DeleteShareLinkInput!) {\n    deleteShareLink(input: $input) {\n      mailItemId\n      emailShares {\n        email\n        linkData {\n          linkKey\n          isEnabled\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation deleteShareLink($input: DeleteShareLinkInput!) {\n    deleteShareLink(input: $input) {\n      mailItemId\n      emailShares {\n        email\n        linkData {\n          linkKey\n          isEnabled\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query mailItemsShareData($mailItemIds: [ID!]!) {\n    mailItemsShareData(mailItemIds: $mailItemIds) {\n      ...MailItemShareData\n    }\n  }\n'
): (typeof documents)['\n  query mailItemsShareData($mailItemIds: [ID!]!) {\n    mailItemsShareData(mailItemIds: $mailItemIds) {\n      ...MailItemShareData\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation toggleShareLinkAccess($input: ToggleShareLinkAccessInput!) {\n    toggleShareLinkAccess(input: $input) {\n      ...MailItemShareData\n    }\n  }\n'
): (typeof documents)['\n  mutation toggleShareLinkAccess($input: ToggleShareLinkAccessInput!) {\n    toggleShareLinkAccess(input: $input) {\n      ...MailItemShareData\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment MailItemShareData on VirtualMailItemShareData {\n      mailItemId\n      generalLinkData {\n        linkKey\n        isEnabled\n      }\n      emailShares {\n          email\n          linkData {\n            linkKey\n            isEnabled\n          }\n        }\n    }\n  '
): (typeof documents)['\n    fragment MailItemShareData on VirtualMailItemShareData {\n      mailItemId\n      generalLinkData {\n        linkKey\n        isEnabled\n      }\n      emailShares {\n          email\n          linkData {\n            linkKey\n            isEnabled\n          }\n        }\n    }\n  ']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation addItemsToShippingCart($input: AddItemsToShippingCartInput!) {\n    addItemsToShippingCart(input: $input) {\n      inboxId\n      userId\n      itemCount\n      items {\n        id\n        inShippingCart\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation addItemsToShippingCart($input: AddItemsToShippingCartInput!) {\n    addItemsToShippingCart(input: $input) {\n      inboxId\n      userId\n      itemCount\n      items {\n        id\n        inShippingCart\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation shredMailItems($input: RequestMailItemsShredInput!) {\n    requestMailItemsShred(input: $input) {\n      ... on VirtualMailItem {\n        id,\n        physicalStatus\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation shredMailItems($input: RequestMailItemsShredInput!) {\n    requestMailItemsShred(input: $input) {\n      ... on VirtualMailItem {\n        id,\n        physicalStatus\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getMailItems($inboxId: ID!, $first: Int, $after: ID, $filters: MailItemFilters) {\n    mailItems(inboxId: $inboxId, first: $first, after: $after, filters: $filters) {\n      edges {\n        node {\n          ...MailItem\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getMailItems($inboxId: ID!, $first: Int, $after: ID, $filters: MailItemFilters) {\n    mailItems(inboxId: $inboxId, first: $first, after: $after, filters: $filters) {\n      edges {\n        node {\n          ...MailItem\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query integrationProviders($inboxId: ID!) {\n    integrationProviders(inboxId: $inboxId) {\n     ...IntegrationProvider\n    }\n  }\n'
): (typeof documents)['\n  query integrationProviders($inboxId: ID!) {\n    integrationProviders(inboxId: $inboxId) {\n     ...IntegrationProvider\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment IntegrationProvider on IntegrationProvider {\n      id\n      providerName\n      displayName\n      authType\n      authorizationUrl\n      logoUrl\n      supportedJobType\n      connections {\n        id\n        connectionName\n        userEmail\n        createdAt\n      }\n  }\n'
): (typeof documents)['\n  fragment IntegrationProvider on IntegrationProvider {\n      id\n      providerName\n      displayName\n      authType\n      authorizationUrl\n      logoUrl\n      supportedJobType\n      connections {\n        id\n        connectionName\n        userEmail\n        createdAt\n      }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment MailItem on VirtualMailItem {\n    id\n    barcode\n    isViewed\n    sender\n    receivedAt\n    recipient {\n      name\n    }\n    physicalStatus\n    digitalStatus\n    checkStatus\n    enclosure {\n      smallUrl\n      largeUrl\n    }\n    scan {\n      pdfUrl\n    }\n    senderCarrier\n    folder\n    physicalDimensions {\n      weightInOunces\n      pageCountEstimate\n      pageCountActual\n    }\n    mailType\n    mailSubtype\n    facility\n    inShippingCart\n    flags\n  }\n'
): (typeof documents)['\n  fragment MailItem on VirtualMailItem {\n    id\n    barcode\n    isViewed\n    sender\n    receivedAt\n    recipient {\n      name\n    }\n    physicalStatus\n    digitalStatus\n    checkStatus\n    enclosure {\n      smallUrl\n      largeUrl\n    }\n    scan {\n      pdfUrl\n    }\n    senderCarrier\n    folder\n    physicalDimensions {\n      weightInOunces\n      pageCountEstimate\n      pageCountActual\n    }\n    mailType\n    mailSubtype\n    facility\n    inShippingCart\n    flags\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getInbox($inboxId: Int!) {\n    getInbox(inboxId: $inboxId) {\n      ...Inbox\n    }\n  }\n'
): (typeof documents)['\n  query getInbox($inboxId: Int!) {\n    getInbox(inboxId: $inboxId) {\n      ...Inbox\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Inbox on Inbox {\n    id,\n    account {\n      id\n      isVerified\n      ecmNumber\n      addresses {\n        ...VirtualAddress\n      }\n    }\n  }\n'
): (typeof documents)['\n  fragment Inbox on Inbox {\n    id,\n    account {\n      id\n      isVerified\n      ecmNumber\n      addresses {\n        ...VirtualAddress\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment VirtualAddress on Address {\n    line1\n    city\n    state\n    postalCode\n  }\n'
): (typeof documents)['\n  fragment VirtualAddress on Address {\n    line1\n    city\n    state\n    postalCode\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getRecipients($inboxId: ID!) {\n    recipients(inboxId: $inboxId) {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  query getRecipients($inboxId: ID!) {\n    recipients(inboxId: $inboxId) {\n      id\n      name\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query mailItemById($inboxId: ID!, $mailItemId: ID!) {\n    mailItemById(inboxId: $inboxId, mailItemId: $mailItemId) {\n      ...MailItem\n    }\n  }\n'
): (typeof documents)['\n  query mailItemById($inboxId: ID!, $mailItemId: ID!) {\n    mailItemById(inboxId: $inboxId, mailItemId: $mailItemId) {\n      ...MailItem\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getMailItemHistory($inboxId: ID!, $mailItemId: ID!) {\n    mailItemHistory(inboxId: $inboxId, mailItemId: $mailItemId) {\n     id\n     createdAt\n     description\n    }\n  }\n'
): (typeof documents)['\n  query getMailItemHistory($inboxId: ID!, $mailItemId: ID!) {\n    mailItemHistory(inboxId: $inboxId, mailItemId: $mailItemId) {\n     id\n     createdAt\n     description\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation setMailItemCheckDetected($input: SetMailItemCheckDetectedInput!) {\n    setMailItemCheckDetected(input: $input) {\n      id\n      checkStatus\n      flags\n    }\n  }\n'
): (typeof documents)['\n  mutation setMailItemCheckDetected($input: SetMailItemCheckDetectedInput!) {\n    setMailItemCheckDetected(input: $input) {\n      id\n      checkStatus\n      flags\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateMailItem($input: UpdateMailItemInput!) {\n      updateMailItem(input: $input) {\n        id\n        sender\n      }\n    }\n  '
): (typeof documents)['\n    mutation updateMailItem($input: UpdateMailItemInput!) {\n      updateMailItem(input: $input) {\n        id\n        sender\n      }\n    }\n  ']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation setNewMailroomEnabled($input: SetNewMailroomEnabledInput!) {\n    setNewMailroomEnabled(input: $input) {\n      accountId,\n      key,\n      value\n    }\n  }\n'
): (typeof documents)['\n  mutation setNewMailroomEnabled($input: SetNewMailroomEnabledInput!) {\n    setNewMailroomEnabled(input: $input) {\n      accountId,\n      key,\n      value\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getMailItemsByIds($inboxId: ID!, $mailItemIds: [ID!]!) {\n    mailItemsByIds(inboxId: $inboxId, mailItemIds: $mailItemIds) {\n      ...ShippingItem\n    }\n  }\n'
): (typeof documents)['\n  query getMailItemsByIds($inboxId: ID!, $mailItemIds: [ID!]!) {\n    mailItemsByIds(inboxId: $inboxId, mailItemIds: $mailItemIds) {\n      ...ShippingItem\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ShippingItem on VirtualMailItem {\n      id\n      receivedAt\n      sender\n      facility\n      enclosure {\n          largeUrl\n        }\n      recipient {\n        name\n      }\n    }\n'
): (typeof documents)['\n  fragment ShippingItem on VirtualMailItem {\n      id\n      receivedAt\n      sender\n      facility\n      enclosure {\n          largeUrl\n        }\n      recipient {\n        name\n      }\n    }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation authorizeShippingAddress($input: AuthorizeShippingAddressInput!) {\n    authorizeShippingAddress(input: $input) {\n      ...Address\n    }\n  }\n'
): (typeof documents)['\n  mutation authorizeShippingAddress($input: AuthorizeShippingAddressInput!) {\n    authorizeShippingAddress(input: $input) {\n      ...Address\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getCountries {\n    countriesConfiguration {\n      name\n      abbreviation\n    }\n  }'
): (typeof documents)['\n  query getCountries {\n    countriesConfiguration {\n      name\n      abbreviation\n    }\n  }']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createShippingAddress($input: ShippingAddressInput!) {\n    createShippingAddress(input: $input) {\n      shippingAddressId\n      documentsToAuthorize {\n        ...Document\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation createShippingAddress($input: ShippingAddressInput!) {\n    createShippingAddress(input: $input) {\n      shippingAddressId\n      documentsToAuthorize {\n        ...Document\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Document on Document {\n    uri\n    name\n    format\n  }  \n'
): (typeof documents)['\n  fragment Document on Document {\n    uri\n    name\n    format\n  }  \n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getShippingAddresses($inboxId: ID!) {\n    getShippingAddresses(inboxId: $inboxId) {\n      ...Address\n    }\n    getMostRecentlyUsedShippingAddress(inboxId: $inboxId) {\n      ...Address\n    }\n  }\n'
): (typeof documents)['\n  query getShippingAddresses($inboxId: ID!) {\n    getShippingAddresses(inboxId: $inboxId) {\n      ...Address\n    }\n    getMostRecentlyUsedShippingAddress(inboxId: $inboxId) {\n      ...Address\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Address on ShippingAddress {\n      id\n      addressee\n      company\n      line1\n      line2\n      city\n      state\n      stateCode\n      country\n      countryCode\n      state\n      stateCode\n      postalCode\n      phone\n      requiresItn\n      requiresHsTariffCodes\n  }\n'
): (typeof documents)['\n  fragment Address on ShippingAddress {\n      id\n      addressee\n      company\n      line1\n      line2\n      city\n      state\n      stateCode\n      country\n      countryCode\n      state\n      stateCode\n      postalCode\n      phone\n      requiresItn\n      requiresHsTariffCodes\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getCartItems($input: GetCurrentShippingCartInput!) {\n    getCurrentShippingCart(input: $input) {\n      inboxId\n      userId\n      items {\n        ...ShippingItem\n      }\n      itemCount\n    }\n  }\n'
): (typeof documents)['\n  query getCartItems($input: GetCurrentShippingCartInput!) {\n    getCurrentShippingCart(input: $input) {\n      inboxId\n      userId\n      items {\n        ...ShippingItem\n      }\n      itemCount\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation removeItemsFromCart($input: RemoveItemsFromShippingCartInput!) {\n    removeItemsFromShippingCart(input: $input) {\n      inboxId\n      userId\n      items {\n        id\n      }\n      itemCount\n    }\n  }\n'
): (typeof documents)['\n  mutation removeItemsFromCart($input: RemoveItemsFromShippingCartInput!) {\n    removeItemsFromShippingCart(input: $input) {\n      inboxId\n      userId\n      items {\n        id\n      }\n      itemCount\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation requestRates($input: RequestRatesInput!) {\n    requestRates(input: $input) {\n      ...CarrierRates\n    }\n  }\n'
): (typeof documents)['\n  mutation requestRates($input: RequestRatesInput!) {\n    requestRates(input: $input) {\n      ...CarrierRates\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CarrierRates on RequestRatesResponse {\n    shippingRates {\n            ...CarrierRate\n        }\n    insuranceValueInCents\n    insurancePriceInCents\n  }\n'
): (typeof documents)['\n  fragment CarrierRates on RequestRatesResponse {\n    shippingRates {\n            ...CarrierRate\n        }\n    insuranceValueInCents\n    insurancePriceInCents\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CarrierRate on ShippingRate {\n      id\n      carrierType\n      carrierLogoUrl\n      priceInCents\n      carrierService\n      isTrackingAvailable\n  }\n'
): (typeof documents)['\n  fragment CarrierRate on ShippingRate {\n      id\n      carrierType\n      carrierLogoUrl\n      priceInCents\n      carrierService\n      isTrackingAvailable\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation requestShipment($input: RequestShipmentInput) {\n    requestShipment(input: $input) {\n      inboxId\n      userId\n      items {\n        id\n      }\n      itemCount\n    }\n  }\n  '
): (typeof documents)['\n  mutation requestShipment($input: RequestShipmentInput) {\n    requestShipment(input: $input) {\n      inboxId\n      userId\n      items {\n        id\n      }\n      itemCount\n    }\n  }\n  ']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getAccountFlags($inboxId: Int!) {\n    getInbox(inboxId: $inboxId) {\n      id\n      account {\n        id\n        flags {\n          accountId\n          key\n          value\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getAccountFlags($inboxId: Int!) {\n    getInbox(inboxId: $inboxId) {\n      id\n      account {\n        id\n        flags {\n          accountId\n          key\n          value\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getInboxes {\n    getInboxes {\n      id\n      account {\n        id\n        ecmNumber\n        name\n      }\n      pieceCounts {\n        unreadPieceCount\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getInboxes {\n    getInboxes {\n      id\n      account {\n        id\n        ecmNumber\n        name\n      }\n      pieceCounts {\n        unreadPieceCount\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getShippingCartItemsCount($input: GetCurrentShippingCartInput!) {\n    getCurrentShippingCart(input: $input) {\n      inboxId\n      userId\n      itemCount\n    }\n  }'
): (typeof documents)['\n  query getShippingCartItemsCount($input: GetCurrentShippingCartInput!) {\n    getCurrentShippingCart(input: $input) {\n      inboxId\n      userId\n      itemCount\n    }\n  }']

export function gql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<
  infer TType,
  any
>
  ? TType
  : never
