import { useState } from 'react'
import { Autocomplete, Chip, FormHelperText, Stack, TextField, Typography } from '@mui/material'

export interface AutocompleteWithChipsProps {
  options: string[]
  label: string
  name: string
  value: string[]
  showInputAsNoOptionText?: boolean
  error?: string
  onChange: (newValue: string[]) => void
}

export default function AutocompleteWithChips({
  label,
  error,
  value,
  showInputAsNoOptionText = false,
  onChange,
  ...restProps
}: AutocompleteWithChipsProps) {
  const [inputValue, setInputValue] = useState<string>('')
  const isNewOptionProvided = inputValue ? !value.includes(inputValue) : false
  const handleAddNewOption = () => {
    if (inputValue && !value.includes(inputValue)) {
      onChange([...value, inputValue])
    }
  }
  return (
    <Stack width='100%'>
      <Autocomplete
        {...restProps}
        value={value}
        onChange={(_event, newValue) => onChange(newValue)}
        multiple
        onInputChange={(_event, newValue: string) => setInputValue(newValue)}
        disableCloseOnSelect
        clearIcon={null}
        fullWidth
        inputValue={inputValue}
        noOptionsText={
          isNewOptionProvided && showInputAsNoOptionText ? (
            <Typography
              onClick={handleAddNewOption}
              sx={{ paddingX: 3, paddingY: 2, borderRadius: 1, ':hover': { backgroundColor: 'grey.50' } }}
            >
              {inputValue}
            </Typography>
          ) : (
            <Typography sx={{ paddingX: 3, paddingY: 2 }}>No options</Typography>
          )
        }
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            label={label}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.stopPropagation()
                e.preventDefault()
                handleAddNewOption()
              }
            }}
            error={!!error}
          />
        )}
        renderTags={(values: string[], getTagProps) =>
          values.map((email, index) => (
            <Chip
              label={email}
              {...getTagProps({ index })}
              key={index}
              color='secondary'
              sx={{
                '.MuiChip-label': {
                  textTransform: 'none',
                  fontSize: '14px',
                  lineHeight: '20px'
                }
              }}
            />
          ))
        }
        sx={{ overflow: 'hidden' }}
      />
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </Stack>
  )
}
