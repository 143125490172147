import React from 'react'
import { Box, Checkbox, Chip, Stack, Typography, Tooltip } from '@mui/material'
import { isUserImpersonated } from 'utils/credentials_service'
import useUserContext from 'hooks/useUserContext'
import {
  DigitalStatusChip,
  PhysicalStatusChip,
  CheckStatusChip
} from 'apps/MailroomAppV2/components/MailItemsList/StatusChip'
import { formatReceivedDate } from 'apps/MailroomAppV2/utils/formatReceivedDate'
import { ItemsSelection, ItemsSelectionActionType } from 'apps/MailroomAppV2/hooks/useItemsSelection'
import { MARK_ITEMS_VIEWED } from 'apps/MailroomAppV2/components/Actions/MarkViewedActionModal'
import { ShoppingCart } from 'theme/icons'
import { MailItemFragment } from 'graphql/__generated__/graphql'
import { useMutation } from 'graphql/client'
import { useAlert } from 'lz-design/AlertContext'

interface MailItemProps {
  item: MailItemFragment
  itemsSelection: ItemsSelection
}

const MailItem = ({
  item,
  itemsSelection: { currentMailItem, dispatchSelectionAction, selectedMailItems }
}: MailItemProps): JSX.Element => {
  const { currentInbox } = useUserContext()
  const { setAlert } = useAlert()
  const {
    isViewed,
    receivedAt,
    sender,
    recipient,
    physicalStatus,
    digitalStatus,
    checkStatus,
    inShippingCart,
    id,
    facility
  } = item
  const isCurrent = item === currentMailItem
  const isSelected = selectedMailItems.includes(item)

  const [markViewed] = useMutation(MARK_ITEMS_VIEWED, {
    optimisticResponse: ({ input }) => ({
      markMailItemsViewed: input.mailItemIds.map(id => ({
        id,
        isViewed: true,
        __typename: 'VirtualMailItem' as const
      }))
    }),
    onError: () =>
      setAlert({
        message: 'Cannot mark item as viewed - unexpected error occurred. Please try again later.',
        severity: 'error'
      })
  })

  function handleClick(e: React.MouseEvent) {
    e.preventDefault()
    dispatchSelectionAction({ type: ItemsSelectionActionType.SET_CURRENT_ITEM, itemId: id })
    if (!isUserImpersonated() && !isViewed) {
      void markViewed({
        variables: {
          input: {
            inboxId: String(currentInbox.id),
            mailItemIds: [id]
          }
        }
      })
    }
  }

  return (
    <Stack
      p={6}
      direction='row'
      bgcolor={isCurrent ? 'warning.50' : isViewed ? 'grey.25' : 'white'}
      alignItems='flex-start'
      gap={4}
      onClick={handleClick}
      component='label'
    >
      <Box marginTop={0.5}>
        <Checkbox
          checked={isSelected}
          onClick={event => {
            event.stopPropagation()
            dispatchSelectionAction({ type: ItemsSelectionActionType.TOGGLE_ITEM_SELECTION, itemId: id })
          }}
        />
      </Box>

      <Stack flexGrow={1} overflow='hidden'>
        <Stack direction='row' justifyContent='space-between' gap={1} fontWeight={isViewed ? 400 : 600}>
          <Box
            overflow='hidden'
            sx={{
              '& > *': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }
            }}
          >
            <Typography variant='body1' fontWeight='inherit'>
              {recipient?.name}
            </Typography>
            <Typography variant='body2' color='secondary' fontWeight='inherit'>
              {sender}
            </Typography>
          </Box>
          <Typography variant='body2' fontWeight='inherit' marginTop={0.5} textAlign='right' whiteSpace='nowrap'>
            {formatReceivedDate(receivedAt)}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          gap={2}
          flexWrap='nowrap'
          marginTop={4}
          sx={{
            '& > *': {
              overflow: 'hidden'
            }
          }}
        >
          <DigitalStatusChip status={digitalStatus} physicalStatus={physicalStatus} />
          <PhysicalStatusChip status={physicalStatus} facility={facility} />
          <CheckStatusChip status={checkStatus} />
          {inShippingCart && (
            <Tooltip title='Item in the shipping cart' placement='top'>
              <Chip color='purple' sx={{ ml: 'auto' }} icon={<ShoppingCart />} />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MailItem
