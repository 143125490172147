import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import useUserContext from 'hooks/useUserContext'
import { SET_NEW_MAILROOM_ENABLED } from 'apps/MailroomAppV2/components/Switchers/SwitchToNewMailroom'
import { useMutation } from 'graphql/client'
import { HookedTextField } from 'lz-design/forms/inputs'
import { useAlert } from 'lz-design/AlertContext'

export interface SwitchToOldMailroomDialogProps {
  open: boolean
  onClose: () => void
}

export default function SwitchToOldMailroomDialog({ open, onClose }: SwitchToOldMailroomDialogProps) {
  const { currentInbox } = useUserContext()
  const { setAlert } = useAlert()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: {
      reason: ''
    }
  })
  const [disable] = useMutation(SET_NEW_MAILROOM_ENABLED, {
    onError: () => {
      setAlert({
        message: 'Failed to switch back to the legacy version of Mailroom. Please try again later.',
        severity: 'error'
      })
      onClose()
    }
  })

  const onSubmit = async ({ reason }: SwitchToOldMailroomFormValues) =>
    disable({
      variables: {
        input: {
          accountId: String(currentInbox.account.id),
          value: false,
          feedbackMessage: reason,
          userAgent: window.navigator.userAgent
        }
      }
    })

  return (
    <Dialog open={open} component='form' onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Switch back to legacy version</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 6 }}>
          You are about to switch back to the legacy version of MailRoom. We are committed to continuously enhancing
          your experience, and your feedback is invaluable to us.
          <br />
          <br />
          Do you have any suggestions for improvement?
        </DialogContentText>
        <HookedTextField
          fullWidth
          control={control}
          name='reason'
          multiline
          minRows={3}
          label='Why are you switching back to the legacy version?'
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} size='large'>
          Cancel
        </Button>
        <LoadingButton loading={isSubmitting} type='submit' variant='contained' size='large'>
          Switch
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export interface SwitchToOldMailroomFormValues {
  reason: string
}
