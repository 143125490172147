import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import pluralize from 'pluralize'
import { useForm } from 'react-hook-form'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps } from 'apps/MailroomAppV2/hooks/useItemsAction'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import { validateEmailList } from 'apps/MailroomAppV2/utils/validateEmails'
import { SharePdfDialogWrapper } from './SharePdfDialog'
import { gql, useMutation, useQuery } from 'graphql/client'
import { HookedAutocompleteWithChips } from 'lz-design/forms/inputs'

interface ShareMailItemsByEmailFormValues {
  emailAddresses: string[]
}

export default function ShareMultiplePdfDialog({
  itemsSuitableForAction,
  suitabilityAlert,
  onCancel,
  onSuccess,
  onError
}: RequestedActionProps) {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    getValues
  } = useForm({
    defaultValues: {
      emailAddresses: []
    }
  })

  const { currentInbox } = useUserContext()
  const inboxId = String(currentInbox.id)
  const { data: mailItemShareEmailsData, loading: mailItemShareEmailsLoading } = useQuery(MAIL_ITEM_SHARE_EMAILS, {
    variables: { inboxId }
  })

  const [shareMailItemsByEmail] = useMutation(SHARE_MAIL_ITEMS_BY_EMAIL, {
    onCompleted: () => {
      const { emailAddresses } = getValues()
      onSuccess(`${pluralize('invitation', emailAddresses.length, true)} sent.`)
    },
    onError: () => onError('The request to share PDF files failed. Please try again later.')
  })

  const mailItemShareEmails = mailItemShareEmailsData?.mailItemShareEmails

  async function onSubmit(values: ShareMailItemsByEmailFormValues) {
    await shareMailItemsByEmail({
      variables: {
        input: {
          inboxId,
          emails: values.emailAddresses,
          mailItemIds: itemsSuitableForAction.map(({ id }) => id)
        }
      }
    })
  }

  return (
    <SharePdfDialogWrapper onClose={onCancel} loading={mailItemShareEmailsLoading} onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        <Stack gap={1}>
          <Stack>
            <Typography fontWeight={600} pr={1}>
              Add access to:
            </Typography>
            {itemsSuitableForAction.map(({ barcode, id }) => (
              <Typography fontWeight={600} key={id}>
                {barcode}.pdf
              </Typography>
            ))}
          </Stack>
          <Typography color='secondary'>Send a secure, unique link to a contact</Typography>
          <Stack direction='row' gap={2} pt={3} alignItems='flex-end'>
            <HookedAutocompleteWithChips
              name='emailAddresses'
              control={control}
              options={mailItemShareEmails || []}
              label='Email address'
              showInputAsNoOptionText
              rules={{
                validate: validateEmailList
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} size='large'>
          Cancel
        </Button>
        <LoadingButton loading={isSubmitting} variant='contained' size='large' type='submit'>
          Invite
        </LoadingButton>
      </DialogActions>
    </SharePdfDialogWrapper>
  )
}

const MAIL_ITEM_SHARE_EMAILS = gql(`
  query mailItemShareEmails($inboxId: ID!) {
    mailItemShareEmails(inboxId: $inboxId)
  }
`)

const SHARE_MAIL_ITEMS_BY_EMAIL = gql(`
  mutation shareMailItemsByEmail($input: ShareMailItemsByEmailInput!) {
    shareMailItemsByEmail(input: $input) {
      ...on VirtualMailItemShareData {
        mailItemId
        emailShares {
          email
          linkData {
            linkKey
            isEnabled
          }
        }
      }
    }
  }
`)
