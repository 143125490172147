import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  Switch,
  Tooltip,
  DialogTitle,
  DialogContentText
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { getEnvConfig } from 'config/envconfig'
import { ItemsActionRequest, RequestedActionProps } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import { MAIL_ITEMS_SHARE_DATA, TOGGLE_SHARE_LINK_ACCESS } from './queries'
import Card from 'lz-design/Card'
import { Info } from 'theme/icons'
import { gql, useMutation, useQuery } from 'graphql/client'
import { useAlert } from 'lz-design/AlertContext'
import CopyToClipboardButton from 'lz-design/CopyToClipboardButton'
import { MailItemShareDataFragment, VirtualMailItemDigitalStatus } from 'graphql/__generated__/graphql'
import LoadingModal from 'lz-design/LoadingModal'

export default function CopyLinkToPdfDialog({
  itemsSuitableForAction,
  suitabilityAlert,
  onCancel,
  onError
}: RequestedActionProps) {
  const { data, loading } = useQuery(MAIL_ITEMS_SHARE_DATA, {
    variables: { mailItemIds: itemsSuitableForAction.map(({ id }) => id) },
    onError: () => {
      onError('An error occurred while fetching data for the shared items. Please try again later.')
      onCancel()
    }
  })

  const getMailItemBarcode = (mailItemId: string) =>
    itemsSuitableForAction.find(({ id }) => id === mailItemId)?.barcode || ''

  if (loading) return <LoadingModal />

  return (
    <Dialog open>
      <DialogTitle>Copy link</DialogTitle>
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        <DialogContentText marginBottom={6}>Send a secure and unique URL.</DialogContentText>
        <Stack gap={9}>
          {data?.mailItemsShareData.map(item => (
            <ItemRow barcode={getMailItemBarcode(item.mailItemId)} {...item} key={item.mailItemId} />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onCancel} size='large'>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function AccessTooltip() {
  return (
    <Stack>
      <Typography fontWeight={600} paddingBottom={0.5}>
        Access
      </Typography>
      <Typography variant='body2' paddingBottom={5}>
        Active - means the link is active and available to be navigated while remaining encrypted and secure.
      </Typography>
      <Typography variant='body2'>
        Inactive - means that no recipients can access the content. Recipients will see a redirect page if they use the
        link.
      </Typography>
    </Stack>
  )
}

interface ItemRowProps extends MailItemShareDataFragment {
  barcode: string
}

function ItemRow({ mailItemId, generalLinkData, emailShares, barcode }: ItemRowProps) {
  const linkKey = generalLinkData?.linkKey
  const isSharableLinkEnabled = !!generalLinkData?.isEnabled
  const { setAlert } = useAlert()
  const { sharePortalUrl } = getEnvConfig()

  const sharableLink = sharePortalUrl + linkKey
  const [getSharableLink, { loading }] = useMutation(CREATE_GENERAL_SHARE_LINK, {
    variables: { input: { mailItemId } },
    onError: () => {
      setAlert({
        severity: 'error',
        message: 'Create sharable link request failed. Please try again later.'
      })
    }
  })

  const [toggleLinkAccess] = useMutation(TOGGLE_SHARE_LINK_ACCESS, {
    onError: () => {
      setAlert({
        severity: 'error',
        message: 'Toggle link access request failed. Please try again later.'
      })
    },
    optimisticResponse: ({ input: { linkKey } }) =>
      ({
        toggleShareLinkAccess: {
          __typename: 'VirtualMailItemShareData',
          mailItemId,
          generalLinkData: {
            linkKey,
            isEnabled: !isSharableLinkEnabled
          },
          emailShares
        }
      }) as const
  })

  return (
    <Stack gap={3}>
      <Stack gap={1} direction='row' justifyContent='space-between'>
        <Stack>
          <Typography fontWeight={600}>Add access to:</Typography>
          <Typography>{barcode}.pdf</Typography>
        </Stack>
        {!linkKey && (
          <LoadingButton onClick={() => void getSharableLink()} loading={loading}>
            Create link
          </LoadingButton>
        )}
      </Stack>
      <Stack gap={3}>
        {linkKey && (
          <>
            <Card direction='row' alignItems='center' justifyContent='space-between' mb={1} gap={2}>
              <Typography textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' variant='body1'>
                {sharableLink}
              </Typography>
              <CopyToClipboardButton
                textToCopy={sharableLink}
                alertMessage='PDF link copied.'
                unavailabilityMessage='To copy the link, enable the "Link is active" toggle.'
                isCopyToClipboardAvailable={isSharableLinkEnabled}
              />
            </Card>
            <Card direction='row' alignItems='center' justifyContent='space-between' py={1.5}>
              <Stack direction='row' gap={3}>
                <Typography variant='body1'>Link is active</Typography>
                <Tooltip title={<AccessTooltip />}>
                  <Stack justifyContent='center'>
                    <Info sx={{ fontSize: '14px' }} />
                  </Stack>
                </Tooltip>
              </Stack>
              <Switch
                checked={isSharableLinkEnabled}
                onChange={() =>
                  void toggleLinkAccess({
                    variables: {
                      input: {
                        mailItemId,
                        linkKey
                      }
                    }
                  })
                }
              />
            </Card>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export const COPY_LINK_TO_PDF_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.COPY_LINK_TO_PDF,
  adjective: 'shared',
  isSuitable: ({ digitalStatus, scan }) =>
    digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanCompleted && !!scan?.pdfUrl,
  suitabilityExplanation: 'To share a PDF file of an item, first scan it.'
}

const CREATE_GENERAL_SHARE_LINK = gql(`
  mutation createGeneralShareLink($input: CreateGeneralShareLinkInput!) {
    createGeneralShareLink(input: $input) {
      mailItemId
      generalLinkData {
        linkKey
        isEnabled
      }
    }
  }
`)
