import { useState } from 'react'
import { Control, useForm } from 'react-hook-form'
import { Alert, Box, Button, Drawer, FormControlLabel, IconButton, Radio, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import useUserContext from 'hooks/useUserContext'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { ItemsActionRequest, RequestedActionProps } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { formatReceivedDate } from 'apps/MailroomAppV2/utils/formatReceivedDate'
import SuitabilityAlert from './SuitabilityAlert'
import { BankAccountFragment, MailItemFragment, VirtualMailItemCheckStatus } from 'graphql/__generated__/graphql'
import { X } from 'theme/icons'
import { HookedCheckbox, HookedRadioGroup } from 'lz-design/forms/inputs'
import OutlinedFormControlLabel from 'lz-design/OutlinedFormControlLabel'
import Card from 'lz-design/Card'
import { gql, useQuery } from 'graphql/client'
import LoadingModal from 'lz-design/LoadingModal'

interface FormState {
  checkDepositBilling: string
  postDepositAction: string
  expeditedOption: boolean
  depositAccount?: BankAccountFragment
}

export default function CheckDepositDrawer({
  onCancel,
  itemsSuitableForAction,
  suitabilityAlert
}: RequestedActionProps) {
  const { currentInbox } = useUserContext()
  const [open, setOpen] = useState(true)
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
    watch
  } = useForm<FormState>({
    defaultValues: {
      checkDepositBilling: '',
      postDepositAction: '',
      expeditedOption: false,
      depositAccount: undefined
    }
  })

  //TODO: _checkDepositBankAccountData will be used fror bank account selection
  const { data: _checkDepositBankAccountData, loading: checkDepositBankAccountDataLoading } = useQuery(
    CHECK_DEPOSIT_BANK_ACCOUNT,
    {
      variables: {
        inboxId: String(currentInbox.id),
        page: 1,
        pageSize: 200 //TODO: transform it into lazy loading
      },
      onCompleted: ({ checkDepositBankAccounts }) => {
        reset({ depositAccount: checkDepositBankAccounts.edges[0].node })
      }
    }
  )
  const isSelectedExpedited = watch('expeditedOption')
  const depositAccount = watch('depositAccount')

  function onSubmit() {}

  if (checkDepositBankAccountDataLoading) return <LoadingModal />

  return (
    <Drawer
      open={open}
      SlideProps={{
        appear: true,
        onExited: onCancel
      }}
      anchor='right'
      onClose={() => setOpen(false)}
    >
      <Stack alignItems='flex-end' color='grey.800'>
        <IconButton onClick={() => setOpen(false)} color='inherit'>
          <X />
        </IconButton>
      </Stack>
      <Stack p={3} gap={10} component='form' onSubmit={handleSubmit(onSubmit)}>
        <Typography fontWeight={600} variant='subtitle2'>
          Check Deposit
        </Typography>
        <SuitabilityAlert options={suitabilityAlert} sx={{ mb: 0 }} />
        <Stack gap={3}>
          <Typography fontWeight={600} variant='subtitle3'>
            How would you like to pay for the check deposit service?
          </Typography>
          <Typography>Select check deposit billing - you can change CheckStream at any time.</Typography>
          {/* TODO: use data from api to display options */}
          <HookedRadioGroup
            name='checkDepositBilling'
            sx={{ gap: 3, marginTop: 4 }}
            control={control}
            rules={{ required: 'Field required' }}
          >
            <OutlinedFormControlLabel
              value='monthly'
              control={<Radio />}
              label={
                <Stack gap={1}>
                  <Typography fontWeight={600}>Monthly subscription</Typography>
                  <Stack color='text.secondary'>
                    <Typography variant='body2'>
                      <strong>$39.00</strong>
                      /mo.
                    </Typography>
                    <Typography variant='body2'>
                      + <strong>$2.00</strong> per check
                    </Typography>
                    <Typography variant='body2'>
                      + <strong>Includes 5 checks per month</strong>
                    </Typography>
                  </Stack>
                </Stack>
              }
            />
            <OutlinedFormControlLabel
              value='one-off'
              control={<Radio />}
              label={
                <Stack gap={1}>
                  <Typography fontWeight={600}>One-off</Typography>
                  <Stack color='text.secondary'>
                    <Typography variant='body2'>
                      <strong>$0.00</strong>
                      /mo.
                    </Typography>
                    <Typography variant='body2'>
                      + <strong>$10.00</strong> per check
                    </Typography>
                  </Stack>
                </Stack>
              }
            />
          </HookedRadioGroup>
        </Stack>
        <Stack gap={3}>
          <Typography variant='subtitle3' fontWeight={600}>
            Items ({itemsSuitableForAction.length})
          </Typography>
          {itemsSuitableForAction.map(item => (
            <Item {...item} key={item.id} />
          ))}
        </Stack>
        <Stack gap={3}>
          <Typography variant='subtitle3' fontWeight={600}>
            Deposit account
          </Typography>
          {depositAccount ? (
            <Card gap={3}>
              <Typography fontWeight={600}>{depositAccount.bankName}</Typography>
              <Stack>
                <Typography>{depositAccount.routingNumber}</Typography>
                <Typography>{depositAccount.accountNumberMasked}</Typography>
                <Typography>US Bank</Typography>
                <Typography>
                  <Typography color='secondary' fontWeight={600} component='span'>
                    Type:{' '}
                  </Typography>
                  {depositAccount.accountType}
                </Typography>
              </Stack>
              <Button sx={{ alignSelf: 'flex-end' }}>Change</Button>
            </Card>
          ) : (
            <Card direction='row' justifyContent='space-between' alignItems='center'>
              <Typography>Deposit account</Typography>
              <Button>Add</Button>
            </Card>
          )}
        </Stack>
        <Stack gap={3}>
          <Typography fontWeight={600} variant='subtitle3'>
            Post deposit action
          </Typography>
          <Typography>Select an action with the check(s) after deposit.</Typography>
          <HookedRadioGroup name='postDepositAction' sx={{ gap: 3, marginTop: 4 }} control={control}>
            <OutlinedFormControlLabel value='shred' control={<Radio />} label='Shred' />
            <OutlinedFormControlLabel value='store' control={<Radio />} label='Store' />
          </HookedRadioGroup>
        </Stack>
        <Stack gap={4}>
          <Typography fontWeight={600} variant='subtitle3'>
            Additional options
          </Typography>
          <AdditionalOptions
            control={control}
            depositAccount={depositAccount}
            isSelectedExpedited={isSelectedExpedited}
          />
        </Stack>

        <LoadingButton loading={isSubmitting} type='submit' variant='contained' size='large'>
          Request deposit
        </LoadingButton>
      </Stack>
    </Drawer>
  )
}

function AdditionalOptions({
  depositAccount,
  isSelectedExpedited,
  control
}: {
  depositAccount?: BankAccountFragment
  isSelectedExpedited: boolean
  control: Control<FormState, any>
}) {
  if (!depositAccount) return <>no account</>

  if (depositAccount.isExpedited) {
    return (
      <Alert severity='success'>
        <Typography fontWeight={600}>Free overnight delivery.</Typography>
        <Typography>
          Deposits typically take <strong>1 business day</strong> to arrive at your bank. Allow an additional business
          day for requests made after 5PM.
        </Typography>
      </Alert>
    )
  }
  return (
    <>
      <Stack direction='row' gap={4} alignItems='flex-start' pt={1}>
        <FormControlLabel
          control={<HookedCheckbox name='expeditedOption' control={control} />}
          label={
            <Box>
              <Typography>Overnight delivery</Typography>
              <Typography color='secondary' variant='body2'>
                Next business day, 20$ fee
              </Typography>
            </Box>
          }
          sx={{
            alignItems: 'start'
          }}
        />
      </Stack>
      {isSelectedExpedited && (
        <Typography variant='body2' color='secondary'>
          Overnight delivery deposits typically take <strong>1 business day</strong> to arrive at your bank. Allow an
          additional business day for requests made after 5PM.
        </Typography>
      )}
    </>
  )
}

function Item({ recipient, sender, receivedAt }: MailItemFragment) {
  return (
    <Card direction='row' justifyContent='space-between' alignItems='center'>
      <Stack>
        <Typography fontWeight={600}>{recipient?.name}</Typography>
        <Typography>{sender}</Typography>
      </Stack>
      <Typography>{formatReceivedDate(receivedAt)}</Typography>
    </Card>
  )
}

export const CHECK_DEPOSIT_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.CHECK_DEPOSIT,
  adjective: 'deposited',
  isSuitable: item => item.checkStatus === VirtualMailItemCheckStatus.VirtualMailItemCheckStatusCheckDetected
}

const CHECK_DEPOSIT_BANK_ACCOUNT = gql(`
  query checkDepositBankAccounts($inboxId: ID!, $page: Int!, $pageSize: Int!) {
    checkDepositBankAccounts(inboxId: $inboxId, page: $page, pageSize: $pageSize) {
      edges {
        cursor
        node {
          ...BankAccount
        }
      }
      hasNextPage
      totalCount
    }
  }
`)

gql(`
  fragment BankAccount on CheckDepositBankAccount {
    id
    displayName
    bankName
    accountType
    isExpedited
    routingNumber
    accountNumberMasked
  }
`)
