import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Cash(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.16146 4L18.8385 4C19.3657 3.99999 19.8205 3.99997 20.195 4.03057C20.5904 4.06288 20.9836 4.13419 21.362 4.32699C21.9265 4.61461 22.3854 5.07355 22.673 5.63803C22.8658 6.01641 22.9371 6.40963 22.9694 6.80498C23 7.17954 23 7.6343 23 8.16144V15.8386C23 16.3657 23 16.8205 22.9694 17.195C22.9371 17.5904 22.8658 17.9836 22.673 18.362C22.3854 18.9265 21.9265 19.3854 21.362 19.673C20.9836 19.8658 20.5904 19.9371 20.195 19.9694C19.8205 20 19.3657 20 18.8386 20L5.16131 20C4.63424 20 4.17951 20 3.80497 19.9694C3.40963 19.9371 3.01641 19.8658 2.63803 19.673C2.07355 19.3854 1.6146 18.9265 1.32698 18.362C1.13419 17.9836 1.06287 17.5904 1.03057 17.195C0.999971 16.8205 0.999985 16.3657 1 15.8386V8.16141C0.999985 7.6343 0.999971 7.17953 1.03057 6.80497C1.06287 6.40963 1.13419 6.01641 1.32698 5.63803C1.6146 5.07354 2.07355 4.6146 2.63803 4.32698C3.01641 4.13419 3.40963 4.06287 3.80498 4.03057C4.17953 3.99997 4.63434 3.99998 5.16146 4ZM12 7C12.5523 7 13 7.44772 13 8H14C14.5523 8 15 8.44772 15 9C15 9.55229 14.5523 10 14 10H11.5C11.2239 10 11 10.2239 11 10.5C11 10.7761 11.2239 11 11.5 11H12.5C13.8807 11 15 12.1193 15 13.5C15 14.7095 14.1411 15.7184 13 15.95V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16H10C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14H12.5C12.7761 14 13 13.7761 13 13.5C13 13.2239 12.7761 13 12.5 13H11.5C10.1193 13 9 11.8807 9 10.5C9 9.29052 9.85888 8.28164 11 8.05001V8C11 7.44772 11.4477 7 12 7ZM16.5 12C16.5 11.1716 17.1716 10.5 18 10.5C18.8284 10.5 19.5 11.1716 19.5 12C19.5 12.8284 18.8284 13.5 18 13.5C17.1716 13.5 16.5 12.8284 16.5 12ZM4.5 12C4.5 11.1716 5.17157 10.5 6 10.5C6.82843 10.5 7.5 11.1716 7.5 12C7.5 12.8284 6.82843 13.5 6 13.5C5.17157 13.5 4.5 12.8284 4.5 12Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
