import { NavLink } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Badge, Box, Typography } from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { useAlert } from './AlertContext'
import { ShoppingCart } from 'theme/icons'
import { gql, useQuery } from 'graphql/client'

function ShippingCartButton() {
  const { currentInbox } = useUserContext()
  const { setAlert } = useAlert()

  const { data, loading } = useQuery(GET_SHIPPING_CART_ITEMS_COUNT, {
    variables: {
      input: {
        inboxId: String(currentInbox.id)
      }
    },
    onError: () =>
      setAlert({
        severity: 'error',
        message: 'Unexpected error during fetching the current shipping cart. Please try again later.'
      })
  })
  const itemsInShippingCartCount = data && String(data.getCurrentShippingCart.itemCount)

  return (
    <Box display={{ xs: 'none', lg: 'block' }}>
      <NavLink to={`/inboxes/${currentInbox.id}/shipments/new-shipment`}>
        <LoadingButton
          loading={loading}
          startIcon={<ShoppingCart />}
          variant='outlined'
          color='secondary'
          endIcon={
            itemsInShippingCartCount && <Badge standalone color='purple' badgeContent={itemsInShippingCartCount} />
          }
          size='large'
          sx={{ textWrap: 'nowrap' }}
        >
          <Typography fontWeight={600}>Shipping cart</Typography>
        </LoadingButton>
      </NavLink>
    </Box>
  )
}

const GET_SHIPPING_CART_ITEMS_COUNT = gql(`
  query getShippingCartItemsCount($input: GetCurrentShippingCartInput!) {
    getCurrentShippingCart(input: $input) {
      inboxId
      userId
      itemCount
    }
  }`)

export default ShippingCartButton
