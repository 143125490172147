import { JSXElementConstructor } from 'react'
import { Box, BoxProps, SvgIconProps, Typography } from '@mui/material'

interface EmptyStateProps extends BoxProps {
  title: string
  subtitle?: string
  icon: JSXElementConstructor<SvgIconProps>
}

const EmptyState = ({ title, subtitle, icon: Icon, children, ...boxProps }: EmptyStateProps) => {
  return (
    <Box display='grid' justifyItems='center' alignContent='center' {...boxProps}>
      <Icon sx={{ color: 'grey.300', fontSize: 60 }} />
      <Typography fontWeight={600} color='secondary' variant='subtitle3' textAlign='center' pt={4}>
        {title}
      </Typography>
      {subtitle && (
        <Typography color='secondary' variant='body2' textAlign='center'>
          {subtitle}
        </Typography>
      )}
      {children && <Box pt={4}>{children}</Box>}
    </Box>
  )
}

export default EmptyState
