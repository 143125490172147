import Chip, { ChipProps } from '@mui/material/Chip'
import { Tooltip } from '@mui/material'
import {
  DIGITAL_STATUS_CONFIG,
  PHYSICAL_STATUS_CONFIG,
  CHECK_STATUS_CONFIG,
  StatusesConfig,
  StatusProps,
  digitalScanPropOverrides
} from 'apps/MailroomAppV2/utils/statusConfig'
import {
  VirtualMailItemPhysicalStatus,
  VirtualMailItemDigitalStatus,
  VirtualMailItemCheckStatus
} from 'graphql/__generated__/graphql'
import * as Icon from 'theme/icons'

interface StatusChipProps<S extends string> extends ChipProps {
  status: S
  statusPropsMap: StatusesConfig<S>
  tip?: string
}

type SpecificTypeStatusChipProps<S extends string> = Omit<StatusChipProps<S>, 'statusPropsMap'>

const STATUS_CONFIG_OVERRIDES: Record<string, Partial<StatusProps>> = {
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredInProgress]: {
    icon: <Icon.Lock />
  },
  [VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShipInProgress]: {
    icon: <Icon.Lock />
  },
  [VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanInProgress]: {
    icon: <Icon.Lock />
  },
  [VirtualMailItemCheckStatus.VirtualMailItemCheckStatusDepositRequested]: {
    icon: <Icon.Lock />
  }
}

export function StatusChip<S extends string>({
  status,
  statusPropsMap,
  tip: tipOverride,
  ...rest
}: StatusChipProps<S>) {
  const configForStatus = statusPropsMap[status]
  if (!configForStatus) {
    return null
  }

  const statusConfigOverrides = STATUS_CONFIG_OVERRIDES[status]
  const { tip, ...config } = { ...configForStatus, ...statusConfigOverrides }

  return (
    <Tooltip title={tipOverride || tip} placement='top'>
      <Chip {...config} {...rest} />
    </Tooltip>
  )
}

interface PhysicalStatusChipProps extends SpecificTypeStatusChipProps<VirtualMailItemPhysicalStatus> {
  facility: string
}

export function PhysicalStatusChip({ status, facility, ...rest }: PhysicalStatusChipProps) {
  const tip = [
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusInStorage,
    VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusStorageFee
  ].includes(status)
    ? PHYSICAL_STATUS_CONFIG[status]?.tip.concat(` (${facility})`)
    : undefined

  return <StatusChip status={status} statusPropsMap={PHYSICAL_STATUS_CONFIG} tip={tip} {...rest} />
}

interface DigitalStatusChipProps extends SpecificTypeStatusChipProps<VirtualMailItemDigitalStatus> {
  physicalStatus: VirtualMailItemPhysicalStatus
}

export function DigitalStatusChip({ status, physicalStatus, ...rest }: DigitalStatusChipProps) {
  const propsOverrides = digitalScanPropOverrides(status, physicalStatus)

  return <StatusChip status={status} statusPropsMap={DIGITAL_STATUS_CONFIG} {...rest} {...propsOverrides} />
}

export function CheckStatusChip({ status, ...rest }: SpecificTypeStatusChipProps<VirtualMailItemCheckStatus>) {
  return <StatusChip status={status} statusPropsMap={CHECK_STATUS_CONFIG} {...rest} />
}
