import { useReducer } from 'react'
import { MailItemFragment } from 'graphql/__generated__/graphql'

interface ItemsSelectionState {
  selectedItemIds: string[]
  currentItemId?: string
}

export enum ItemsSelectionActionType {
  TOGGLE_ITEM_SELECTION,
  SELECT_ALL,
  UNSELECT_ALL,
  SELECT_VIEWED,
  SELECT_NOT_VIEWED,
  SET_CURRENT_ITEM
}

interface BaseItemsSelectionAction<T extends ItemsSelectionActionType> {
  type: T
}
interface ToggleItemSelectionAction extends BaseItemsSelectionAction<ItemsSelectionActionType.TOGGLE_ITEM_SELECTION> {
  itemId: string
}
interface SetCurrentItemAction extends BaseItemsSelectionAction<ItemsSelectionActionType.SET_CURRENT_ITEM> {
  itemId: string
}
interface UnselectAllAction extends BaseItemsSelectionAction<ItemsSelectionActionType.UNSELECT_ALL> {
  keepSelectedItemIds?: string[]
}

export type ItemsSelectionAction =
  | BaseItemsSelectionAction<
      | ItemsSelectionActionType.SELECT_ALL
      | ItemsSelectionActionType.SELECT_VIEWED
      | ItemsSelectionActionType.SELECT_NOT_VIEWED
    >
  | ToggleItemSelectionAction
  | UnselectAllAction
  | SetCurrentItemAction

export type ItemsSelection = ReturnType<typeof useItemsSelection>

export default function useItemsSelection(mailItems: MailItemFragment[]) {
  const [{ selectedItemIds, currentItemId }, dispatchSelectionAction] = useReducer(
    ({ selectedItemIds }: ItemsSelectionState, action: ItemsSelectionAction) => {
      switch (action.type) {
        case ItemsSelectionActionType.TOGGLE_ITEM_SELECTION:
          return {
            selectedItemIds: selectedItemIds.includes(action.itemId)
              ? selectedItemIds.filter(id => id !== action.itemId)
              : [...selectedItemIds, action.itemId]
          }
        case ItemsSelectionActionType.SELECT_ALL:
          return { selectedItemIds: mailItems.map(itemToId) }
        case ItemsSelectionActionType.UNSELECT_ALL:
          const { keepSelectedItemIds } = action
          return {
            selectedItemIds: keepSelectedItemIds ? selectedItemIds.filter(id => keepSelectedItemIds.includes(id)) : []
          }
        case ItemsSelectionActionType.SELECT_VIEWED:
          return {
            selectedItemIds: mailItems.filter(({ isViewed }) => isViewed).map(itemToId)
          }
        case ItemsSelectionActionType.SELECT_NOT_VIEWED:
          return {
            selectedItemIds: mailItems.filter(({ isViewed }) => !isViewed).map(itemToId)
          }
        case ItemsSelectionActionType.SET_CURRENT_ITEM:
          return {
            selectedItemIds: [action.itemId],
            currentItemId: action.itemId
          }
      }
    },
    { selectedItemIds: [] }
  )

  const currentMailItem = mailItems.find(item => item.id === currentItemId)
  const selectedMailItems = mailItems.filter(item => selectedItemIds.includes(item.id))

  return {
    currentMailItem,
    selectedMailItems,
    dispatchSelectionAction
  }
}

const itemToId = ({ id }: MailItemFragment) => id
