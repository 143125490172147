import { NavLink } from 'react-router-dom'
import { Alert, Typography, Box, Link } from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { VirtualAddressFragment } from 'graphql/__generated__/graphql'

interface UnauthorizedBannerProps {
  address: VirtualAddressFragment
  pmb: string
}

export default function UnauthorizedBanner({
  address: { line1, city, state, postalCode },
  pmb
}: UnauthorizedBannerProps) {
  const { currentInbox } = useUserContext()

  return (
    <Alert severity='error'>
      <Typography fontWeight={600}>Your business address and virtual mail service require authorization</Typography>
      <Typography variant='body2' sx={{ mb: 4 }}>
        Under U.S. Postal Service regulations, you must submit Form 1583 with information about who is using your
        address and mailbox at {line1}, {city}, {state} {postalCode}, PMB {pmb}.{' '}
        <Box component='span' fontWeight={600}>
          Without this form, your business is not in compliance, and you will no longer be able to use this address with
          the Secretary of State, potentially requiring you to use your personal address instead.
        </Box>
      </Typography>
      <Link component={NavLink} to={`/get-started/${currentInbox.id}/authorize`} color='textPrimary'>
        Complete required authorization
      </Link>
    </Alert>
  )
}
