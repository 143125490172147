import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import useUserContext from 'hooks/useUserContext'
import { useItemsAction } from 'apps/MailroomAppV2/hooks/useItemsAction'
import Actions from 'apps/MailroomAppV2/components/Actions'
import ContentTab from 'apps/MailroomAppV2/components/MailItemDetails/ContentTab'
import { ItemsSelection } from 'apps/MailroomAppV2/hooks/useItemsSelection'
import ActionsBar from './ActionsBar'
import { ChevronLeft } from 'theme/icons'
import { gql, useQuery } from 'graphql/client'
import NotFound from 'lz-design/NotFound'
import MailItemDetails from './MailItemDetails/MailItemDetails'

export default function MailItemDetailView() {
  const { id } = useParams<{ id: string }>()
  const { currentInbox } = useUserContext()
  const inboxId = String(currentInbox.id)
  const { data, error, loading } = useQuery(GET_MAIL_ITEM, { variables: { inboxId, mailItemId: id! } })
  const mailItem = data?.mailItemById
  const itemsSelection: ItemsSelection = {
    currentMailItem: mailItem,
    selectedMailItems: mailItem ? [mailItem] : [],
    dispatchSelectionAction: () => {}
  }

  const { requestItemAction, requestBulkAction, requestedAction } = useItemsAction({
    itemsSelection
  })

  if (loading) {
    return (
      <Box p={8} display='flex' justifyContent='center' margin='auto'>
        <CircularProgress color='primary' />
      </Box>
    )
  }

  if (error) {
    return (
      <Box justifySelf='center' alignSelf='center'>
        <Typography variant='subtitle3' color='grey.600' fontWeight={600}>
          An error occurred while fetching mail item
        </Typography>
      </Box>
    )
  }

  if (mailItem) {
    return (
      <Stack
        direction={{ xs: 'column-reverse', lg: 'column' }}
        mb={{ xs: 18, md: 0 }}
        overflow={{ xs: 'hidden', md: 'auto' }}
      >
        <Actions requestedAction={requestedAction} />
        <ActionsBar requestBulkAction={requestBulkAction} itemsSelection={itemsSelection} isItemDetailsView />
        <Box
          display='grid'
          gridTemplateColumns={{ lg: '1fr 2fr' }}
          gap={{ xs: 2, lg: 6 }}
          pt={{ lg: 4 }}
          mb={{ xs: 7, lg: 0 }}
          alignItems={{ lg: 'start' }}
          flex={1}
          overflow={{ xs: 'hidden', md: 'initial' }}
        >
          <MailItemDetails
            mailItem={mailItem}
            requestItemAction={requestItemAction}
            isItemDetailsView
            overflow={{ xs: 'hidden', md: 'auto' }}
            height='100%'
          >
            <Box display={{ lg: 'none' }}>
              <Button variant='text' startIcon={<ChevronLeft />} href={`/inboxes/${inboxId}/mailroom`}>
                Back to Mailroom
              </Button>
            </Box>
          </MailItemDetails>
          <Stack
            display={{ xs: 'none', lg: 'flex' }}
            mt={{ xs: 10, lg: 14 }}
            bgcolor='white'
            borderRadius={1}
            height='auto'
            minHeight='calc(100dvh - 242px)'
            p={6}
            sx={{
              overflowY: 'auto'
            }}
          >
            <ContentTab mailItem={mailItem} requestItemAction={requestItemAction} showDownloadButton={false} />
          </Stack>
        </Box>
      </Stack>
    )
  }

  return <NotFound />
}

const GET_MAIL_ITEM = gql(`
  query mailItemById($inboxId: ID!, $mailItemId: ID!) {
    mailItemById(inboxId: $inboxId, mailItemId: $mailItemId) {
      ...MailItem
    }
  }
`)
