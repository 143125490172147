import { MouseEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Stack, Typography, MenuItem, Menu, DialogActions, DialogContent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import pluralize from 'pluralize'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { MAIL_ITEMS_SHARE_DATA, TOGGLE_SHARE_LINK_ACCESS } from 'apps/MailroomAppV2/components/Actions/SharePdf/queries'
import SuitabilityAlert from 'apps/MailroomAppV2/components/Actions/SuitabilityAlert'
import { validateEmailList } from 'apps/MailroomAppV2/utils/validateEmails'
import { SharePdfDialogWrapper } from './SharePdfDialog'
import { gql, useMutation, useQuery } from 'graphql/client'
import { ChevronUp } from 'theme/icons'
import { useAlert } from 'lz-design/AlertContext'
import { HookedAutocompleteWithChips } from 'lz-design/forms/inputs'

interface ShareMailItemsByEmailFormValues {
  emailAddresses: string[]
}

export default function ShareSinglePdfDialog({
  itemsSuitableForAction,
  suitabilityAlert,
  onCancel,
  onError
}: RequestedActionProps) {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset
  } = useForm({
    defaultValues: {
      emailAddresses: []
    }
  })
  const { currentInbox } = useUserContext()
  const { setAlert } = useAlert()
  const inboxId = String(currentInbox.id)
  const mailItemToShare = itemsSuitableForAction[0]
  const { data: shareData, loading: shareDataLoading } = useQuery(MAIL_ITEMS_SHARE_DATA, {
    variables: { mailItemIds: mailItemToShare.id },
    onError: () => {
      onError('An error occurred while fetching data for the shared item. Please try again later.')
      onCancel()
    }
  })
  const { data: mailItemShareEmailsData, loading: mailItemShareEmailsLoading } = useQuery(MAIL_ITEM_SHARE_EMAILS, {
    variables: { inboxId }
  })

  const mailItemsShareData = shareData?.mailItemsShareData[0]
  const emailsList = mailItemsShareData?.emailShares
  const mailItemShareEmails = mailItemShareEmailsData?.mailItemShareEmails

  const [shareMailItemsByEmail] = useMutation(SHARE_MAIL_ITEMS_BY_EMAIL, {
    onCompleted: data => {
      reset({
        emailAddresses: []
      })
      setAlert({
        message: `${pluralize('invitation', data.shareMailItemsByEmail.length, true)} sent.`,
        severity: 'success'
      })
    },
    onError: () => onError('The request to share PDF file failed. Please try again later.')
  })

  async function onSubmit(values: ShareMailItemsByEmailFormValues) {
    await shareMailItemsByEmail({
      variables: {
        input: {
          inboxId,
          emails: values.emailAddresses,
          mailItemIds: [mailItemToShare.id]
        }
      }
    })
  }

  return (
    <SharePdfDialogWrapper
      loading={shareDataLoading || mailItemShareEmailsLoading}
      onClose={onCancel}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogContent>
        <SuitabilityAlert options={suitabilityAlert} />
        <Stack gap={6}>
          <Stack gap={1}>
            <Typography fontWeight={600} variant='body1'>
              Add access to: {itemsSuitableForAction[0].barcode}.pdf
            </Typography>
            <Typography color='secondary' variant='body1'>
              Send a secure, unique link to a contact
            </Typography>
            <Stack direction='row' gap={2} pt={3} alignItems='start'>
              <HookedAutocompleteWithChips
                name='emailAddresses'
                control={control}
                options={mailItemShareEmails || []}
                label='Email address'
                showInputAsNoOptionText
                rules={{
                  validate: validateEmailList
                }}
              />
              <LoadingButton loading={isSubmitting} variant='outlined' size='large' type='submit' sx={{ marginTop: 5 }}>
                Invite
              </LoadingButton>
            </Stack>
          </Stack>
          {emailsList && emailsList?.length > 0 && (
            <Stack gap={2}>
              <Stack direction='row' justifyContent='space-between'>
                <Typography textTransform='uppercase' variant='footnote' fontWeight={500}>
                  Who has access
                </Typography>
                <Typography textTransform='uppercase' variant='footnote' fontWeight={500}>
                  Access
                </Typography>
              </Stack>
              <Stack>
                {emailsList.map(({ email, linkData }) => (
                  <EmailRow email={email} linkData={linkData!} mailItemId={mailItemToShare.id} key={email} />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onCancel} size='large'>
          Done
        </Button>
      </DialogActions>
    </SharePdfDialogWrapper>
  )
}

interface EmailRowProps {
  mailItemId: string
  email: string
  linkData: {
    linkKey: string
    isEnabled: boolean
  }
}

function EmailRow({ mailItemId, email, linkData }: EmailRowProps) {
  const { setAlert } = useAlert()
  const { isEnabled, linkKey } = linkData
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isMenuOpen = Boolean(anchorEl)

  const openMenu = (event: MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const [toggleLinkAccess, { loading: toggleAccessLoading }] = useMutation(TOGGLE_SHARE_LINK_ACCESS, {
    variables: { input: { mailItemId, linkKey } },
    onCompleted: () => {
      setAlert({
        severity: 'success',
        message: 'Access level changed.'
      })
    },
    onError: () => {
      setAlert({
        severity: 'error',
        message: 'The request to change the access level failed. Please try again later.'
      })
    }
  })

  const [deleteShareLink, { loading: deleteLoading }] = useMutation(DELETE_SHARE_LINK, {
    variables: { input: { linkKey, mailItemId } },
    onCompleted: () => {
      setAlert({
        severity: 'success',
        message: 'Access removed.'
      })
    },
    onError: () => {
      setAlert({
        severity: 'error',
        message: 'The request to remove the access failed. Please try again later.'
      })
    }
  })

  function handleToggleLinkAccess() {
    closeMenu()
    void toggleLinkAccess()
  }

  function handleDeleteLink() {
    closeMenu()
    void deleteShareLink()
  }

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      borderTop={1}
      borderColor='grey.100'
      py={2}
      gap={3}
      sx={{
        ':first-of-type': {
          borderTop: 'none',
          paddingTop: 0
        }
      }}
    >
      <Typography color='secondary' variant='body2' width='100%' sx={{ wordBreak: 'break-word' }}>
        {email}
      </Typography>
      <LoadingButton
        loading={toggleAccessLoading || deleteLoading}
        endIcon={<ChevronUp sx={{ rotate: isMenuOpen ? 'none' : '180deg' }} />}
        onClick={openMenu}
      >
        {isEnabled ? 'Yes' : 'No'}
      </LoadingButton>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={closeMenu}>
        <MenuItem onClick={handleToggleLinkAccess} selected={isEnabled} disabled={isEnabled}>
          Yes
        </MenuItem>
        <MenuItem onClick={handleToggleLinkAccess} selected={!isEnabled} disabled={!isEnabled}>
          No
        </MenuItem>
        <MenuItem onClick={handleDeleteLink}>Remove access</MenuItem>
      </Menu>
    </Stack>
  )
}

const MAIL_ITEM_SHARE_EMAILS = gql(`
  query mailItemShareEmails($inboxId: ID!) {
    mailItemShareEmails(inboxId: $inboxId)
  }
`)

const SHARE_MAIL_ITEMS_BY_EMAIL = gql(`
  mutation shareMailItemsByEmail($input: ShareMailItemsByEmailInput!) {
    shareMailItemsByEmail(input: $input) {
      ...on VirtualMailItemShareData {
        mailItemId
        emailShares {
          email
          linkData {
            linkKey
            isEnabled
          }
        }
      }
    }
  }
`)

const DELETE_SHARE_LINK = gql(`
  mutation deleteShareLink($input: DeleteShareLinkInput!) {
    deleteShareLink(input: $input) {
      mailItemId
      emailShares {
        email
        linkData {
          linkKey
          isEnabled
        }
      }
    }
  }
`)
