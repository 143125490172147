import { Button } from '@mui/material'
import { RequestAction } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { SCAN_ACTION_REQUEST } from 'apps/MailroomAppV2/components/Actions/ScanActionDialog'
import EmptyState from 'lz-design/EmptyState'
import PDFViewer from 'lz-design/PDFViewer'
import { Slash, Scan, Mail } from 'theme/icons'
import {
  VirtualMailItemPhysicalStatus,
  VirtualMailItemDigitalStatus,
  MailItemFragment
} from 'graphql/__generated__/graphql'

interface ContentTabProps {
  mailItem: MailItemFragment
  requestItemAction: RequestAction
  showDownloadButton?: boolean
}

const ContentTab = ({ mailItem, requestItemAction, showDownloadButton = true }: ContentTabProps) => {
  const { physicalStatus, digitalStatus, scan, id } = mailItem
  // TODO seems to have some missing/invalid logic here - item can be shredded but still have a scan to show; what about shipped items?
  // TODO also we consider noScan flag first and expose "Request scan" button, even if the item is shredded or shipped already
  const isShredded = physicalStatus === VirtualMailItemPhysicalStatus.VirtualMailItemPhysicalStatusShredInProgress
  const isScanning = [
    VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanRequested,
    VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanInProgress
  ].includes(digitalStatus)
  const noScan = digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusNoScan

  if (noScan) {
    return (
      <EmptyState title='The mail content has not been scanned yet' icon={Mail} height='100%' flex={1}>
        <Button startIcon={<Scan />} onClick={() => requestItemAction(SCAN_ACTION_REQUEST)}>
          Request scan
        </Button>
      </EmptyState>
    )
  }

  if (isShredded) {
    return <EmptyState title='Mail content has been shredded' icon={Slash} height='100%' flex={1} />
  }

  if (isScanning) {
    return (
      <EmptyState
        title='Scanning in progress'
        subtitle='We are processing your request.'
        icon={Scan}
        height='100%'
        flex={1}
      />
    )
  }

  return <PDFViewer maxPageWidth={700} url={scan?.pdfUrl} key={id} showDownloadButton={showDownloadButton} />
}
export default ContentTab
