// Theoretically we use present-env and browserslist to support missing features,
// but we use not maintained create-react-app which currently uses the exact "3" (https://github.com/facebook/create-react-app/blob/main/packages/babel-preset-react-app/create.js#L85)
// core-js version in the babel config. This version doesn't support the "withResolvers" method.
// We need to import the polyfill manually to support the Promise.withResolvers method in older browsers.
// Remove this polyfill import once we use up-to-date libraries which can import polyfills automatically
// through the preset-env and browserslist configuration.
import 'polyfills/promiseWithResolvers'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Amplify, Auth, Hub } from 'aws-amplify'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'styles/base/_base.scss'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { ApolloProvider } from '@apollo/client'
import { store } from 'store/store'
import {
  appCookieDomain,
  awsSecureCookie,
  awsOAuthDomain,
  awsOAuthSignInRedirect,
  awsOAuthSignOutRedirect,
  awsProjectRegion,
  awsCognitoRegion,
  awsUserPoolsId,
  awsUserPoolsWebClientId,
  launchDarklyClientSideId
} from 'config/config'
import App from 'App'
import * as serviceWorker from 'serviceWorker'
import theme from 'material-ui/Theme'
import { initDatadogRum } from 'services/datadog'
import apolloClient from 'graphql/client'

initDatadogRum()

Hub.listen('auth', ({ payload: { event, data } }) => {
  const providerTag = ['lz', 'google', 'apple'].find((pTag: string) => String(data).startsWith(`${pTag}Redirect:`))
  if (event === 'customOAuthState' && providerTag) {
    window.history.replaceState({}, '', data.split(`${providerTag}Redirect:`).join(''))
    window.history.go()
  }
})

const AWS_CONFIG = {
  aws_project_region: awsProjectRegion,
  aws_cognito_region: awsCognitoRegion,
  aws_user_pools_id: awsUserPoolsId,
  aws_user_pools_web_client_id: awsUserPoolsWebClientId,
  federationTarget: 'COGNITO_USER_POOLS',
  cookieStorage: {
    domain: appCookieDomain,
    expires: 365,
    path: '/',
    secure: awsSecureCookie
  },
  oauth: {
    domain: awsOAuthDomain,
    redirectSignIn: awsOAuthSignInRedirect,
    redirectSignOut: awsOAuthSignOutRedirect,
    responseType: 'code',
    scope: ['aws.cognito.signin.user.admin', 'email', 'profile', 'openid']
  }
}

const prefix = 'PreSignUp+failed+with+error+'
if (window.location.href.includes(prefix)) {
  const url = new URL(window.location.href)
  const errorMessage = url.searchParams.get('error_description')?.replace('PreSignUp failed with error ', '') ?? ''
  window.location.href = `/login?error_message=${errorMessage}`
}

Amplify.configure(AWS_CONFIG)
Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' })

const LDProvider = withLDProvider({
  clientSideID: launchDarklyClientSideId
})(App)

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ApolloProvider client={apolloClient}>
            <LDProvider />
          </ApolloProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('react-root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
