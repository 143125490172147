import { useEffect } from 'react'
import pluralize from 'pluralize'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { extractQueryVariablesFromStoreFieldName } from 'apps/MailroomAppV2/utils/extractQueryVariablesFromStoreFieldName'
import { gql, useMutation } from 'graphql/client'
import LoadingModal from 'lz-design/LoadingModal'
import { GetMailItemsQueryVariables } from 'graphql/__generated__/graphql'

export default function MarkNotViewedActionModal({ onSuccess, onError, itemsSuitableForAction }: RequestedActionProps) {
  const { currentInbox } = useUserContext()
  const [markItemsNotViewed] = useMutation(MARK_ITEMS_NOT_VIEWED, {
    variables: {
      input: {
        inboxId: String(currentInbox.id),
        mailItemIds: itemsSuitableForAction.map(({ id }) => id)
      }
    },
    onCompleted: () => onSuccess(`${pluralize('item', itemsSuitableForAction.length, true)} marked as unread.`),
    onError: () => onError('Mark as unread request failed. Please try again later.'),
    update: cache =>
      cache.modify({
        fields: {
          mailItems: (data, { fieldName, storeFieldName, DELETE }) => {
            const variables = extractQueryVariablesFromStoreFieldName<GetMailItemsQueryVariables>(
              fieldName,
              storeFieldName
            )

            return variables.filters?.viewed === false ? DELETE : data
          }
        }
      })
  })

  useEffect(() => void markItemsNotViewed(), [markItemsNotViewed])

  return <LoadingModal />
}

export const MARK_NOT_VIEWED_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.MARK_NOT_VIEWED,
  adjective: 'marked as unread'
}

const MARK_ITEMS_NOT_VIEWED = gql(`
  mutation markMailItemsNotViewed($input: MarkMailItemsNotViewedInput!) {
    markMailItemsNotViewed(input: $input) {
      ...on VirtualMailItem {
        id
        isViewed
      }
    }
  }
`)
