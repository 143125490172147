import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function MailOpen(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          d='M12.6855 0.964143C12.2346 0.858306 11.7654 0.858306 11.3145 0.964143C10.7994 1.08504 10.3416 1.38353 9.82066 1.72313C7.56542 3.18908 5.30411 4.64619 3.05228 6.11748C2.71184 6.33992 2.54162 6.45114 2.48234 6.59255C2.43059 6.71601 2.43057 6.85551 2.48228 6.97898C2.54151 7.12042 2.71193 7.23184 3.05276 7.45469L10.7961 12.5176C11.4941 12.9741 11.6407 13.0527 11.7705 13.0833C11.9214 13.119 12.0786 13.119 12.2296 13.0833C12.3594 13.0527 12.5059 12.9741 13.204 12.5176L20.9473 7.45472C21.2881 7.23187 21.4585 7.12045 21.5178 6.97901C21.5695 6.85554 21.5694 6.71604 21.5177 6.59258C21.4584 6.45117 21.2882 6.33995 20.9477 6.11751C18.6959 4.64622 16.4346 3.18909 14.1793 1.72314C13.6584 1.38353 13.2006 1.08504 12.6855 0.964143Z'
          fill='currentColor'
        />
        <path
          d='M22.9974 9.98116C22.9971 9.50111 22.9969 9.26108 22.8965 9.12765C22.8089 9.01139 22.6751 8.93904 22.5299 8.92948C22.3632 8.91852 22.162 9.05007 21.7596 9.31316L14.1884 14.2637C13.6656 14.6066 13.2061 14.9079 12.6887 15.0299C12.2358 15.1368 11.7643 15.1368 11.3113 15.0299C10.7939 14.9079 10.3345 14.6066 9.81166 14.2637L2.24037 9.31311C1.838 9.05001 1.63682 8.91847 1.47015 8.92943C1.32494 8.93898 1.19108 9.01134 1.10355 9.1276C1.00308 9.26102 1.00293 9.50105 1.00264 9.98111C1.00157 11.7062 1 14.5401 1 16.2407C0.999988 17.0457 0.999977 17.71 1.04419 18.2512C1.09012 18.8133 1.18868 19.33 1.43597 19.8154C1.81947 20.568 2.43139 21.1799 3.18404 21.5634C3.66937 21.8107 4.18608 21.9093 4.74817 21.9552C5.28936 21.9994 5.95372 21.9994 6.75868 21.9994H17.2413C18.0463 21.9994 18.7106 21.9994 19.2518 21.9552C19.8139 21.9093 20.3306 21.8107 20.816 21.5634C21.5686 21.1799 22.1805 20.568 22.564 19.8154C22.8113 19.33 22.9099 18.8133 22.9558 18.2512C23 17.71 23 17.0457 23 16.2407C23 14.5401 22.9984 11.7062 22.9974 9.98116Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
