import EmptyState from 'lz-design/EmptyState'
import { Mailbox } from 'theme/icons'

const ItemDetailsEmptyState = () => (
  <EmptyState
    mt={14}
    bgcolor='white'
    borderRadius={1}
    title='Select a mail from the list to view its contents'
    icon={Mailbox}
    display={{ xs: 'none', lg: 'grid' }}
  />
)

export default ItemDetailsEmptyState
