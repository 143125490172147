import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import useUserContext from 'hooks/useUserContext'
import { RequestedActionProps, ItemsActionRequest } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { extractQueryVariablesFromStoreFieldName } from 'apps/MailroomAppV2/utils/extractQueryVariablesFromStoreFieldName'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import { gql, useMutation } from 'graphql/client'
import { GetMailItemsQueryVariables, VirtualMailItemDigitalStatus } from 'graphql/__generated__/graphql'

export default function CancelScanActionDialog({
  onCancel,
  onError,
  onSuccess,
  itemsSuitableForAction
}: RequestedActionProps) {
  const { currentInbox } = useUserContext()

  const [item] = itemsSuitableForAction

  const [cancelItemScan, { loading }] = useMutation(CANCEL_ITEMS_SCAN, {
    variables: {
      input: {
        inboxId: String(currentInbox.id),
        mailItemIds: [item.id]
      }
    },
    onCompleted: () => onSuccess('Scan request cancelled.'),
    onError: () => onError('Scan cancellation failed. Please try again later.'),
    update: cache =>
      cache.modify({
        fields: {
          mailItems: (data, { fieldName, storeFieldName, DELETE }) => {
            const variables = extractQueryVariablesFromStoreFieldName<GetMailItemsQueryVariables>(
              fieldName,
              storeFieldName
            )

            return variables.filters?.digitalStatusIn?.length ? DELETE : data
          }
        }
      })
  })

  if (itemsSuitableForAction.length !== 1) {
    return null
  }

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>Cancel scan?</DialogTitle>
      <DialogContent>
        <DialogContentText>You are about to cancel the scan of this item.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onCancel} size='large'>
          No
        </Button>
        <LoadingButton
          loading={loading}
          variant='contained'
          size='large'
          color='error'
          onClick={() => void cancelItemScan()}
        >
          Yes, cancel
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export const CANCEL_SCAN_ACTION_REQUEST: ItemsActionRequest = {
  action: ItemsActionType.CANCEL_SCAN,
  adjective: 'requested for scan cancellation',
  isSuitable: ({ digitalStatus }) =>
    digitalStatus === VirtualMailItemDigitalStatus.VirtualMailItemDigitalStatusScanRequested
}

const CANCEL_ITEMS_SCAN = gql(`
  mutation cancelMailItemsScan($input: CancelMailItemsScanInput!) {
    cancelMailItemsScan(input: $input) {
      id,
      digitalStatus
    }
  }
`)
