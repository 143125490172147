import { Button, ButtonProps } from '@mui/material'
import { useAlert } from 'lz-design/AlertContext'

interface CopyToClipboardButtonProps extends ButtonProps {
  textToCopy: string
  alertMessage: string
  unavailabilityMessage?: string
  isCopyToClipboardAvailable?: boolean
}

export default function CopyToClipboardButton({
  textToCopy,
  alertMessage,
  unavailabilityMessage = '',
  isCopyToClipboardAvailable = true,
  ...restProps
}: CopyToClipboardButtonProps) {
  const { setAlert } = useAlert()

  async function copyToClipboard() {
    if (isCopyToClipboardAvailable) {
      await navigator.clipboard.writeText(textToCopy)
      setAlert({ severity: 'success', message: alertMessage })
      return
    }
    setAlert({ severity: 'info', message: unavailabilityMessage })
  }

  return (
    <Button variant='text' size='small' onClick={copyToClipboard} {...restProps}>
      Copy
    </Button>
  )
}
