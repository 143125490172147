import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function CheckedCheckbox(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='24' height='24' rx='8' fill='currentColor' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.8047 7.52827C18.0651 7.78862 18.0651 8.21073 17.8047 8.47108L10.4714 15.8044C10.2111 16.0648 9.78894 16.0648 9.5286 15.8044L6.19526 12.4711C5.93491 12.2107 5.93491 11.7886 6.19526 11.5283C6.45561 11.2679 6.87772 11.2679 7.13807 11.5283L10 14.3902L16.8619 7.52827C17.1223 7.26792 17.5444 7.26792 17.8047 7.52827Z'
          fill='white'
        />
      </svg>
    </SvgIcon>
  )
}
