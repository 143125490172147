import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Home(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.4773 1.33611C11.8196 1.24343 12.1804 1.24343 12.5227 1.33611C12.9201 1.4437 13.2546 1.70636 13.5216 1.91599C15.825 3.72437 18.1428 5.51447 20.4563 7.30987C20.8329 7.60216 21.1647 7.85966 21.412 8.19396C21.629 8.48735 21.7906 8.81785 21.889 9.16926C22.0011 9.56967 22.0006 9.98969 22.0001 10.4664C21.9975 12.9237 22 15.381 22 17.8383C22 18.3654 22.0001 18.8202 21.9694 19.1948C21.9371 19.5901 21.8658 19.9833 21.673 20.3617C21.3854 20.9262 20.9265 21.3851 20.362 21.6728C19.9836 21.8656 19.5904 21.9369 19.195 21.9692C18.3935 22.0347 17.5806 21.9994 16.7769 21.9997C16.6578 21.9998 16.5155 21.9998 16.3902 21.9896C16.245 21.9777 16.037 21.9473 15.819 21.8363C15.5368 21.6924 15.3073 21.463 15.1635 21.1807C15.0525 20.9628 15.022 20.7548 15.0101 20.6096C14.9999 20.4842 15 20.342 15 20.2229L15.0079 14.6266C15.0087 14.0659 15.0091 13.7855 14.9002 13.5713C14.8044 13.3828 14.6514 13.2296 14.4631 13.1336C14.249 13.0244 13.9687 13.0244 13.4079 13.0244H10.608C10.0487 13.0244 9.76904 13.0244 9.55528 13.1332C9.36725 13.2289 9.21431 13.3816 9.11834 13.5695C9.00923 13.7831 9.00884 14.0628 9.00804 14.6221L9.00002 20.2229C9.00007 20.342 9.00012 20.4843 8.98988 20.6096C8.97802 20.7548 8.94758 20.9628 8.83653 21.1807C8.69272 21.463 8.46325 21.6924 8.181 21.8363C7.96306 21.9473 7.75507 21.9777 7.60985 21.9896C7.48453 21.9998 7.34228 21.9998 7.22317 21.9997C6.41948 21.9994 5.60651 22.0347 4.80499 21.9692C4.40964 21.9369 4.01643 21.8656 3.63805 21.6728C3.07356 21.3851 2.61462 20.9262 2.327 20.3617C2.1342 19.9833 2.06289 19.5901 2.03059 19.1948C1.99998 18.8202 2 18.3654 2.00002 17.8383C2.00002 15.381 2.0026 12.9237 1.99995 10.4664C1.99943 9.98968 1.99898 9.56967 2.11106 9.16926C2.20942 8.81785 2.37107 8.48735 2.58806 8.19396C2.83532 7.85966 3.16713 7.60216 3.54376 7.30988C5.8572 5.51455 8.17512 3.72433 10.4784 1.91599C10.7454 1.70636 11.08 1.4437 11.4773 1.33611Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
