import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useUserContext from 'hooks/useUserContext'
import { gql, useMutation } from 'graphql/client'
import LoadingModal from 'lz-design/LoadingModal'
import { useAlert } from 'lz-design/AlertContext'

export default function SwitchToNewMailroom() {
  const { currentInbox } = useUserContext()
  const { setAlert } = useAlert()
  const navigate = useNavigate()

  const [enable] = useMutation(SET_NEW_MAILROOM_ENABLED, {
    variables: {
      input: {
        accountId: String(currentInbox.account.id),
        value: true
      }
    },
    onError: () => {
      setAlert({
        message: 'Failed to enable new mailroom. Please try again later.',
        severity: 'error'
      })
      navigate(`/inboxes/${currentInbox.id}/pieces`, { replace: true })
    }
  })

  useEffect(() => void enable(), [enable])

  return <LoadingModal />
}

export const SET_NEW_MAILROOM_ENABLED = gql(`
  mutation setNewMailroomEnabled($input: SetNewMailroomEnabledInput!) {
    setNewMailroomEnabled(input: $input) {
      accountId,
      key,
      value
    }
  }
`)
