import React from 'react'
import { SvgIconProps, SvgIcon } from '@mui/material'

export default function Share(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L13 5.41421L13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15L11 5.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289Z'
          fill='currentColor'
        />
        <path
          d='M9 12.5343C9 12.0361 9 11.7871 8.91325 11.5926C8.81278 11.3674 8.63263 11.1872 8.40741 11.0868C8.21294 11 7.96387 11 7.46573 11C6.79596 11 6.24306 11 5.79046 11.0309C5.32118 11.0629 4.88708 11.1314 4.46927 11.3045C3.48916 11.7105 2.71046 12.4892 2.30448 13.4693C2.13142 13.8871 2.06288 14.3212 2.03087 14.7905C1.99999 15.2431 1.99999 15.7959 2 16.4657V16.5343C1.99999 17.204 1.99999 17.7569 2.03087 18.2095C2.06288 18.6788 2.13142 19.1129 2.30448 19.5307C2.71046 20.5108 3.48916 21.2895 4.46927 21.6955C4.88708 21.8686 5.32118 21.9371 5.79046 21.9691C6.24306 22 6.79592 22 7.46569 22H16.5343C17.2041 22 17.7569 22 18.2095 21.9691C18.6788 21.9371 19.1129 21.8686 19.5307 21.6955C20.5108 21.2895 21.2895 20.5108 21.6955 19.5307C21.8686 19.1129 21.9371 18.6788 21.9691 18.2095C22 17.7569 22 17.2041 22 16.5343V16.4657C22 15.7959 22 15.2431 21.9691 14.7905C21.9371 14.3212 21.8686 13.8871 21.6955 13.4693C21.2895 12.4892 20.5108 11.7105 19.5307 11.3045C19.1129 11.1314 18.6788 11.0629 18.2095 11.0309C17.7569 11 17.2041 11 16.5343 11C16.0361 11 15.787 11 15.5926 11.0868C15.3674 11.1872 15.1872 11.3674 15.0868 11.5926C15 11.787 15 12.0361 15 12.5343V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V12.5343Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
