import { ThemeOptions } from '@mui/material/styles'

export const MuiAccordion: Exclude<ThemeOptions['components'], undefined>['MuiAccordion'] = {
  defaultProps: {
    disableGutters: true
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',
      background: 'none',
      '&:before': {
        display: 'none'
      }
    }
  }
}

export const MuiAccordionSummary: Exclude<ThemeOptions['components'], undefined>['MuiAccordionSummary'] = {
  styleOverrides: {
    root: {
      minHeight: 'auto'
    },
    content: {
      margin: 0
    },
    expandIconWrapper: {
      color: 'inherit'
    }
  }
}
