import { ThemeOptions } from '@mui/material/styles'

export const MuiTabs: Exclude<ThemeOptions['components'], undefined>['MuiTabs'] = {
  styleOverrides: {
    flexContainer: ({ theme }) => ({
      '& > .Mui-selected': {
        fontWeight: 600
      },
      '& > :not(.Mui-selected)': {
        color: theme.palette.text.primary,
        fontWeight: 400
      },
      gap: theme.spacing(3),
      padding: theme.spacing(0, 6)
    }),
    indicator: {
      height: 4
    }
  }
}

export const MuiTab: Exclude<ThemeOptions['components'], undefined>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      lineHeight: '24px',
      padding: theme.spacing(4, 3),
      minWidth: 'auto',
      // prevent tabs shifting by occupying space with the invisible bolded content
      '&::after': {
        content: 'attr(data-text)',
        height: 0,
        fontWeight: 600,
        visibility: 'hidden',
        overflow: 'hidden',
        userSelect: 'none',
        pointerEvents: 'none'
      }
    })
  }
}
