import { RequestedActionProps } from 'apps/MailroomAppV2/hooks/useItemsAction'
import { ItemsActionType } from 'apps/MailroomAppV2/constants/itemsActionType'
import ScanActionDialog from 'apps/MailroomAppV2/components/Actions/ScanActionDialog'
import MoveActionDialog from 'apps/MailroomAppV2/components/Actions/MoveActionDialog'
import MarkNotViewedActionModal from 'apps/MailroomAppV2/components/Actions/MarkNotViewedActionModal'
import MarkViewedActionModal from 'apps/MailroomAppV2/components/Actions/MarkViewedActionModal'
import CancelScanActionDialog from 'apps/MailroomAppV2/components/Actions/CancelScanActionDialog'
import NotMyMailActionDialog from 'apps/MailroomAppV2/components/Actions/NotMyMailActionDialog'
import ShredActionDialog from 'apps/MailroomAppV2/components/Actions/ShredActionDialog'
import CancelShredActionDialog from 'apps/MailroomAppV2/components/Actions/CancelShredActionDialog'
import ShipActionDialog from 'apps/MailroomAppV2/components/Actions/ShipActionDialog'
import CopyLinkToPdfDialog from 'apps/MailroomAppV2/components/Actions/SharePdf/CopyLinkToPdfDialog'
import ShareItemDialog from 'apps/MailroomAppV2/components/Actions/SharePdf/ShareItemDialog'
import SharePdfDialog from 'apps/MailroomAppV2/components/Actions/SharePdf/SharePdfDialog'
import CheckDepositDrawer from './Actions/CheckDepositDrawer'

export default function Actions({ requestedAction }: { requestedAction?: RequestedActionProps }) {
  switch (requestedAction?.action) {
    case ItemsActionType.SHIP:
      return <ShipActionDialog {...requestedAction} />
    case ItemsActionType.SCAN:
      return <ScanActionDialog {...requestedAction} />
    case ItemsActionType.CANCEL_SCAN:
      return <CancelScanActionDialog {...requestedAction} />
    case ItemsActionType.SHRED:
      return <ShredActionDialog {...requestedAction} />
    case ItemsActionType.CANCEL_SHRED:
      return <CancelShredActionDialog {...requestedAction} />
    case ItemsActionType.MOVE_TO_INBOX:
    case ItemsActionType.MOVE_TO_ARCHIVE:
    case ItemsActionType.MOVE_TO_TRASH:
      return <MoveActionDialog {...requestedAction} />
    case ItemsActionType.MARK_NOT_VIEWED:
      return <MarkNotViewedActionModal {...requestedAction} />
    case ItemsActionType.MARK_VIEWED:
      return <MarkViewedActionModal {...requestedAction} />
    case ItemsActionType.NOT_MY_MAIL:
      return <NotMyMailActionDialog {...requestedAction} />
    case ItemsActionType.COPY_LINK_TO_PDF:
      return <CopyLinkToPdfDialog {...requestedAction} />
    case ItemsActionType.SHARE_ITEM_VIA_INTEGRATION:
      return <ShareItemDialog {...requestedAction} />
    case ItemsActionType.SHARE_PDF:
      return <SharePdfDialog {...requestedAction} />
    case ItemsActionType.CHECK_DEPOSIT:
      return <CheckDepositDrawer {...requestedAction} />
    default:
      return null
  }
}
